import { format } from "date-fns";
import { SYSTEM_DATE_FORMAT } from "@/src/const";
import {
  DashboardUrlParamsType,
  InquiryFiltersType,
  InquirySearchType,
} from "@/src/type/blaceV2/BlaceV2Type";
import { URLHelper } from "@/src/util";

export function getDashboardFiltersFromURL(
  queryParams: DashboardUrlParamsType
) {
  const openValue = queryParams.open;
  const closedValue = queryParams.closed;
  const completedValue = queryParams.completed;
  const bookedValue = queryParams.booked;
  const eventValue = queryParams.eventValue;
  const startDateValue = queryParams.startDateValue;
  const endDateValue = queryParams.endDateValue;
  const dayStartTime = "T00:00:00";

  const isURLWithFilterParams =
    openValue ||
    closedValue ||
    completedValue ||
    bookedValue ||
    startDateValue ||
    endDateValue ||
    eventValue;

  const filterParams = {
    endDateValue: endDateValue && new Date(endDateValue + dayStartTime),
    startDateValue: startDateValue && new Date(startDateValue + dayStartTime),
    eventValue: eventValue,
    statusFilters: {
      closed: !!closedValue,
      completed: !!completedValue,
      open: !!openValue,
      booked: !!bookedValue,
    },
  };

  return {
    isURLWithFilterParams: Boolean(isURLWithFilterParams),
    filtersFromURL: isURLWithFilterParams ? filterParams : null,
    inquiryId: queryParams.inquiryId || "",
    page: queryParams.page ? Number(queryParams.page) : 1,
  };
}

export function getFeedbackParamsFromURL() {
  const inquiryLogId = URLHelper.urlGetParameter("inquiryLogId");
  const rating = URLHelper.urlGetParameter("rating");

  return {inquiryLogId, rating};
}

export function removeFeedbackParamsFromURL() {
  URLHelper.urlRemoveParameter("inquiryLogId");
  URLHelper.urlRemoveParameter("rating");
}

export function constructDashboardURL(
  baseUrl: string,
  selectedInquiry: InquirySearchType | null,
  appliedFilters: InquiryFiltersType | undefined,
  paginationCurrentPage: number,
) {
  
  const { inquiryLogId, rating } = getFeedbackParamsFromURL();

  const dashboardParams = {
    page: paginationCurrentPage,
  } as DashboardUrlParamsType;

  if (selectedInquiry) {
    dashboardParams.inquiryId = selectedInquiry.inquiryId;
  }

  if (inquiryLogId) {
    dashboardParams.inquiryLogId = inquiryLogId;
  }

  if (rating) {
    dashboardParams.rating = rating;
  }

  if (appliedFilters) {
    if (appliedFilters.eventValue) {
      dashboardParams.eventValue = appliedFilters.eventValue;
    }
    if (appliedFilters.startDateValue) {
      const dateObject =
        typeof appliedFilters.startDateValue === "object"
          ? appliedFilters.startDateValue
          : new Date(appliedFilters.startDateValue);

      dashboardParams.startDateValue = format(dateObject, SYSTEM_DATE_FORMAT);
    }
    if (appliedFilters.endDateValue) {
      const dateObject =
        typeof appliedFilters.endDateValue === "object"
          ? appliedFilters.endDateValue
          : new Date(appliedFilters.endDateValue);

      dashboardParams.endDateValue = format(dateObject, SYSTEM_DATE_FORMAT);
    }
    if (appliedFilters.statusFilters.open) {
      dashboardParams.open = "true";
    }
    if (appliedFilters.statusFilters.completed) {
      dashboardParams.completed = "true";
    }
    if (appliedFilters.statusFilters.closed) {
      dashboardParams.closed = "true";
    }
    if (appliedFilters.statusFilters.booked) {
      dashboardParams.booked = "true";
    }
  }

  return {
    pathname: baseUrl,
    query: dashboardParams,
  };
}
