import { APP_API, APP_TENANT } from "@/src/const";
import { BlaceV1Type, BlaceV2Type, VenueType } from "@/src/type";
import { HTTP, HTTPResponse } from "@/src/util";
import { ProxyViaBlaceV2API } from "./";

/**
 * Search for a venue
 * @param city - The city that the client is filtering on
 * @param page - The page number of the results the client wants to see
 * @param perPage - How many results per page the clinet wants to see
 * @param orderBy - How should the results be ordered by
 * @param partner
 * @param includeRelations
 * @returns {Promise<HTTPResponse<BlaceV1Type.PagedResults<VenueType.VenueDetails>>>} - page results for veneue data
 */
export async function getVenueSearchResults(
  city?: string,
  page?: number,
  perPage?: number,
  orderBy?: string,
  partner?: string,
  includeRelations?: boolean
  //Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<T>>>
): Promise<
  HTTPResponse<
    BlaceV2Type.BlaceV2Response<BlaceV1Type.PagedResults<VenueType.VenueItem>>
  >
> {
  const params = `?city=${city ?? "new-york"}&page=${page ?? "1"}&per_page=${
    perPage ?? "16"
  }&orderBy=${orderBy ?? "name"}&partner=${
    partner ?? "blace"
  }&includeRelations=${includeRelations ?? "true"}`;
  return await ProxyViaBlaceV2API.proxy<BlaceV1Type.PagedResults<VenueType.VenueItem>>(
    HTTP.methods.GET,
    `/venue${params}`,
    undefined
  );
}

/**
 * Get a Venue's data by the slug.  The slug is a unique string value for the url to find a venue.
 * @param slug - the unique slug that is passed to the url of the page for a venue
 * @returns {Promise<HTTPResponse<VenueType.VenueDetails>>} - venue data
 */
export async function getVenueBySlug(
  slug: string
): Promise<HTTPResponse<VenueType.VenueItem>> {
  const action = new HTTP<VenueType.VenueItem>(
    `${APP_API}/venue/slug/${slug}?with_exclusive=true`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}
