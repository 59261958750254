import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { CMSLogic } from "@/src/model";
import { StrapiCMSAPI } from "@/src/service";
import { StrapiCMSType } from "@/src/type";
import { Log } from "@/src/util";
import { CMSContext, CMSContextType } from "./CMSContext";

interface WithCMSProps {
  locale: string;
  globalWebConfig: StrapiCMSType.GlobalWebConfigResponse;
  dontLoadConfig?: boolean;
}

function WithCMS({
  children,
  locale,
  globalWebConfig,
  dontLoadConfig,
}: PropsWithChildren<WithCMSProps>) {
  const [state, setState] =
    useState<StrapiCMSType.GlobalWebConfigResponse>(globalWebConfig);

  //if global config is missing run an effect to fallback
  useEffect(() => {
    if (globalWebConfig?.data?.attributes || dontLoadConfig) {
      return;
    }

    Log.logToConsoleDebug(
      "WithCMSContext.tsx",
      "Loading CMS Global Web Config in Context",
      []
    );

    (async () => {
      const GlobalWebConfigResponse = await StrapiCMSAPI.getGlobalWebConfig(
        locale
      );
      if (GlobalWebConfigResponse?.response?.data?.attributes) {
        setState(GlobalWebConfigResponse?.response);
      }
    })();
  }, [globalWebConfig, locale, dontLoadConfig]);

  /**
   * A function to construct a relative root using the `d` prefix and locale code
   * @param path - the page path without the `/d/${locale}`
   * @returns
   */
  function constructLink(path: string) {
    return CMSLogic.constructLink(path, locale);
  }

  return (
    <CMSContext.Provider
      value={{
        globalWebConfig: state,
        locale,
        constructLink,
      }}
    >
      {children}
    </CMSContext.Provider>
  );
}

function useCMS(): CMSContextType {
  return useContext(CMSContext);
}

export { useCMS, WithCMS };
