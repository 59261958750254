import React from "react";

export function FeedbackIconSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#ECF5FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9512 6H12.0488C13.2644 5.99998 14.2443 5.99997 15.0149 6.10358C15.815 6.21115 16.4887 6.44127 17.0237 6.97631C17.5587 7.51135 17.7889 8.18502 17.8964 8.98511C18 9.75575 18 10.7356 18 11.9512V12.0488C18 13.2644 18 14.2443 17.8964 15.0149C17.7889 15.815 17.5587 16.4887 17.0237 17.0237C16.4887 17.5587 15.815 17.7889 15.0149 17.8964C14.2443 18 13.2644 18 12.0488 18H8.00001L7.96375 18C7.68103 18.0001 7.40628 18.0001 7.17966 17.9696C6.92419 17.9353 6.63268 17.8516 6.39053 17.6095C6.14839 17.3673 6.06471 17.0758 6.03036 16.8203C5.9999 16.5937 5.99995 16.319 6 16.0363L6.00001 16L6.00001 11.9512C5.99999 10.7356 5.99998 9.75575 6.10359 8.98511C6.21116 8.18502 6.44128 7.51135 6.97632 6.97631C7.51136 6.44127 8.18502 6.21115 8.98512 6.10358C9.75576 5.99997 10.7356 5.99998 11.9512 6ZM9.16278 7.42502C8.51054 7.51271 8.16514 7.67311 7.91913 7.91912C7.67312 8.16513 7.51272 8.51053 7.42503 9.16278C7.33476 9.8342 7.33334 10.7241 7.33334 12V16C7.33334 16.3331 7.33476 16.5159 7.35181 16.6427L7.35247 16.6475L7.35733 16.6482C7.48412 16.6653 7.66689 16.6667 8.00001 16.6667H12C13.2759 16.6667 14.1658 16.6653 14.8372 16.575C15.4895 16.4873 15.8349 16.3269 16.0809 16.0809C16.3269 15.8349 16.4873 15.4895 16.575 14.8372C16.6653 14.1658 16.6667 13.2759 16.6667 12C16.6667 10.7241 16.6653 9.8342 16.575 9.16278C16.4873 8.51053 16.3269 8.16513 16.0809 7.91912C15.8349 7.67311 15.4895 7.51271 14.8372 7.42502C14.1658 7.33475 13.2759 7.33333 12 7.33333C10.7241 7.33333 9.83421 7.33475 9.16278 7.42502ZM9.33333 10.6667C9.33333 10.2985 9.63181 10 10 10H14C14.3682 10 14.6667 10.2985 14.6667 10.6667C14.6667 11.0349 14.3682 11.3333 14 11.3333H10C9.63181 11.3333 9.33333 11.0349 9.33333 10.6667ZM10 12.6667C9.63181 12.6667 9.33333 12.9651 9.33333 13.3333C9.33333 13.7015 9.63181 14 10 14H12C12.3682 14 12.6667 13.7015 12.6667 13.3333C12.6667 12.9651 12.3682 12.6667 12 12.6667H10Z"
        fill="#205CDF"
      />
    </svg>
  );
};
