import { useMemo } from "react";
import { Link, ListItem } from "@mui/material";
import cn from "classnames";
import styles from "./FooterLinkListItem.module.scss";

interface FooterLinkListItemProps {
  title: string;
  href: string;
  isFromLandingList?: boolean;
}

function FooterLinkListItem({ title, href, isFromLandingList }: FooterLinkListItemProps) {
  const linkStyles = useMemo(() => {
    return cn(
      isFromLandingList
        ? styles.footerLinksTextLanding
        : styles.footerLinksText,
      styles.footerLinksAnimation
    );
  }, [isFromLandingList]);

  return (
    <ListItem className={styles.itemStyles}>
      <Link underline="hover" color="white" className={linkStyles} href={href}>
        {title}
      </Link>
    </ListItem>
  );
}

export default FooterLinkListItem;
