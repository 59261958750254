import React from "react";

export function ErrorFillSVG() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM13.0639 7.06631C13.4544 7.45684 13.4544 8.09 13.0639 8.48053L11.4128 10.1316L13.0625 11.7812C13.453 12.1717 13.453 12.8049 13.0625 13.1954C12.672 13.5859 12.0388 13.5859 11.6483 13.1954L9.99862 11.5458L8.34762 13.1968C7.95709 13.5873 7.32393 13.5873 6.9334 13.1968C6.54288 12.8062 6.54288 12.1731 6.9334 11.7826L8.58441 10.1316L6.93476 8.4819C6.54423 8.09137 6.54423 7.45821 6.93476 7.06768C7.32528 6.67716 7.95845 6.67716 8.34897 7.06768L9.99862 8.71734L11.6496 7.06631C12.0402 6.67579 12.6733 6.67579 13.0639 7.06631Z"
        fill="#B2BAC9"
      />
    </svg>
  );
}
