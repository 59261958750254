import { KEYS } from "@/src/const";
import { AuthType } from "@/src/type";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";

/**
 * Determines does current user in current environment is able to test features
 *
 * @param {AuthType.LoginResponseProfile} currentUser - the profile returned when a user authenticates
 * @returns {boolean}
 */
export function isAvailableForTest(currentUser?: AuthType.LoginResponseProfile): boolean {
  const ENV = SharedConfigManager.getValue(KEYS.ENV);
  if (ENV !== "prod") {
    return true; // everything is available for testing not in production ENV
  }

  return currentUser?.is_admin || false;
}
