import type { PropsWithChildren } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton } from "@mui/material";
import { BaseTransition } from "@/src/component/base/BaseTransition";
import { DragBar } from "@/src/component/base/DragBar";
import { DraggablePaper } from "@/src/component/base/DraggablePaper";
import { useBreakPointUp } from "@/src/hook/style/useBreakPointUp";
import { useDragToClose } from "@/src/hook/useDragToClose";
import type { DialogProps } from "@mui/material";
import styles from "./BaseModal.module.scss";

interface BaseModalProps {
  dialogProps: DialogProps;
  useSlide?: boolean;
  inquiryDialog?: boolean;
  isMobileDraggable?: boolean;
  onExit?: () => void;
}

function BaseModal({
  dialogProps,
  children,
  useSlide = true,
  inquiryDialog = false,
  isMobileDraggable = true,
  onExit,
}: PropsWithChildren<BaseModalProps>) {
  const { open } = dialogProps;
  const customStylesChanges = inquiryDialog
    ? {
        ".MuiDialog-paper": {
          padding: 0,
        },
      }
    : undefined;

  const { dragId, handleDrag, handleDragStop } = useDragToClose({
    onDragStop: onExit,
  });
  const isDesktop = useBreakPointUp("md");

  return (
    open && (
      <Dialog
        keepMounted
        className={styles.baseModal}
        TransitionComponent={useSlide ? BaseTransition : undefined}
        PaperComponent={(props) => (
          <DraggablePaper
            handleStop={handleDragStop}
            handleDrag={handleDrag}
            dragId={dragId}
            {...props}
          />
        )}
        sx={customStylesChanges}
        {...dialogProps}
      >
        {isDesktop || !isMobileDraggable ? (
          <div className={styles.dialogWithExitBtnContainer}>
            <div className={styles.dialogWithExitExitBtnInner}>
              {onExit && (
                <IconButton
                  color="inherit"
                  onClick={onExit}
                  data-testid="close-button"
                >
                  <CloseIcon color="inherit" />
                </IconButton>
              )}
            </div>
          </div>
        ) : (
          onExit && <DragBar dragId={dragId} />
        )}
        {children}
      </Dialog>
    )
  );
}

export default BaseModal;
