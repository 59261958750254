import { createContext } from "react";
import * as FeatureHubTypes from "./FeatureHubTypes";

export type FeatureState = {
  all: FeatureHubTypes.FeatureHubApp;
  map: Record<string, FeatureHubTypes.FeatureHubAppFeature>;
};

export interface FeatureHubContextType {
  features: FeatureState | undefined;
  useFeatureFlag?: <T>(
    key: string,
    fallback: T,
    timeToResetInSeconds: number,
    trackingId?: string
  ) => Promise<T>;
  trackFeatureFlag: (key: string, value: any, trackingId?: string) => void;
}

export const FeatureHubContext = createContext<FeatureHubContextType>({
  features: undefined,
  trackFeatureFlag: () => {},
});
