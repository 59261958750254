interface Props {
  color?: string;
  size?: number;
}

export function WarnSVG({ color = "#EA0000", size = 16 }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${size}`} height={`${size}`} viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.9987 2.66683C5.05318 2.66683 2.66536 5.05464 2.66536 8.00016C2.66536 10.9457 5.05318 13.3335 7.9987 13.3335C10.9442 13.3335 13.332 10.9457 13.332 8.00016C13.332 5.05464 10.9442 2.66683 7.9987 2.66683ZM1.33203 8.00016C1.33203 4.31826 4.3168 1.3335 7.9987 1.3335C11.6806 1.3335 14.6654 4.31826 14.6654 8.00016C14.6654 11.6821 11.6806 14.6668 7.9987 14.6668C4.3168 14.6668 1.33203 11.6821 1.33203 8.00016ZM7.9987 4.66683C8.36689 4.66683 8.66536 4.96531 8.66536 5.3335V8.66683C8.66536 9.03502 8.36689 9.3335 7.9987 9.3335C7.63051 9.3335 7.33203 9.03502 7.33203 8.66683V5.3335C7.33203 4.96531 7.63051 4.66683 7.9987 4.66683ZM8.66536 11.0002C8.66536 10.632 8.36689 10.3335 7.9987 10.3335C7.63051 10.3335 7.33203 10.632 7.33203 11.0002V11.3335C7.33203 11.7017 7.63051 12.0002 7.9987 12.0002C8.36689 12.0002 8.66536 11.7017 8.66536 11.3335V11.0002Z" fill={color} />
    </svg>
  );
}
