import { APP_TENANT, BLACE_V2_API } from "@/src/const";
import { BlaceV2Type } from "@/src/type";
import { HTTP, HTTPResponse } from "./HTTP";

export async function postInquirySubmit(
  data: BlaceV2Type.EventType.Inquiry
): Promise<HTTPResponse<BlaceV2Type.EventType.InquirySubmittedResponse>> {
  const action = new HTTP<BlaceV2Type.EventType.InquirySubmittedResponse>(
    `${BLACE_V2_API}/event/inquiry`,
    HTTP.methods.POST,
    data
  );
  action.setAllEnvId(APP_TENANT);
  action.setAllowGuest(true);
  action.setUseV1Authorization(true);
  return await action.execute();
}

export async function putInquirySubmit(
  inquiryId: string,
  data: BlaceV2Type.EventType.Inquiry
): Promise<HTTPResponse<BlaceV2Type.EventType.InquirySubmittedResponse>> {
  const action = new HTTP<BlaceV2Type.EventType.InquirySubmittedResponse>(
    `${BLACE_V2_API}/event/inquiry/${inquiryId}`,
    HTTP.methods.PUT,
    data
  );
  action.setAllEnvId(APP_TENANT);
  action.setAllowGuest(true);
  action.setUseV1Authorization(true);
  return await action.execute();
}

export async function getInquiry(
  inquiryId: string
): Promise<HTTPResponse<BlaceV2Type.EventType.GetInquiryResponse>> {
  const action = new HTTP<BlaceV2Type.EventType.GetInquiryResponse>(
    `${BLACE_V2_API}/event/inquiry/${inquiryId}`,
    HTTP.methods.GET,
    undefined,
    false
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}
