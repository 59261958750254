import { useCMS } from "@/src/component/provider";
import { URL_PARAMS } from "@/src/const";
import { useNextNavigation } from "@/src/hook/useNextNavigation";
import { AuthLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { Log } from "@/src/util";

type UseInquiryCallbackType = {
  complete: (inquiry: BlaceV2Type.EventType.Inquiry) => void;
};

export function useInquiryCallback(): UseInquiryCallbackType {
  const nextNavigation = useNextNavigation();
  const cms = useCMS();

  async function complete(inquiry: BlaceV2Type.EventType.Inquiry) {
    if (!inquiry?.inquiryId) {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "useInquiryCallback",
        "Unable to complete callback due to missing inquiry object",
        [inquiry]
      );
      const toast = encodeURIComponent("unable to complete inquiry");
      const params = `?${URL_PARAMS.TOAST.TOAST_MESSAGE}=${toast}&${URL_PARAMS.TOAST.TOAST_LEVEL}=${URL_PARAMS.TOAST.VALUES.TOAST_LEVEL.ERROR}`;
      nextNavigation.router.push(cms.constructLink(`/discover${params}`));
      return;
    }

    const currentUser = AuthLogic.getAuthCurrentUser();
    if (!currentUser) {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "useInquiryCallback",
        "Unable to complete callback due to missing currentUser object from auth",
        [inquiry]
      );
      const toast = encodeURIComponent("must be logged in to complete inquiry");
      const params = `?${URL_PARAMS.TOAST.TOAST_MESSAGE}=${toast}&${URL_PARAMS.TOAST.TOAST_LEVEL}=${URL_PARAMS.TOAST.VALUES.TOAST_LEVEL.ERROR}`;
      nextNavigation.router.push(cms.constructLink(`/discover${params}`));
      return;
    }

    inquiry.email = currentUser.email;
    inquiry.clientName = currentUser.name;

    const response = await BlaceV2API.EventServiceV2.putInquirySubmit(
      inquiry.inquiryId ?? "",
      inquiry
    );

    if (response.body?.payload.inquiryId) {
      nextNavigation.router.push(
        cms.constructLink(`/inquiry/${inquiry.inquiryId}/complete`)
      );
      return;
    }

    if (response?.body?.metadata?.statusCode === 409) {
      nextNavigation.router.push(
        cms.constructLink(
          `/inquiry/${inquiry.inquiryId}/complete?${URL_PARAMS.INQUIRY_COMPLETE.STATUS}=v1Error`
        )
      );
      return;
    }

    Log.logToDataDog(
      Log.LogLevel.WARN,
      "useInquiryCallback.tsx",
      "Unable to complete inquiry",
      [inquiry]
    );

    const toast = encodeURIComponent(
      "Your inquiry could not be completed at this time"
    );
    const params = `?${URL_PARAMS.TOAST.TOAST_MESSAGE}=${toast}&${URL_PARAMS.TOAST.TOAST_LEVEL}=${URL_PARAMS.TOAST.VALUES.TOAST_LEVEL.WARN}`;
    nextNavigation.router.push(cms.constructLink(`/discover${params}`));
  }

  return {
    complete,
  };
}
