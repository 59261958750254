import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import { Divider, Typography } from "@mui/material";
import { BlaceLogoSmallSVG } from "@/src/asset";
import { NO_PRICE_TEXT, PRICE_STARTING_PRE_TEXT } from "@/src/const";
import styles from "./InquiryForm.module.scss";

export interface InquiryFormHeaderProps {
  isExclusive?: boolean;
  primaryContact?: string;
  pricing: string;
  hideStartingAt?: boolean;
  altStartingAt?: string;
}

function InquiryFormHeader({
  isExclusive,
  primaryContact,
  pricing,
  hideStartingAt,
  altStartingAt,
}: InquiryFormHeaderProps) {
  return (
    <>
      <div className={styles.inquiryFormSeller}>
        <div className={styles.inquiryFormSellerAvatar}>
          {isExclusive && <BlaceLogoSmallSVG color="#000" size={30} />}
          {!isExclusive && (
            <div>
              <AccountCircleTwoToneIcon fontSize={"inherit"} />
            </div>
          )}
        </div>
        <div className={styles.inquiryFormSellerInfo}>
          <Typography variant="h4">{`${
            primaryContact ?? "A Trusted Partner"
          }`}</Typography>
          {isExclusive && (
            <Typography variant="subtitle2">
              Exclusively listed by BLACE
            </Typography>
          )}
          {!isExclusive && (
            <Typography variant="subtitle2">
              Listed by a BLACE verified partner
            </Typography>
          )}
        </div>
      </div>
      <Divider />
      <div className={styles.inquiryFormPricing}>
        {pricing !== NO_PRICE_TEXT && !hideStartingAt && (
          <Typography variant="h6">{PRICE_STARTING_PRE_TEXT}</Typography>
        )}
        {hideStartingAt && typeof altStartingAt === "string" && (
          <Typography variant="h6">{altStartingAt}</Typography>
        )}
        <Typography variant="h2">{pricing}</Typography>
      </div>
    </>
  );
}

export default InquiryFormHeader;
