import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  Link as MUILink,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Link from "next/link";
import { GoogleFullColorLogoSVG, LinkedInColorLogoSVG } from "@/src/asset";
import { BaseButton } from "@/src/component/base";
import { MagicLinkSent } from "@/src/component/partial/MagicLinkSent";
import { SplitLayoutFormContainer } from "@/src/component/partial/SplitLayoutFormContainer";
import { useCMS } from "@/src/component/provider";
import { APP_API } from "@/src/const";
import { useSignUp } from "@/src/hook";
import { FormHelper } from "@/src/util";
import type { SignUpTypeFormik } from "@/src/hook";
import styles from "./SignUpForm.module.scss";

function SingUpForm() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const signup = useSignUp();
  const cms = useCMS();

  function isDisabled(): boolean {
    //check for WITH magic link
    if (
      signup.formik.values.magicLink &&
      signup.formik.values.agreed &&
      signup.formik.values.name &&
      !FormHelper.formikCheckError<SignUpTypeFormik>(signup.formik, "name") &&
      signup.formik.values.email &&
      !FormHelper.formikCheckError<SignUpTypeFormik>(signup.formik, "email")
    ) {
      return false;
    }
    //check for WITHOUT magic link
    else if (
      !signup.formik.values.magicLink &&
      signup.formik.values.agreed &&
      signup.formik.values.name &&
      !FormHelper.formikCheckError<SignUpTypeFormik>(signup.formik, "name") &&
      signup.formik.values.email &&
      !FormHelper.formikCheckError<SignUpTypeFormik>(signup.formik, "email") &&
      (signup.formik.values.password ?? "").length > 7 &&
      !FormHelper.formikCheckError<SignUpTypeFormik>(signup.formik, "password")
    ) {
      return false;
    }
    //OTHERWISE disabled
    return true;
  }

  /**
   * after a registration is complete let the user now they must confirm email
   */
  if (signup.saveComplete) {
    return (
      <MagicLinkSent
        title={"MAGIC LINK SENT"}
        message={`A confirmation email has been sent to ${signup.formik.values.email}.  Please check your email to complete your sign up.`}
      />
    );
  }

  return (
    <SplitLayoutFormContainer
      title={"SIGN UP"}
      message={
        "Create your free account to access premier Venues and Vendors for your event production"
      }
    >
      <>
        {!signup.saveComplete && (
          <form
            onSubmit={signup.formik.handleSubmit}
            className={styles.signUpFormEmail}
          >
            <TextField
              label="Name"
              fullWidth
              onChange={e => {
                signup.formik.setFieldValue("name", e?.target?.value ?? "");
              }}
              value={signup.formik.values.name}
              helperText={FormHelper.formikErrorMessage<SignUpTypeFormik>(
                signup.formik,
                "name"
              )}
              error={FormHelper.formikCheckError<SignUpTypeFormik>(
                signup.formik,
                "name"
              )}
            />
            <TextField
              label="Email"
              fullWidth
              onChange={e => {
                signup.formik.setFieldValue("email", e?.target?.value ?? "");
              }}
              value={signup.formik.values.email}
              helperText={FormHelper.formikErrorMessage<SignUpTypeFormik>(
                signup.formik,
                "email"
              )}
              error={FormHelper.formikCheckError<SignUpTypeFormik>(
                signup.formik,
                "email"
              )}
            />
            {!signup.formik.values.magicLink && (
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                onChange={e => {
                  signup.formik.setFieldValue(
                    "password",
                    e?.target?.value ?? "",
                    false
                  );
                }}
                value={signup.formik.values.password}
                helperText={FormHelper.formikErrorMessage<SignUpTypeFormik>(
                  signup.formik,
                  "password"
                )}
                error={FormHelper.formikCheckError<SignUpTypeFormik>(
                  signup.formik,
                  "password"
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <FormControlLabel
              className={styles.signUpFormCheckbox}
              label={
                <Tooltip title="A magic link allows you to login via a link sent to your email without using a password">
                  <Typography variant="body2">Use Magic Link</Typography>
                </Tooltip>
              }
              control={
                <Checkbox
                  value={signup.formik.values.magicLink}
                  checked={signup.formik.values.magicLink}
                  onChange={() => {
                    signup.formik.setFieldValue(
                      "magicLink",
                      !signup.formik.values.magicLink
                    );
                  }}
                />
              }
            />
            <FormControlLabel
              className={styles.signUpFormCheckbox}
              label={
                <Typography variant="body2">
                  By creating an account, I agree that I have read and accepted
                  the <MUILink href="/terms-of-use">Terms of Service</MUILink>{" "}
                  &amp; <MUILink href="/privacy-policy">Privacy Policy</MUILink>
                </Typography>
              }
              control={
                <Checkbox
                  value={signup.formik.values.agreed}
                  checked={signup.formik.values.agreed}
                  onChange={() => {
                    signup.formik.setFieldValue(
                      "agreed",
                      !signup.formik.values.agreed
                    );
                  }}
                />
              }
            />
            <BaseButton
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              loading={signup.isLoading}
              disabled={isDisabled()}
            >
              CREATE FREE ACCOUNT
            </BaseButton>
            {signup.hasError && (
              <Typography color="error">{signup.hasError}</Typography>
            )}
          </form>
        )}
        <Divider>
          <Typography variant="subtitle2">OR</Typography>
        </Divider>
        <a href={`${APP_API}/auth/google`} className={styles.buttonLink}>
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            <div className={styles.signUpFormBttnInner}>
              <GoogleFullColorLogoSVG />
              CONTINUE WITH GOOGLE
            </div>
          </BaseButton>
        </a>
        <a href={`${APP_API}/auth/linkedin`} className={styles.buttonLink}>
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            <div className={styles.signUpFormBttnInner}>
              <LinkedInColorLogoSVG size={24} />
              CONTINUE WITH LINKEDIN
            </div>
          </BaseButton>
        </a>
        <Divider>
          <Typography variant="subtitle2">OR</Typography>
        </Divider>
        <Link
          href={cms.constructLink(
            `/auth${
              signup.formik.values.email
                ? `?email=${signup.formik.values.email}`
                : ""
            }`
          )}
          aria-label="Go to Sign In"
          className={styles.buttonLink}
        >
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            <div className={styles.signUpFormBttnInner}>LOG IN</div>
          </BaseButton>
        </Link>
      </>
    </SplitLayoutFormContainer>
  );
}

export default SingUpForm;
