import { BaseButton } from "@src/component/base";
import styles from "./InquiryForm.module.scss";
import InquiryFormFooter from "./InquiryFormFooter";
import InquiryFormHeader, { InquiryFormHeaderProps } from "./InquiryFormHeader";

interface InquiryFormCardProps extends InquiryFormHeaderProps {
  setInquiryDrawerOpen: () => void;
}

function InquiryFormCard({
  isExclusive,
  primaryContact,
  pricing,
  hideStartingAt,
  altStartingAt,
  setInquiryDrawerOpen,
}: InquiryFormCardProps) {
  return (
    <section className={styles.inquiryFormContainerCard}>
      <InquiryFormHeader
        isExclusive={isExclusive}
        primaryContact={primaryContact}
        pricing={pricing}
        hideStartingAt={hideStartingAt}
        altStartingAt={altStartingAt}
      />
      <div className={styles.inquiryFormCardInfo}>
        <BaseButton
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={setInquiryDrawerOpen}
        >
          Contact Us
        </BaseButton>
      </div>
      <InquiryFormFooter isExclusive={isExclusive} />
    </section>
  );
}

export default InquiryFormCard;
