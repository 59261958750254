import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { EMAIL_VALIDATION_REGEXP } from "@/src/const";
import { useNextNavigation } from "@/src/hook/useNextNavigation";
import { ActivityLogic, AuthLogic } from "@/src/model";
import { BlaceV1API } from "@/src/service";
import { AuthType } from "@/src/type";
import { URLHelper } from "@/src/util";
import type { FormikProps } from "formik";

export type SignInTypeFormik = AuthType.LoginRequest;

export type SignInType = {
  isLoading: boolean;
  saveComplete: boolean;
  hasError?: string;
  formik: FormikProps<SignInTypeFormik>;
  magicLinkSent: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_VALIDATION_REGEXP, "Must be a valid email")
    .required("Email is required"),
});

export function useSignIn(): SignInType {
  const nextNavigation = useNextNavigation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [magicLinkSent, setMagicLinkSent] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saveComplete, setSaveComplete] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string | undefined>(undefined);

  const formik = useFormik<SignInTypeFormik>({
    initialValues: {
      email: "",
      magicLink: true,
      remember: true,
      password: "",
    },
    validationSchema,
    onSubmit: async values => {
      setIsLoading(true);
      const response = await BlaceV1API.AuthService.postSignIn(values);
      const token = response.body?.data?.token;
      const currentUser = response.body?.data;

      if (response.success) {
        ActivityLogic.ga4Tracking("login", {}, true);
        await ActivityLogic.toActivityService({
          action: "login",
          locationInApp: "useSignIn.ts",
        });
      }

      if (response.success && typeof token === "string" && currentUser) {
        AuthLogic.storeAuthToken(token, currentUser);

        const callbackPath = await AuthLogic.getAuthCallbackPath(currentUser);
        await AuthLogic.performRedirectAfterAuth(
            currentUser,
            token,
            callbackPath,
            nextNavigation.router
        );
      } else if (response.success && response?.body?.success) {
        setMagicLinkSent(true);
      } else {
        if (response.body?.error) {
          setHasError(response.body?.error);
        } else {
          setHasError("Unable to complete login, please try again");
        }
      }
      setIsLoading(false);
    },
  });

  /**
   * set the email if provided in the url
   */
  useEffect(() => {
    const email = URLHelper.urlGetParameter("email");
    if (email) {
      formik.setFieldValue("email", email);
    }
  }, []);

  /**
   * after the user starts changing the form remove the error
   */
  useEffect(() => {
    if (!hasError) {
      return;
    }

    function handleKeyDown() {
      setHasError(undefined);
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [hasError]);

  /**
   * Update password error based on if magic link or not
   */
  useEffect(() => {
    if (formik.values.magicLink) {
      formik.setFieldError("password", undefined);
      return;
    }

    if ((formik.values.password ?? "").length < 6) {
      formik.setFieldError("password", "A password is required");
    } else {
      formik.setFieldError("password", undefined);
    }
  }, [formik.values.password]);

  return {
    isLoading,
    saveComplete,
    hasError,
    formik,
    magicLinkSent,
  };
}
