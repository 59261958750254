export function copyToClipboard(value: string) {
  if (typeof navigator !== "undefined") {
    try {
      navigator.clipboard.writeText(value);
      return;
    } catch (err) {
      /** ignore this error */
    }
  }

  const temp = document.createElement("textarea");
  temp.value = value;
  temp.setAttribute("readonly", "");
  temp.style.position = "absolute";
  temp.style.left = "-9999px";
  document.body.appendChild(temp);
  temp.select();
  document.execCommand("copy");
  document.body.removeChild(temp);
}
