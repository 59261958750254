import type { HTMLAttributes } from "react";

/**
 * CMS (content management system) app types
 */

export interface CmsComponentProps<T> extends HTMLAttributes<HTMLElement> {
  data: T;
  appMode?: "discovery" | "management";
}

export interface ContentModuleProps<T, Placement> {
  data: T;
  placement: Placement;
  siteSlug?: string;
}

export interface MediaQueries<T> {
  xs: T;
  s: T;
  m: T;
  l: T;
  xl: T;
}

export type ImageObjectFit =
  | "cover"
  | "contain"
  | "fill"
  | "scale-down"
  | "none";

/**
 * Listing app types
 */

export enum ListingDisplayVariant {
  full = "full",
  map = "map",
}

export enum QueryParams {
  VENUE_TABS = "venueTabs",
}
