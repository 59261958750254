import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { FooterContactUsItem } from "@/src/component/partial/Footer/component/FooterContactUsItem";
import { Footer } from "@/src/type/strapiCMS/components/partial";
import styles from "./FooterContactInfo.module.scss";

interface FooterContactInfoProps {
  footer: Footer;
}

function FooterContactInfo({ footer }: FooterContactInfoProps) {
  return (
    <div className={styles.footerContactInfo}>
      {footer?.address && (
        <FooterContactUsItem
          Icon={<LocationOnIcon fontSize="medium" color="secondary" />}
          title={footer.address}
          href="/"
        />
      )}
      {footer?.emailAddress?.emailAddress && (
        <FooterContactUsItem
          Icon={<MailOutlineIcon fontSize="medium" color="secondary" />}
          title={footer.emailAddress.label}
          href={`mailto:${footer.emailAddress.emailAddress}`}
        />
      )}
      {footer?.phoneNumber?.phoneNumber && (
        <FooterContactUsItem
          Icon={<CallIcon fontSize="medium" color="secondary" />}
          title={footer.phoneNumber.label}
          href={`tel:${footer.phoneNumber.phoneNumber}`}
        />
      )}
    </div>
  );
}

export default FooterContactInfo;
