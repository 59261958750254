export function CapacityCabaretSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="21"
      viewBox="0 0 56 21"
      fill="none"
    >
      <path
        d="M20.1797 13.9768C19.5727 17.8549 15.8971 20.5147 11.967 19.9157C8.03698 19.3167 5.34597 15.6864 5.95301 11.8084C6.56006 7.93028 10.2351 5.27426 14.1651 5.87328C18.0951 6.4723 20.7868 10.0987 20.1797 13.9768Z"
        fill="black"
      />
      <path
        d="M8.04246 3.92138C7.69977 6.11065 4.37685 5.60417 4.71954 3.4149C5.06224 1.22563 8.38515 1.73211 8.04246 3.92138Z"
        fill="#1D39C4"
      />
      <path
        d="M15.7335 2.14474C15.3908 4.33401 12.0679 3.82753 12.4106 1.63826C12.7525 -0.546087 16.0754 -0.0396059 15.7335 2.14474Z"
        fill="#1D39C4"
      />
      <path
        d="M22.5414 5.03075C22.1987 7.22002 18.8708 6.71278 19.2135 4.52351C19.5555 2.33917 22.8834 2.84641 22.5414 5.03075Z"
        fill="#1D39C4"
      />
      <path
        d="M25.8769 10.9819C25.5342 13.1712 22.2063 12.664 22.549 10.4747C22.8909 8.29034 26.2188 8.79758 25.8769 10.9819Z"
        fill="#1D39C4"
      />
      <path
        d="M25.4618 17.4759C25.1191 19.6651 21.7912 19.1579 22.1339 16.9686C22.4765 14.7793 25.8044 15.2866 25.4618 17.4759Z"
        fill="#1D39C4"
      />
      <path
        d="M3.57932 10.5957C3.2374 12.78 -0.0855113 12.2736 0.256409 10.0892C0.599101 7.89995 3.92201 8.40643 3.57932 10.5957Z"
        fill="#1D39C4"
      />
      <path
        d="M3.91585 17.6648C3.57316 19.8541 0.245261 19.3468 0.587952 17.1576C0.930644 14.9683 4.25855 15.4755 3.91585 17.6648Z"
        fill="#1D39C4"
      />
      <path
        d="M50.0466 13.9768C49.4396 17.8549 45.764 20.5147 41.834 19.9157C37.9039 19.3167 35.2129 15.6864 35.82 11.8084C36.427 7.93028 40.102 5.27426 44.032 5.87328C47.9621 6.4723 50.6537 10.0987 50.0466 13.9768Z"
        fill="black"
      />
      <path
        d="M37.9092 3.92138C37.5665 6.11065 34.2436 5.60417 34.5862 3.4149C34.9289 1.22563 38.2518 1.73211 37.9092 3.92138Z"
        fill="#1D39C4"
      />
      <path
        d="M45.6002 2.14474C45.2575 4.33401 41.9346 3.82753 42.2773 1.63826C42.6192 -0.546087 45.9421 -0.0396059 45.6002 2.14474Z"
        fill="#1D39C4"
      />
      <path
        d="M52.4079 5.03075C52.0652 7.22002 48.7373 6.71278 49.08 4.52351C49.4219 2.33917 52.7498 2.84641 52.4079 5.03075Z"
        fill="#1D39C4"
      />
      <path
        d="M55.7436 10.9819C55.4009 13.1712 52.073 12.664 52.4157 10.4747C52.7576 8.29034 56.0855 8.79758 55.7436 10.9819Z"
        fill="#1D39C4"
      />
      <path
        d="M55.3287 17.4759C54.986 19.6651 51.6581 19.1579 52.0008 16.9686C52.3435 14.7793 55.6714 15.2866 55.3287 17.4759Z"
        fill="#1D39C4"
      />
      <path
        d="M33.4463 10.5957C33.1043 12.78 29.7814 12.2736 30.1234 10.0892C30.466 7.89995 33.789 8.40643 33.4463 10.5957Z"
        fill="#1D39C4"
      />
      <path
        d="M33.7828 17.6648C33.4401 19.8541 30.1122 19.3468 30.4549 17.1576C30.7976 14.9683 34.1255 15.4755 33.7828 17.6648Z"
        fill="#1D39C4"
      />
    </svg>
  );
}
