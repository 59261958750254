export const BaseButtonStyles = {
  white: {
    backgroundColor: "var(--common-white)",
    color: "var(--primary-dark)",
    border: "none",
    "&:hover": {
      backgroundColor: "var(--common-white)",
      color: "var(--primary-dark)",
      border: "none",
      opacity: ".98",
    },
  },
  whiteWithBorder: {
    backgroundColor: "var(--common-white)",
    color: "var(--primary-dark)",
    "&:hover": {
      backgroundColor: "var(--common-white)",
      color: "var(--primary-dark)",
      opacity: ".98",
    },
  },
};
