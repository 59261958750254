import { useEffect } from "react";

export function useWidget() {
  useEffect(() => {
    const links = document.querySelectorAll("a");
    if (links) {
      //@ts-ignore
      for (const link of links ?? []) {
        link.setAttribute("target", "_parent");
      }
    }
  }, []);
}
