import { PropsWithChildren } from "react";
import { TestableLogic } from "@src/model";
import { AuthType } from "@src/type";

interface TestableComponentProps {
  currentUser?: AuthType.LoginResponseProfile;
}

function TestableComponent({
  currentUser,
  children
}: PropsWithChildren<TestableComponentProps>) {
  return TestableLogic.isAvailableForTest(currentUser) && (children);
}

export default TestableComponent;
