import Link from "next/link";
import { BaseButton } from "@/src/component/base";
import { SplitLayoutFormContainer } from "@/src/component/partial/SplitLayoutFormContainer";
import { useCMS } from "@/src/component/provider";
import { HOME_TO } from "@/src/const";

interface MagicLinkSentProps {
  title: string;
  message: string;
}

function MagicLinkSent({ title, message }: MagicLinkSentProps) {
  const cms = useCMS();
  return (
    <SplitLayoutFormContainer title={title} message={message}>
      <Link href={cms.constructLink(HOME_TO)} aria-label="Go to Search">
        <BaseButton
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          loading={false}
        >
          Return Home
        </BaseButton>
      </Link>
    </SplitLayoutFormContainer>
  );
}

export default MagicLinkSent;
