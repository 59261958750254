import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Link from "next/link";
import { GoogleFullColorLogoSVG, LinkedInColorLogoSVG } from "@/src/asset";
import { BaseButton } from "@/src/component/base";
import { MagicLinkSent } from "@/src/component/partial/MagicLinkSent";
import { SplitLayoutFormContainer } from "@/src/component/partial/SplitLayoutFormContainer";
import { useCMS } from "@/src/component/provider";
import { APP_API } from "@/src/const";
import { useSignIn } from "@/src/hook";
import { FormHelper } from "@/src/util";
import type { SignInTypeFormik } from "@/src/hook";
import styles from "./SignInForm.module.scss";

function SignInForm() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  //const [message, setMessage] = useState<string | undefined>();
  const signin = useSignIn();
  const cms = useCMS();

  /**
   * TURN OFF SET MESSAGE IN URL PARAMS
  useEffect(() => {
    const checkMessage = URLHelper.urlGetParameter("message");
    if (typeof checkMessage === "string" && checkMessage.length > 10) {
      setMessage(checkMessage);
      return;
    }
    setMessage("Book premier Venues and Vendors for your event production");
  }, []);
  */

  function isDisabled(): boolean {
    //check for WITH magic link
    if (
      signin.formik.values.magicLink &&
      signin.formik.values.email &&
      !FormHelper.formikCheckError<SignInTypeFormik>(signin.formik, "email")
    ) {
      return false;
    }
    //check for WITHOUT magic link
    else if (
      !signin.formik.values.magicLink &&
      signin.formik.values.email &&
      !FormHelper.formikCheckError<SignInTypeFormik>(signin.formik, "email") &&
      (signin.formik.values.password ?? "").length > 7 &&
      !FormHelper.formikCheckError<SignInTypeFormik>(signin.formik, "password")
    ) {
      return false;
    }

    //OTHERWISE disabled
    return true;
  }

  /**
   * when the user selects a magic link for login respond back success when completed
   */
  if (signin.magicLinkSent) {
    return (
      <MagicLinkSent
        title={"MAGIC LINK SENT"}
        message={`A magic link has been sent to ${signin.formik.values.email}.  Please check your email to complete your log in.`}
      />
    );
  }

  /// title={"MAGIC LINK SENT"}
  //isLogin={`${isLogin ? "A magic link" : "An email confirmation"} has been sent to ${email}.Please check your email to complete your ${isLogin ? "log in" : "sign up"}.`}

  return (
    <SplitLayoutFormContainer
      title={"LOG IN"}
      message={
        "Log in to access premier Venues and Vendors for your event production"
      }
    >
      <>
        {!signin.saveComplete && (
          <form
            onSubmit={signin.formik.handleSubmit}
            className={styles.signinFormEmail}
          >
            <TextField
              label="Email"
              fullWidth
              onChange={e => {
                signin.formik.setFieldValue("email", e?.target?.value ?? "");
              }}
              value={signin.formik.values.email}
              helperText={FormHelper.formikErrorMessage<SignInTypeFormik>(
                signin.formik,
                "email"
              )}
              error={FormHelper.formikCheckError<SignInTypeFormik>(
                signin.formik,
                "email"
              )}
            />
            {!signin.formik.values.magicLink && (
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                onChange={e => {
                  signin.formik.setFieldValue(
                    "password",
                    e?.target?.value ?? "",
                    false
                  );
                }}
                value={signin.formik.values.password}
                helperText={FormHelper.formikErrorMessage<SignInTypeFormik>(
                  signin.formik,
                  "password"
                )}
                error={FormHelper.formikCheckError<SignInTypeFormik>(
                  signin.formik,
                  "password"
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <div className={styles.linkBox}>
              <FormControlLabel
                label={<Typography variant="body2">Use Magic Link</Typography>}
                control={
                  <Checkbox
                    value={signin.formik.values.magicLink}
                    checked={signin.formik.values.magicLink}
                    onChange={() => {
                      signin.formik.setFieldValue(
                        "magicLink",
                        !signin.formik.values.magicLink
                      );
                    }}
                  />
                }
                aria-label="Use Magic Link"
              />
              <Link
                href={cms.constructLink("/auth/forgot-password")}
                aria-label="Forgot Password"
              >
                <Typography variant="button">Forgot Password</Typography>
              </Link>
            </div>
            <BaseButton
              loading={signin.isLoading}
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disabled={isDisabled()}
            >
              LOG IN
            </BaseButton>
            {signin.hasError && (
              <Typography color="error">{signin.hasError}</Typography>
            )}
          </form>
        )}
        <Divider>
          <Typography variant="subtitle2">OR</Typography>
        </Divider>
        <a href={`${APP_API}/auth/google`} className={styles.buttonLink}>
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            <div className={styles.signinFormBttnInner}>
              <GoogleFullColorLogoSVG />
              CONTINUE WITH GOOGLE
            </div>
          </BaseButton>
        </a>
        <a href={`${APP_API}/auth/linkedin`} className={styles.buttonLink}>
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            <div className={styles.signinFormBttnInner}>
              <LinkedInColorLogoSVG size={24} />
              CONTINUE WITH LINKEDIN
            </div>
          </BaseButton>
        </a>
        <Divider>
          <Typography variant="subtitle2">OR</Typography>
        </Divider>
        <Link
          href={cms.constructLink(
            `/auth/sign-up${
              signin.formik.values.email
                ? `?email=${signin.formik.values.email}`
                : ""
            }`
          )}
          aria-label="Go to Free Sign Up"
          className={styles.buttonLink}
        >
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            <div className={styles.signinFormBttnInner}>FREE SIGN UP</div>
          </BaseButton>
        </Link>
      </>
    </SplitLayoutFormContainer>
  );
}

export default SignInForm;
