export function arraySortByKey(
  array: Array<any>,
  key: string,
  desc: boolean = false
) {
  return array.sort(function (a, b) {
    const x = a[key];
    const y = b[key];
    if (desc) {
      return x > y ? -1 : x < y ? 1 : 0;
    }
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function arrayParseToPairs(arr: Array<any>) {
  const result = [];
  if (arr) {
    let buildArr = [];
    let right = false;
    for (const opt of arr) {
      buildArr.push(opt);

      if (right) {
        result.push(buildArr);
        right = false;
        buildArr = [];
      } else {
        right = true;
      }
    }
  }
  return result;
}

export function arrayShuffle(a: Array<any>) {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}
