import { CatalogItemImage } from "@/src/type/blaceV2";
import { ArrayHelper, ImageFromCDN } from "@/src/util";

export interface ItemImages {
  images: CatalogItemImage[];
}

/**
 * Get url of the key image
 * @param {ItemImages} item - the catalog or search item, or item that has images
 * @param {number} quality - needed quality of image
 * @param {number} width - needed width of image
 * @returns {string}
 */
export function getMainImageUrl<T extends ItemImages>(
  item?: T,
  quality?: number,
  width?: number
): string {
  if (!item?.images?.length) {
    return "";
  }

  const image: CatalogItemImage = ArrayHelper.arraySortByKey(
    item.images,
    "order"
  )[0];

  return getImageUrl(image?.imageHash, quality, width);
}

export function getImageUrl(
  imageHash?: string,
  quality?: number,
  width?: number
): string {
  if (!imageHash) {
    return "";
  }

  return ImageFromCDN.imageSizeAndQuality(
    ensureImageExtension(imageHash),
    quality || 90,
    width || 400,
    true
  );
}

export function ensureImageExtension(filename: string): string {
  if (!filename) {
    return filename;
  }

  const regex = /\.\w{3,4}$/;

  if (regex.test(filename)) {
    return filename;
  }

  return filename + ".png";
}
