export function CapacityStandingSVG() {
  return (
    <svg
      width="31"
      height="26"
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.97354 2.35561C3.5937 4.75927 -0.0949938 4.20236 0.28485 1.79869C0.663839 -0.599565 4.35253 -0.0426499 3.97354 2.35561Z"
        fill="#1D39C4"
      />
      <path
        d="M12.5082 2.35561C12.1284 4.75927 8.43967 4.20236 8.81952 1.79869C9.19851 -0.599565 12.8872 -0.0426499 12.5082 2.35561Z"
        fill="#1D39C4"
      />
      <path
        d="M21.612 2.35561C21.2321 4.75927 17.5434 4.20236 17.9233 1.79869C18.3023 -0.599565 21.991 -0.0426499 21.612 2.35561Z"
        fill="#1D39C4"
      />
      <path
        d="M30.7157 2.35561C30.3359 4.75927 26.6472 4.20236 27.027 1.79869C27.406 -0.599565 31.0947 -0.0426499 30.7157 2.35561Z"
        fill="#1D39C4"
      />
      <path
        d="M3.97354 9.63637C3.5937 12.04 -0.0949938 11.4831 0.28485 9.07946C0.663839 6.6812 4.35253 7.23811 3.97354 9.63637Z"
        fill="#1D39C4"
      />
      <path
        d="M12.5082 9.63637C12.1284 12.04 8.43967 11.4831 8.81952 9.07946C9.19851 6.6812 12.8872 7.23811 12.5082 9.63637Z"
        fill="#1D39C4"
      />
      <path
        d="M21.612 9.63637C21.2321 12.04 17.5434 11.4831 17.9233 9.07946C18.3023 6.6812 21.991 7.23811 21.612 9.63637Z"
        fill="#1D39C4"
      />
      <path
        d="M30.7157 9.63637C30.3359 12.04 26.6472 11.4831 27.027 9.07946C27.406 6.6812 31.0947 7.23811 30.7157 9.63637Z"
        fill="#1D39C4"
      />
      <path
        d="M3.97354 16.9166C3.5937 19.3203 -0.0949938 18.7634 0.28485 16.3597C0.663839 13.9615 4.35253 14.5184 3.97354 16.9166Z"
        fill="#1D39C4"
      />
      <path
        d="M12.5082 16.9166C12.1284 19.3203 8.43967 18.7634 8.81952 16.3597C9.19851 13.9615 12.8872 14.5184 12.5082 16.9166Z"
        fill="#1D39C4"
      />
      <path
        d="M21.612 16.9166C21.2321 19.3203 17.5434 18.7634 17.9233 16.3597C18.3023 13.9615 21.991 14.5184 21.612 16.9166Z"
        fill="#1D39C4"
      />
      <path
        d="M30.7157 16.9166C30.3359 19.3203 26.6472 18.7634 27.027 16.3597C27.406 13.9615 31.0947 14.5184 30.7157 16.9166Z"
        fill="#1D39C4"
      />
      <path
        d="M3.97354 24.1974C3.5937 26.6011 -0.0949938 26.0442 0.28485 23.6405C0.663839 21.2422 4.35253 21.7991 3.97354 24.1974Z"
        fill="#1D39C4"
      />
      <path
        d="M12.5082 24.1974C12.1284 26.6011 8.43967 26.0442 8.81952 23.6405C9.19851 21.2422 12.8872 21.7991 12.5082 24.1974Z"
        fill="#1D39C4"
      />
      <path
        d="M21.612 24.1974C21.2321 26.6011 17.5434 26.0442 17.9233 23.6405C18.3023 21.2422 21.991 21.7991 21.612 24.1974Z"
        fill="#1D39C4"
      />
      <path
        d="M30.7157 24.1974C30.3359 26.6011 26.6472 26.0442 27.027 23.6405C27.406 21.2422 31.0947 21.7991 30.7157 24.1974Z"
        fill="#1D39C4"
      />
    </svg>
  );
}
