import { Link } from "@mui/material";
import styles from "./FooterBottomLinkExtra.module.scss";

interface FooterBottomLinkExtraProps {
  title: string;
  href: string;
  onClick?: () => void;
}

function FooterBottomLinkExtra({
  title,
  href,
  onClick,
}: FooterBottomLinkExtraProps) {
  return (
    <Link
      underline="hover"
      variant="body1"
      color="white"
      href={href}
      onClick={onClick}
      className={styles.footerExtraLink}
    >
      {title}
    </Link>
  );
}

export default FooterBottomLinkExtra;
