import { v4 } from "uuid";

export function uniqueId(withoutDashes: boolean = true) {
  const uniqueId = `${v4()}`;
  if (withoutDashes) {
    const split = uniqueId.split("-");
    let newId = "";
    for (const sequence of split) {
      newId += sequence;
    }
    return newId;
  }
  return uniqueId;
}
