import { APP_TENANT, BLACE_V2_API } from "@/src/const";
import { BlaceV2Type } from "@/src/type";
import { HTTP, HTTPResponse } from "@/src/util";

export async function proxy<T>(
  method: string,
  path?: string,
  body?: string
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<T>>> {
  const action = new HTTP<BlaceV2Type.BlaceV2Response<T>>(
    `${BLACE_V2_API}/proxy/v1?p=${btoa(path ?? "")}`,
    method,
    body
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}
