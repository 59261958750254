//@ts-nocheck
//TODO window.addEventListener is throwing a ts error:
//`Type error: Property 'addEventListener' does not exist on type 'never'.`
import { useEffect, useRef, useState } from "react";
import type { HTMLAttributes, PropsWithChildren } from "react";
import Head from "next/head";

export interface ImgBgResponsiveProps extends HTMLAttributes<HTMLElement> {
  imageUrl: string | null;
  lazy?: "eager" | "lazy";
  prefetch?: boolean;
}

export function ImgBgResponsive({
  lazy = "lazy",
  children,
  imageUrl,
  className,
  prefetch = true,
  style,
  ...attrs
}: PropsWithChildren<ImgBgResponsiveProps>) {
  const lazyRef = useRef<any>();
  const [loadImage, setLoadImage] = useState<boolean>(false);

  useEffect(() => {
    if (lazy === "eager" || loadImage) {
      return;
    }

    let lazyloadThrottleTimeout: any;
    function fallbackLoader() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        const scrollTop = window.pageYOffset;
        const img = lazyRef.current;
        if (img.offsetTop < window.innerHeight + scrollTop) {
          img.src = img.dataset.src;
          setLoadImage(true);
          document.removeEventListener("scroll", fallbackLoader);
          window.removeEventListener("resize", fallbackLoader);
          window.removeEventListener("orientationChange", fallbackLoader);
          clearTimeout(lazyloadThrottleTimeout);
        }
      }, 20);
    }

    if ("IntersectionObserver" in window) {
      const imageObserver = new IntersectionObserver(
        function (entries) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              setLoadImage(true);
              imageObserver.unobserve(entry.target);
            }
          });
        },
        { rootMargin: "-120px 0px 0px 0px" }
      );
      imageObserver.observe(lazyRef.current);
    } else {
      //when browser doesn't support IntersectionObserver
      document.addEventListener("scroll", fallbackLoader);
      window.addEventListener("resize", fallbackLoader);
      window.addEventListener("orientationChange", fallbackLoader);
      fallbackLoader();

      return () => {
        //TODO: test this
        //document.removeEventListener("scroll", fallbackLoader);
        //window.removeEventListener("resize", fallbackLoader);
        //window.removeEventListener("orientationChange", fallbackLoader);
      };
    }
  }, [lazy, loadImage]);

  return (
    <>
      {!loadImage && lazy !== "eager" && prefetch && (
        <Head>
          <link rel="preload" href={imageUrl} as="image" />
        </Head>
      )}
      <div
        ref={lazyRef}
        style={{
          backgroundImage:
            loadImage || lazy === "eager" ? `url(${imageUrl})` : "none",
          backgroundSize: "cover",
          ...style,
        }}
        className={className}
        {...attrs}
      >
        {children}
      </div>
    </>
  );
}
