import React, { useEffect, useState } from "react";
import TextsmsTwoToneIcon from "@mui/icons-material/TextsmsOutlined";
import { Typography } from "@mui/material";
import cn from "classnames";
import Link from "next/link";
import {
  BlaceLogoSmallSVG,
  BlaceLogoSVG,
  BurgerSVG,
  RightArrowSVG,
} from "@/src/asset";
import {
  DRAWER_FIX_TO_HEIGHT,
  DrawerFixedToHeader,
} from "@/src/component/base";
import { GlobalSearch } from "@/src/component/partial";
import { NavigationDrawer } from "@/src/component/partial/Header/component/NavigationDrawer";
import { useApp, useCMS } from "@/src/component/provider";
import { useBreakPointDown } from "@/src/hook";
import styles from "./Header.module.scss";

interface HeaderProps {
  transparentBeforeScroll?: boolean;
}

function Header({ transparentBeforeScroll }: HeaderProps) {
  const cms = useCMS();
  const app = useApp();
  const isSmallScreen = useBreakPointDown("md");
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [showNavBttn, setShowNavBttn] = useState<boolean>(false);
  const [transparentHeader, setTransparentHeader] = useState<boolean>(
    !!transparentBeforeScroll
  );
  const headerLinks = cms.globalWebConfig?.data?.attributes?.header?.links;

  /**
   * do not run animation when screen not in small screen
   *
   * @param {boolean} val - whether the search input is focused
   * @returns
   */
  function handleSearchIsFocused(val: boolean) {
    if (!isSmallScreen) {
      //handle resposnive screen size increasing while drawer is open
      if (app.searchFocus && !val) {
        app.setSearchFocus(false);
      }
      return;
    }
    app.setSearchFocus(val);
  }

  /**
   * this is to smooth out the animation
   */
  useEffect(() => {
    if (!app.searchFocus) {
      const timeout = setTimeout(() => {
        setShowNavBttn(false);
      }, 500);
      return () => clearTimeout(timeout);
    }

    setShowNavBttn(true);
  }, [app.searchFocus]);

  /**
   * turn off side nav if search is engage
   */

  useEffect(() => {
    if (drawerOpen && app.searchFocus) {
      setDrawerOpen(false);
    }
  }, [app.searchFocus, drawerOpen]);

  /**
   * when transparent header turn background on when scroll > 0
   */
  useEffect(() => {
    if (
      typeof app?.searchFilters?.filterState?.regions !== "string" ||
      !app?.searchFilters?.filterState?.regions
    ) {
      setTransparentHeader(false);
      return;
    }

    function handleScroll() {
      if (window.scrollY > 0) {
        setTransparentHeader(false);
      } else {
        setTransparentHeader(true);
      }
    }

    setTransparentHeader(true);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [app?.searchFilters?.filterState?.regions]);

  return (
    <>
      <header
        className={cn(
          styles.headerSticky,
          styles.header,
          transparentHeader ? styles.headerNavTransparent : undefined
        )}
        data-testid="header"
      >
        <div className={cn(styles.headerNav, app.searchFocus ? "focused" : "")}>
          <Link href={cms.constructLink("/discover")} aria-label="Go to Search">
            <div className={styles.headerNavDesktopLogo}>
              <BlaceLogoSVG />
            </div>
            <div className={styles.headerNavMobileLogo}>
              <BlaceLogoSmallSVG />
            </div>
          </Link>
          <div
            className={cn(
              styles.headerSearchInput,
              showNavBttn ? "focused" : ""
            )}
          >
            <GlobalSearch
              setIsFocused={handleSearchIsFocused}
              isFocused={app.searchFocus}
            />
          </div>
          <div className={styles.contactMailWrapper}>
            <Link
              href={cms.constructLink("/inquiry/general")}
              className={styles.linkButton}
            >
              <div className={styles.contactMailContent}>
                <div className={styles.contactMailIconWrapper}>
                  <TextsmsTwoToneIcon color="inherit" />
                </div>
                <div className={styles.contactMailTextWrapper}>
                  <Typography
                    variant="caption"
                    component="p"
                    className={cn(
                      styles.contactQuestionText,
                      transparentHeader
                        ? styles.contactQuestionTextWhite
                        : undefined
                    )}
                  >
                    Have a question?
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    className={styles.contactSendMailText}
                  >
                    Contact a BLACE expert
                  </Typography>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {!showNavBttn && (
          <div
            className={styles.navBarDesktopOptsDrawerBtn}
            onClick={() => setDrawerOpen(!drawerOpen)}
            data-testid="navbar-drawer"
          >
            <div className={styles.navBarDesktopOptsDrawerBtnInner}>
              {drawerOpen ? (
                <RightArrowSVG width={9} />
              ) : (
                <BurgerSVG width={18} />
              )}
            </div>
          </div>
        )}
      </header>
      <DrawerFixedToHeader
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        className={styles.drawerScrollbar}
        PaperProps={{
          sx: {
            width: 345,
            top: DRAWER_FIX_TO_HEIGHT, // (DRAWER_FIX_TO_HEIGHT + (transparentHeader ? 79 : 0))
            maxWidth: "100vw",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50))",
            backdropFilter: "blur(50px)",
            "@media (max-width: 780px)": {
              width: "100%",
            },
          },
        }}
      >
        <NavigationDrawer headerLinks={headerLinks} />
      </DrawerFixedToHeader>
    </>
  );
}

export default Header;
