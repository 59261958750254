import { useState } from "react";

export function useReachedLimitInquiry() {
  const [isLimitReachedPopupVisible, setIsLimitReachedPopupVisible] =
    useState<boolean>(false);

  const handleDialogClose = () => {
    setIsLimitReachedPopupVisible(false);
  };

  const setInquiryLimitReached = () => {
    setIsLimitReachedPopupVisible(true);
  };
  return { isLimitReachedPopupVisible, handleDialogClose, setInquiryLimitReached };
}
