import { useRef } from "react";
import { DraggableData, DraggableEvent } from "react-draggable";

interface UseDragToClose {
  onDragStop?: () => void;
}

export function useDragToClose({ onDragStop }: UseDragToClose) {
  const dialogYPosition = useRef(0);
  const dragId = "draggable-bar";

  function handleDragStop() {
    if (dialogYPosition.current >= 0) {
      onDragStop && onDragStop();
    }
  }

  function handleDrag(e: DraggableEvent, data: DraggableData) {
    dialogYPosition.current = data.deltaY;
  }

  return { handleDragStop, handleDrag, dragId };
}
