import { ImageFromCDN } from "@/src/util";
import { ImgBgResponsive, ImgResponsive } from "@src/component/base";

interface Props {
  url: string;
  id: string;
  imageClassName: string;
  quality?: number;
  width?: number;
  imageOnClick?: () => void;
  fixedImageSize?: boolean;
  objectFit?: "contain" | "cover";
}

export function CarouselImageItem({
  url,
  id,
  imageClassName,
  quality,
  width,
  fixedImageSize,
  imageOnClick,
  objectFit = "cover",
}: Props) {

  if (fixedImageSize) {
    return (
      <ImgBgResponsive
        imageUrl={ImageFromCDN.imageSizeAndQuality(url, quality, width)}
        style={{
          height: "100%",
        }}
      />
    );
  }
  return (
    <ImgResponsive
      className={imageClassName}
      src={url}
      alt={"Image"}
      id={id}
      objectFit={objectFit}
      onClick={imageOnClick}
      quality={quality}
      width={width}
    />
  );
}
