export function CapacityDiningSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M2.0918 9.12252L16.3534 1.83838L23.5629 16.2478L9.30122 23.532L2.0918 9.12252Z"
        fill="black"
      />
      <path
        d="M23.3051 5.2535C23.3051 7.52631 19.9309 7.52631 19.9309 5.2535C19.9309 2.98581 23.3051 2.98581 23.3051 5.2535Z"
        fill="#1D39C4"
      />
      <path
        d="M26 10.6284C26 12.9012 22.6309 12.9012 22.6309 10.6284C22.6309 8.35563 26 8.35563 26 10.6284Z"
        fill="#1D39C4"
      />
      <path
        d="M3.36918 15.4243C3.36918 17.6971 0 17.6971 0 15.4243C0 13.1515 3.36918 13.1515 3.36918 15.4243Z"
        fill="#1D39C4"
      />
      <path
        d="M6.06955 20.8096C6.06955 23.0824 2.69531 23.0824 2.69531 20.8096C2.69531 18.5368 6.06955 18.5368 6.06955 20.8096Z"
        fill="#1D39C4"
      />
      <path
        d="M16.9773 24.2954C16.9773 26.5682 13.603 26.5682 13.603 24.2954C13.603 22.0226 16.9773 22.0226 16.9773 24.2954Z"
        fill="#1D39C4"
      />
      <path
        d="M22.3066 21.567C22.3066 23.8398 18.9324 23.8398 18.9324 21.567C18.9324 19.2993 22.3066 19.2993 22.3066 21.567Z"
        fill="#1D39C4"
      />
      <path
        d="M7.04245 4.41317C7.04245 6.68597 3.66821 6.68597 3.66821 4.41317C3.66821 2.14548 7.04245 2.14548 7.04245 4.41317Z"
        fill="#1D39C4"
      />
      <path
        d="M12.3718 1.70077C12.3718 3.97357 8.99756 3.97357 8.99756 1.70077C8.99756 -0.566923 12.3718 -0.566923 12.3718 1.70077Z"
        fill="#1D39C4"
      />
    </svg>
  );
}
