import { useState } from "react";
import { Divider, Typography } from "@mui/material";
//import Image from "next/image";
import { StringHelper } from "@/src/util/helper";
import styles from "@/src/component/partial/Header/Header.module.scss";
import { CurrentUserProps } from "./NavigationDrawer";

export function Profile({ currentUser }: CurrentUserProps) {
  const [photoError, setPhotoError] = useState<boolean>(false);
  if (!currentUser) {
    return null;
  }

  const { name, created_at, photo_url } = currentUser;

  const gerUserCreatedYear = () => {
    const dateYear = new Date(created_at);
    return dateYear.getFullYear();
  };

  return (
    <div className={styles.profileSectionWrapper} data-testid="profile">
      <div className={styles.profileContent}>
        {photo_url && !photoError ? (
          <img
            src={photo_url}
            alt="avatar"
            width="60"
            height="60"
            className={styles.profileAvatar}
            data-testid="user-photo"
            onError={() => {
              setPhotoError(true);
            }}
          />
        ) : (
          <div className={styles.userInitial}>
            <p className={styles.userInitialText}>{StringHelper.getInitialLetters(name)}</p>
          </div>
        )}
        <div>
          <Typography component="h3" className={styles.userName}>
            {name}
          </Typography>
          <Typography
            className={styles.memberYear}
          >{`BLACE member since ${gerUserCreatedYear()}`}</Typography>
        </div>
      </div>
      <Divider className={styles.drawerDivider} />
    </div>
  );
}
