import { DrawerProps, Drawer as MuiDrawer } from "@mui/material";

export const DRAWER_FIX_TO_HEIGHT = 80;

function DrawerFixedToHeader({ children, ...props }: DrawerProps) {
  return (
    <MuiDrawer
      slotProps={{
        backdrop: {
          invisible: true,
          sx: {
            top: DRAWER_FIX_TO_HEIGHT,
          },
        },
      }}
      PaperProps={{
        sx: {
          top: DRAWER_FIX_TO_HEIGHT,
          width: "100vw",
          bottom: 0,
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
      sx={{
        top: DRAWER_FIX_TO_HEIGHT,
      }}
      //allow for props to override fix props above
      {...props}
    >
      {children}
    </MuiDrawer>
  );
}

export default DrawerFixedToHeader;
