import { useEffect, useState } from "react";
import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

export function useBreakPointDown(breakpoint: Breakpoint): boolean {
  const [isDown, setIsDown] = useState<boolean>(false);
  const theme = useTheme();
  const isChange = useMediaQuery(theme.breakpoints.down(breakpoint));
  useEffect(() => {
    setIsDown(isChange);
  }, [isChange]);
  return isDown;
}
