import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import cn from "classnames";
import styles from "./ExitButton.module.scss";

interface ExitButtonProps {
  onExit: () => void;
  className?: string;
}

function ExitButton({ onExit, className }: ExitButtonProps) {
  return (
    <div className={styles.exitBttnContainer}>
      <div className={cn(styles.exitBttnInner, className)}>
        <IconButton color="inherit" onClick={onExit} data-testid="exit-button">
          <CloseIcon color="inherit" />
        </IconButton>
      </div>
    </div>
  );
}

export default ExitButton;
