import { SearchType } from "@/src/type/blaceV2";

interface InquiryFormParams {
  selectedVenue?: SearchType.SearchItem;
  selectedVendor?: SearchType.SearchItem;
}

export function useInquiryFormParams({
  selectedVenue,
  selectedVendor,
}: InquiryFormParams) {
  if (selectedVenue || selectedVendor) {
    const altCatalogId = selectedVendor
      ? `vendor_v1_${selectedVendor?.data?.id}`
      : `venue_v1_${selectedVenue?.data?.id}`;
    const inquiryFormParams = { altCatalogId };
    return inquiryFormParams;
  }
  return null;
}
