import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { ActivityLogic } from "@src/model";
import { Log, StorageHelper } from "@src/util";
import {
  FeatureHubContext,
  FeatureHubContextType,
  FeatureState,
} from "./FeatureHubContext";
import * as FeatureHubTypes from "./FeatureHubTypes";

const STORAGE_PREFIX = "FEATURE_FLAG_";

interface WithFeatureHubProps {
  url?: string;
  apiKey?: string;
  applicationId?: string;
}

function WithFeatureHub({
  url,
  apiKey,
  applicationId,
  children,
}: PropsWithChildren<WithFeatureHubProps>) {
  const [features, setFeatures] = useState<FeatureState | undefined>(undefined);

  useEffect(() => {
    if (!url || !apiKey || !applicationId) {
      return;
    }

    function getApplication(response: FeatureHubTypes.FeatureHubResponse) {
      if ((response ?? []).length === 0) {
        return;
      }

      const filtered = response.filter(x => x.id === applicationId);
      if ((filtered ?? []).length === 1) {
        const all = filtered[0];
        const map: Record<string, FeatureHubTypes.FeatureHubAppFeature> = {};
        for (const opt of all.features ?? []) {
          map[opt.key] = opt;
        }

        Log.logToConsoleDebug(
          "WithFeatureHub.tsx",
          "Loaded Feature Hub",
          [all, map],
          "#000080",
          "#fff"
        );

        setFeatures({ all, map });
      }
      return;
    }

    try {
      const featureHubUrl = `${url}/features?apiKey=${apiKey}`;
      fetch(featureHubUrl, { method: "GET" })
        .then(response => {
          const { status } = response;
          switch (status) {
            case 200:
              response.json().then(getApplication);
              return;
            default:
              Log.logToConsoleDebug(
                "WithFeatureHub.tsx",
                "Error Loading From Featurehub [1]",
                [response],
                "#8b0000",
                "#ffffff"
              );
              return;
          }
        })
        .catch(error => {
          Log.logToConsoleDebug(
            "WithFeatureHub.tsx",
            "Error Loading From Featurehub [2]",
            [error],
            "#8b0000",
            "#ffffff"
          );
        });
    } catch (error) {
      Log.logToConsoleDebug(
        "WithFeatureHub.tsx",
        "Error Loading From Featurehub [3]",
        [error],
        "#8b0000",
        "#ffffff"
      );
    }
  }, [url, apiKey, applicationId]);

  function trackFeatureFlag(key: string, value: any, trackingId?: string) {
    ActivityLogic.toActivityService({
      action: "featureFlag",
      actionId: value,
      actionIdType: key,
      actionMessage: trackingId,
      locationInApp: "WithFeatureHub.tsx",
    });
  }

  async function useFeatureFlag<T>(
    key: string,
    fallback: T,
    timeToResetInSeconds: number = 0,
    trackingId?: string
  ): Promise<T> {
    const STORAGE_KEY = `${STORAGE_PREFIX}${key}`;
    const exists: T | undefined = await StorageHelper().getFromCache(
      STORAGE_KEY
    );
    //if available from storage then use storage
    if (exists) {
      return exists;
    }

    if (features?.map?.[key]) {
      const value: T = features?.map?.[key]?.value ?? fallback;
      //only cache when time > 0 and retrieved value from featurehub
      if (timeToResetInSeconds > 0 && features?.map?.[key]) {
        StorageHelper().setToCache(
          STORAGE_KEY,
          value,
          timeToResetInSeconds * 1000
        );
      }

      //when called log the feature flag usage
      trackFeatureFlag(key, value, trackingId);
      return value;
    }

    return fallback;
  }

  return (
    <FeatureHubContext.Provider
      value={{
        features,
        useFeatureFlag,
        trackFeatureFlag,
      }}
    >
      <>{children}</>
    </FeatureHubContext.Provider>
  );
}

function useFeatureHub(): FeatureHubContextType {
  return useContext(FeatureHubContext);
}

export { FeatureHubContext, useFeatureHub, WithFeatureHub };
