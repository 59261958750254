import { APP_TENANT, BLACE_V2_API } from "@/src/const";
import { BlaceV2Type, VendorType, VenueType } from "@/src/type";
import { SearchType } from "@/src/type/blaceV2";
import { HTTP, HTTPResponse } from "./HTTP";

export async function getSlug(
  slug: string
): Promise<
  HTTPResponse<BlaceV2Type.BlaceV2Response<BlaceV2Type.SearchType.Search>>
> {
  const action = new HTTP<
    BlaceV2Type.BlaceV2Response<BlaceV2Type.SearchType.Search>
  >(`${BLACE_V2_API}/search/slug/${slug}`, HTTP.methods.GET);
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getAllSlugs(): Promise<
  HTTPResponse<BlaceV2Type.BlaceV2Response<string[]>>
> {
  const action = new HTTP<BlaceV2Type.BlaceV2Response<string[]>>(
    `${BLACE_V2_API}/search/slugs/all`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getSlugWithV1PayloadForVenue(
  slug: string
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<SearchType.SearchWithV1Payload<VenueType.VenueItem>>>> {
  const action = new HTTP<BlaceV2Type.BlaceV2Response<SearchType.SearchWithV1Payload<VenueType.VenueItem>>>(
    `${BLACE_V2_API}/search/slug/${slug}/type/venue`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getSlugWithV1PayloadForVendor(
  slug: string
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<SearchType.SearchWithV1Payload<VendorType.Vendor>>>> {
  const action = new HTTP<BlaceV2Type.BlaceV2Response<SearchType.SearchWithV1Payload<VendorType.Vendor>>>(
    `${BLACE_V2_API}/search/slug/${slug}/type/vendor`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function postSearchQuery(
  request: BlaceV2Type.AzureSearchQueryType.Request
): Promise<HTTPResponse<BlaceV2Type.SearchType.SearchResponse>> {
  const action = new HTTP<BlaceV2Type.SearchType.SearchResponse>(
    `${BLACE_V2_API}/search/query`,
    HTTP.methods.POST,
    request
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function postSearchAutoComplete(
  request: BlaceV2Type.AzureSearchAutoCompleteType.Request
): Promise<
  HTTPResponse<BlaceV2Type.AzureSearchAutoCompleteType.SearchResponse>
> {
  const action =
    new HTTP<BlaceV2Type.AzureSearchAutoCompleteType.SearchResponse>(
      `${BLACE_V2_API}/search/autocomplete`,
      HTTP.methods.POST,
      request
    );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function postSearchSuggestion(
  request: BlaceV2Type.AzureSearchSuggestionType.Request
): Promise<HTTPResponse<BlaceV2Type.AzureSearchSuggestionType.SearchResponse>> {
  const action = new HTTP<BlaceV2Type.AzureSearchSuggestionType.SearchResponse>(
    `${BLACE_V2_API}/search/suggestion`,
    HTTP.methods.POST,
    request
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}
