import React from "react";
import { Slider, Typography } from "@mui/material";
import styles from "./CapacitySlider.module.scss";

interface CapacitySliderProps {
  capacityValue: number[];
  sliderMin: number;
  sliderMax: number;
  handleCapacitySliderChangeCommit: (newValue: number[]) => void;
  handleCapacitySliderChange: (newValue: number[]) => void;
}
function CapacitySlider({
  capacityValue,
  sliderMin,
  sliderMax,
  handleCapacitySliderChangeCommit,
  handleCapacitySliderChange,
}: CapacitySliderProps) {
  const maxCapacityDefaultLabel = "+1,000";

  const capacityToLabel = getCapacityToLabel();
  const capacityFromLabel = getCapacityFromLabel();

  function handleSliderChange(event: Event, newCapacity: number | number[]) {
    handleCapacitySliderChange(newCapacity as number[]);
  }

  function handleSliderCommitChange(
    event: React.SyntheticEvent | Event,
    newCapacity: number | number[]
  ) {
    handleCapacitySliderChangeCommit(newCapacity as number[]);
  }

  function getCapacityToLabel() {
    const capacityTo = capacityValue[1];
    return capacityTo < sliderMax ? `${capacityTo}` : maxCapacityDefaultLabel;
  }

  function getCapacityFromLabel() {
    return capacityValue[0];
  }

  return (
    <>
      <div className={styles.sliderWrapper}>
        <Typography className={styles.sliderText}>
          from <span className={styles.sliderValue}>{capacityFromLabel}</span>
        </Typography>
        <Typography className={styles.sliderText}>
          {capacityValue[1] < sliderMax && "to "}
          <span className={styles.sliderValue}>{capacityToLabel}</span>
        </Typography>
      </div>
      <Slider
        getAriaLabel={() => "Capacity range"}
        step={50}
        value={capacityValue}
        onChange={handleSliderChange}
        onChangeCommitted={handleSliderCommitChange}
        color="secondary"
        min={sliderMin}
        max={sliderMax}
        className={styles.slider}
      />
    </>
  );
}

export default CapacitySlider;
