import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

type BreakPointRange = [Breakpoint|number, Breakpoint | number];

export function useBreakPointBetween(range: BreakPointRange): boolean {
  const theme = useTheme();
  const [down, up] = range;
  const isDown = useMediaQuery(typeof down === "number" ? `(max-width: ${down}px)` : theme.breakpoints.down(down));
  const isUp = useMediaQuery(typeof up === "number" ? `(min-width: ${up}px)` : theme.breakpoints.up(up));

  return !isDown && !isUp;
}
