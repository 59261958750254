import { KEYS } from "@/src/const";
import { BlaceV2Type } from "@/src/type";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import { HTTP, HTTPResponse } from "./HTTP";

export async function postClientLead(
  data: BlaceV2Type.ClientLead
): Promise<HTTPResponse<BlaceV2Type.ClientLeadResponse>> {
  const BLACE_V2_API: string = SharedConfigManager.getValue(KEYS.BLACE_V2_API);
  const APP_TENANT: string = SharedConfigManager.getValue(KEYS.APP_TENANT);

  const action = new HTTP<BlaceV2Type.ClientLeadResponse>(
    `${BLACE_V2_API}/client/lead`,
    HTTP.methods.POST,
    data
  );
  action.setAllEnvId(APP_TENANT);
  action.setAllowGuest(true);
  return await action.execute();
}
