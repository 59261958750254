import { AuthLogic } from "@/src/model";
import { AuthType, BlaceV1Type } from "@/src/type";
import { HTTP } from "./";

export async function postSignIn(
  data: AuthType.LoginRequest
): Promise<BlaceV1Type.V1ServiceResponse<AuthType.LoginResponse>> {
  return await HTTP.v1Client<AuthType.LoginResponse>("/login", data);
}

export async function getMagicLink(
  hash: string
): Promise<BlaceV1Type.V1ServiceResponse<AuthType.LoginResponse>> {
  return await HTTP.v1Client<AuthType.LoginResponse>(
    `/magiclink/${hash}`,
    undefined,
    "GET"
  );
}

export async function postSignUp(
  data: AuthType.SignupRequest
): Promise<BlaceV1Type.V1ServiceResponse<AuthType.SignupResponse>> {
  return await HTTP.v1Client<AuthType.SignupResponse>("/register", data);
}

export async function postForgotPassword(
  email: string
  //TODO: get the response type
): Promise<BlaceV1Type.V1ServiceResponse<AuthType.ForgotPasswordResponse>> {
  return await HTTP.v1Client<AuthType.ForgotPasswordResponse>("/passwordReset", {
    email,
  });
}

export async function postResetpassword(
  password: string
  //TODO: get the response type
): Promise<BlaceV1Type.V1ServiceResponse<AuthType.ResetPasswordResponse>> {
  return await HTTP.v1Client<AuthType.ResetPasswordResponse>(
    "/user/profile",
    { password },
    "POST",
    {
      Authorization: `Bearer ${AuthLogic.getIdentityToken()}`,
    }
  );
}

export async function LogOut(): Promise<
  AuthType.AuthServiceResponse<AuthType.LogOutResponse>
> {
  return await HTTP.v1Client<AuthType.LogOutResponse>("/logout", null, "GET", {
    Authorization: `Bearer ${AuthLogic.getIdentityToken()}`,
  });
}
