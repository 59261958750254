/**
 * This should be structure with the params for the feature that will use theme
 */
export const URL_PARAMS = {
  TOAST: {
    TOAST_LEVEL: "toastLevel",
    TOAST_MESSAGE: "toastMessage",
    VALUES: {
      TOAST_LEVEL: {
        ERROR: "ERROR",
        WARN: "WARN",
        SUCCESS: "SUCCESS",
      },
      TOAST_MESSAGE: {
        INQUIRY_COMPLETE: "INQUIRY_COMPLETE",
      },
    },
  },
  INQUIRY_COMPLETE: {
    STATUS: "status",
  },
  ACTIVITY: {
    EMPLOYEE: "employee",
  },
};
