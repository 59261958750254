import { useEffect, useState } from "react";
import cn from "classnames";
import Image from "next/image";
import { ImageFromCDN } from "@/src/util";
import styles from "./ImgFromCDN.module.scss";

export interface ImgFromCDNProps {
  priority?: boolean | undefined;
  objectFit?: "cover" | "contain";
  className?: string;
  src: string;
  alt: string;
  id?: string;
  quality?: number;
  width?: number;
  height?: number;
  onClick?: () => void;
}

/**
 * A base component for handling images with NextJS
 *
 * @component ImgFromCDN
 * @interface ImgFromCDNProps
 * @property {boolean | undefined} priority - when true this will load the image immediately, when undefined defaults to true, when false will lazy load
 * @property {"cover" | "contain"} objectFit - when the object fit should cover or contain, the default is cover
 * @property {ImageLoader} loader - [OPTIONAL] an image CDN loader;
 * @property {string} className - [OPTIONAL] a CSS class for the image;
 * @property {string} src - the image link;
 * @property {string} alt - the alternate text that represents what the image is;
 * @property {string} id - an id for the element rendering the image
 * @property {number} quality - a number representing the % of image desired 100% = best quality
 * @property {numbebr} width - the width of the image in pixels
 * @property {numbebr} height - the height of the image in pixels
 * @property {()=>void} onClick - an function that will be executed on the click of the image
 * @returns
 */
export function ImgFromCDN({ ...props }: ImgFromCDNProps) {
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    setHasError(false);
  }, [props.src]);

  if (hasError) {
    return (
      <div
        style={{ height: props.height, width: props.width }}
        className={cn(styles.imgFromCDNError, props.className)}
      />
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      {...props}
      loader={ImageFromCDN.loaderForNextJS}
      onError={() => setHasError(true)}
    />
  );
}
