import { useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { BlaceLogoSmallSVG } from "@/src/asset";
import { BaseButton } from "@/src/component/base";
import { ClientLeadCaptureTypeFormik, useClientLeadCapture } from "@/src/hook";
import { AuthLogic } from "@/src/model";
import { FormHelper } from "@/src/util";
import styles from "./ContactCapture.module.scss";

interface ContactCaptureProps {
  handleClose: (complete?: boolean) => void;
}

function ContactCapture({ handleClose }: ContactCaptureProps) {
  const clientLeadCapture = useClientLeadCapture({
    occurredAt: "contactCapture",
  });
  const [complete, setComplete] = useState<boolean>(false);

  useEffect(() => {
    if (clientLeadCapture.saveComplete) {
      setComplete(true);

      const TIMEOUT = setTimeout(() => {
        handleClose(true);
      }, 1500);
      return () => clearTimeout(TIMEOUT);
    }
  }, [clientLeadCapture.saveComplete]);

  useEffect(() => {
    if (AuthLogic.getIdentityToken()) {
      handleClose();
    }
  }, []);

  return (
    <div className={styles.contactCaptureContainer}>
      <div className={styles.contactCaptureBlaceLogo}>
        <BlaceLogoSmallSVG color={"#000"} size={48} />
      </div>
      <div className={styles.contactCaptureTitle}>
        <Typography>
          {complete
            ? "You're in! BLACE will send you trending event & production news."
            : "Enter your email to discover your next stunning venue"}
        </Typography>
      </div>
      {!complete && (
        <form onSubmit={clientLeadCapture.formik.handleSubmit}>
          <div className={styles.contactCaptureEmail}>
            <TextField
              label="Email"
              fullWidth
              onChange={e => {
                clientLeadCapture.formik.setFieldValue(
                  "email",
                  e?.target?.value ?? ""
                );
              }}
              value={clientLeadCapture.formik.values.email}
              helperText={FormHelper.formikErrorMessage<ClientLeadCaptureTypeFormik>(
                clientLeadCapture.formik,
                "email"
              )}
              error={FormHelper.formikCheckError<ClientLeadCaptureTypeFormik>(
                clientLeadCapture.formik,
                "email"
              )}
            />
            <BaseButton
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              loading={clientLeadCapture.isLoading}
            >
              CONTINUE WITH EMAIL
            </BaseButton>
          </div>
        </form>
      )}
      {/*
      <Divider>
        <Typography variant="subtitle2">
          OR
        </Typography>
      </Divider>
      <BaseButton
        type="button"
        variant="outlined"
        size="large"
        color="primary"
      >
        <div className={styles.contactCaptureBttnInner}>
          <GoogleFullColorLogoSVG />
            CONTINUE WITH GOOGLE
        </div>
      </BaseButton>
      */}
    </div>
  );
}

export default ContactCapture;
