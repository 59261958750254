import { Divider, List } from "@mui/material";
import cn from "classnames";
import {
  BlaceLogoSVG,
  FacebookSVG,
  InstagramSVG,
  LinkedInSVG,
  PintrestSVG,
  TikTokSVG,
} from "@/src/asset";
import { NoSSR } from "@/src/component/partial";
import { useCMS } from "@/src/component/provider";
import { useBreakPointDown, useBreakPointUp } from "@/src/hook";
import styles from "./Footer.module.scss";
import { FooterBottomExtra } from "./component/FooterBootomExtra";
import { FooterContactInfo } from "./component/FooterContactInfo";
import { FooterLandingSection } from "./component/FooterLandingSection";
import { FooterLinkListItem } from "./component/FooterLinkListIem";
import { FooterSignup } from "./component/FooterSignup";
import { FooterSocialButton } from "./component/FooterSocialButton";

interface FooterProps {
  pageHasMobileFooter?: boolean;
  isWidget?: boolean;
}

enum SocialMedia {
  Pinterest = "pinterest",
  Instagram = "instagram",
  Facebook = "facebook",
  Linkedin = "linkedin",
  Tiktok = "tiktok",
}

function Footer({ pageHasMobileFooter, isWidget }: FooterProps) {
  const cms = useCMS();
  const isMobile = useBreakPointDown("md");
  const isDesktop = useBreakPointUp("md");
  const logoWidth = isMobile ? 323 : 253;

  if (!cms?.globalWebConfig?.data?.attributes?.footer) {
    return <></>;
  }

  function SocialIconSwitch({ socialNetwork }: { socialNetwork: string }) {
    switch (socialNetwork) {
      case SocialMedia.Pinterest:
        return <PintrestSVG />;
      case SocialMedia.Instagram:
        return <InstagramSVG />;
      case SocialMedia.Facebook:
        return <FacebookSVG />;
      case SocialMedia.Linkedin:
        return <LinkedInSVG />;
      case SocialMedia.Tiktok:
        return <TikTokSVG />;
    }
    return <></>;
  }

  const { footer, name, tracking } = cms.globalWebConfig.data.attributes;
  const linkedinAccountNumber = tracking?.linkedinAccountNumber;
  const { landingPages } = footer;
  const landingList = landingPages?.data;
  const hasLandingDesktop = isDesktop && !!landingList?.length && !isWidget;
  const hasLandingMobile = isMobile && !!landingList?.length && !isWidget;

  return (
    <footer className={styles.footer}>
      <section className={styles.footerTop}>
        {hasLandingDesktop && (
          <div className={styles.landingList}>
            <FooterLandingSection landingList={landingList} />
            <Divider className={styles.footerDivider} />
          </div>
        )}
        <div className={styles.footerLogo}>
          <BlaceLogoSVG width={logoWidth} />
          {isDesktop && <FooterContactInfo footer={footer} />}
        </div>
        <div className={styles.linksWrapper}>
          {hasLandingMobile && (
            <>
              <FooterLandingSection landingList={landingList} />
              <Divider className={styles.footerDivider} />
            </>
          )}
          <div className={styles.footerLinks}>
            <List disablePadding>
              {(footer.links ?? []).map((opt, i) => (
                <FooterLinkListItem key={i} href={opt.href} title={opt.label} />
              ))}
            </List>
          </div>
        </div>
        {isMobile && (
          <div className={styles.footerSocialLinks} data-testid="social-links">
            {(footer.socialNetworks ?? []).map((socialNetwork, i) => (
              <FooterSocialButton
                key={i}
                href={socialNetwork.url}
                socialNetwork={socialNetwork.socialNetwork}
                SocialLogo={
                  <SocialIconSwitch
                    socialNetwork={socialNetwork.socialNetwork}
                  />
                }
              />
            ))}
          </div>
        )}
        {isMobile && <FooterContactInfo footer={footer} />}
        {footer.signUpHeader && (
          <div className={styles.signupWrapper}>
            <FooterSignup footer={footer} />
            {isDesktop && (
              <div
                className={styles.footerSocialLinks}
                data-testid="social-links"
              >
                {(footer.socialNetworks ?? []).map((socialNetwork, i) => (
                  <FooterSocialButton
                    key={i}
                    href={socialNetwork.url}
                    socialNetwork={socialNetwork.socialNetwork}
                    SocialLogo={
                      <SocialIconSwitch
                        socialNetwork={socialNetwork.socialNetwork}
                      />
                    }
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </section>
      <section
        className={cn(
          styles.footerBottom,
          pageHasMobileFooter && styles.footerPageHasMobileFooter
        )}
        data-testid="footer-bottom"
      >
        <Divider className={styles.footerDivider} />
        <FooterBottomExtra name={name} footer={footer} isWidget={isWidget} />
        {linkedinAccountNumber && !isWidget && (
          <NoSSR>
            <script
              async
              defer
              dangerouslySetInnerHTML={{
                __html: `
                    _linkedin_partner_id = "${linkedinAccountNumber}";
                    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                    </script><script type="text/javascript">
                    (function(l) {
                    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                    window.lintrk.q=[]}
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript";b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);})(window.lintrk);
                  `,
              }}
            ></script>
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: "none" }}
                alt=""
                src={`https://px.ads.linkedin.com/collect/?pid=${linkedinAccountNumber}&fmt=gif"}`}
              />
            </noscript>
          </NoSSR>
        )}
      </section>
    </footer>
  );
}

export default Footer;
