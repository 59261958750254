import { datadogLogs } from "@datadog/browser-logs";

export class DataDogLogger {
  static _initialized = false;

  static initialize(datadogToken: string, env: string, serviceName: string) {
    if (datadogToken && datadogToken !== "DATADOG_TOKEN") {
      datadogLogs.init({
        clientToken: datadogToken,
        site: "us5.datadoghq.com",
        datacenter: "us",
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: serviceName,
        env: env,
      });
      this._initialized = true;
    }
  }

  static createLogger(
    datadogToken: string,
    env: string,
    serviceName: string
  ): (message: string, payload: any) => void {
    if (!this._initialized) {
      this.initialize(datadogToken, env, serviceName);
    }
    const logger: any = datadogLogs.createLogger(serviceName);
    return logger;
  }
}
