import { format } from "date-fns";
import { SYSTEM_DATE_FORMAT } from "@/src/const";
import { InquiryLog, InquiryMessagesList } from "@/src/type/blaceV2";

/**
 * functionality to group inquiry logs by date
 *
 * @returns {InquiryMessagesList[]}
 */
export function groupMessagesByDay(
  inquiryLogs: InquiryLog[]
): InquiryMessagesList[] {
  const sortedMessagesList = inquiryLogs.sort(
    (a, b) => a.createDate - b.createDate
  );

  const groupedMessagesByDay: { [key: string]: InquiryLog[] } = {};
  sortedMessagesList.forEach(result => {
    const timestamp = result.createDate;
    const day = format(new Date(timestamp), SYSTEM_DATE_FORMAT);
    if (!groupedMessagesByDay[day]) {
      groupedMessagesByDay[day] = [];
    }
    groupedMessagesByDay[day].push(result);
  });

  return Object.entries(groupedMessagesByDay).map(([day, objects]) => ({
    [day]: objects,
  }));
}

export function hasEventDetailsChanges(message: InquiryLog) {
  const { shortProperties } = message;
  const hasChange =
    shortProperties.newStartDate !== shortProperties.oldStartDate ||
    shortProperties.newEndDate !== shortProperties.oldEndDate ||
    shortProperties.newFlexibleDates !== shortProperties.oldFlexibleDates ||
    shortProperties.newGuests !== shortProperties.oldGuests;
  return hasChange;
}
