import { APP_API, APP_TENANT } from "@/src/const";
import { VendorType } from "@/src/type";
import { HTTP, HTTPResponse } from "@/src/util";

/**
 * Get a Vendors's data by the slug.  The slug is a unique string value for the url to find a vendor.
 * @param slug - the unique slug that is passed to the url of the page for a vendor
 * @returns {Promise<HTTPResponse<VendorType.Vendor>>} - vendor data
 */
export async function getVendorBySlug(
  slug: string
): Promise<HTTPResponse<VendorType.Vendor>> {
  const action = new HTTP<VendorType.Vendor>(
    `${APP_API}/vendor/slug/${slug}`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}
