import { Dispatch, SetStateAction } from "react";
import { Divider, Link, Typography } from "@mui/material";
import Image from "next/image";
import LogOutIcon from "@/public/icons/logOutIcon.svg";
import ProfileIcon from "@/public/icons/profileIcon.svg";
import { GoogleFullColorLogoSVG, LinkedInColorLogoSVG } from "@/src/asset";
import { BaseButton, BaseButtonStyles } from "@/src/component/base";
import { useCMS } from "@/src/component/provider";
import { APP_API } from "@/src/const";
import { useNextNavigation } from "@/src/hook";
import { AuthLogic, CMSLogic } from "@/src/model";
import { AuthType } from "@/src/type";
import styles from "@/src/component/partial/Header/Header.module.scss";
import { CurrentUserProps } from "./NavigationDrawer";

interface AccountProps extends CurrentUserProps {
  setCurrentUserData: Dispatch<
    SetStateAction<AuthType.LoginResponseProfile | undefined>
  >;
}

export function Account({ currentUser, setCurrentUserData }: AccountProps) {
  const cms = useCMS();
  const nextNavigation = useNextNavigation();

  const onClickLogOutHandler = () => {
    AuthLogic.logOutCurrentUser();
    setCurrentUserData(undefined);
    if (nextNavigation.pathname.includes("/dashboard")) {
      nextNavigation.router.push(CMSLogic.constructLink("/auth"));
    }
  };

  /**TODO: need to address this, this is nasty */
  const accountProfilePath = (): string => {
    return currentUser?.role === "vendor" || currentUser?.role === "landlord"
      ? "/a/dashboard/profile"
      : "/a/account/profile";
  };

  return (
    <div className={styles.accountWrapper}>
      <Typography component="h3" className={styles.title}>
        Account
      </Typography>
      {currentUser ? (
        <>
          <Link
            href={accountProfilePath()}
            className={styles.accountLink}
            aria-label="My Account"
            id={"Account.myAccount"}
          >
            <div className={styles.accountLinkContent}>
              <Image
                src={ProfileIcon}
                alt="profile icon"
                className={styles.arrowOffsetRight}
              />
              Profile
            </div>
          </Link>
          <a
            onClick={onClickLogOutHandler}
            className={styles.accountLink}
            aria-label="Log Out"
            id={"Account.logOut"}
          >
            <div className={styles.accountLinkContent}>
              <Image
                src={LogOutIcon}
                alt="log out icon"
                className={styles.arrowOffsetRight}
              />
              Log out
            </div>
          </a>
        </>
      ) : (
        <div className={styles.accountInner}>
          <Link
            href={cms.constructLink("/auth")}
            className={styles.accountLink}
          >
            <BaseButton
              variant="contained"
              fullWidth
              sx={BaseButtonStyles.whiteWithBorder}
              className={styles.accountBttn}
              aria-label="Log In"
              id={"Account.logIn"}
            >
              Log in
            </BaseButton>
          </Link>
          <Link
            href={cms.constructLink("/auth/sign-up")}
            className={styles.accountLink}
          >
            <BaseButton
              variant="contained"
              fullWidth
              sx={BaseButtonStyles.whiteWithBorder}
              className={styles.accountBttn}
              aria-label="Sign Up"
              id={"Account.signUp"}
            >
              Sign up
            </BaseButton>
          </Link>
          <a href={`${APP_API}/auth/google`} className={styles.accountLink}>
            <BaseButton
              variant="contained"
              fullWidth
              sx={BaseButtonStyles.whiteWithBorder}
              className={styles.accountBttn}
              aria-label="Continue with Google"
              id={"Account.continueWithGoogle"}
            >
              <GoogleFullColorLogoSVG size={20} />
              Continue with Google
            </BaseButton>
          </a>
          <a href={`${APP_API}/auth/linkedin`} className={styles.accountLink}>
            <BaseButton
              variant="contained"
              fullWidth
              sx={BaseButtonStyles.whiteWithBorder}
              className={styles.accountBttn}
              aria-label="Continue with Google"
              id={"Account.continueWithLinkedIn"}
            >
              <LinkedInColorLogoSVG size={20} />
              Continue with LinkedIn
            </BaseButton>
          </a>
        </div>
      )}
      <Divider className={styles.drawerDivider} />
    </div>
  );
}
