import { useState } from "react";
import { FormHelperText } from "@mui/material";
import {
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
} from "@mui/x-date-pickers";
import { isMobile } from "react-device-detect";

interface DatePickerProps<T> extends MUIDatePickerProps<T> {
  error?: boolean;
  helperText?: string;
  datePickerProps?: any;
}

function DatePicker<T>({ error, helperText, ...props }: DatePickerProps<T>) {
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const errorStyles = !error
    ? undefined
    : {
        fieldset: {
          border: "1px solid red !important",
        },
        input: {
          "&::placeholder": {
            color: "red",
            opacity: 0.6,
          },
        },
      };

  if (isMobile) {
    return (
      <div data-testid="datePicker-mobile">
        <MUIDatePicker {...props} sx={errorStyles}/>
        {error && (
          <FormHelperText error={true} sx={{ paddingLeft: "12px" }}>
            {helperText}
          </FormHelperText>
        )}
      </div>
    );
  }

  return (
    <div
      onClick={datePickerOpen ? undefined : () => setDatePickerOpen(true)}
      data-testid="datePicker"
    >
      <MUIDatePicker
        open={datePickerOpen}
        onAccept={() => setDatePickerOpen(false)}
        onClose={() => setDatePickerOpen(false)}
        {...props}
        sx={errorStyles}
      />
      {error && (
        <FormHelperText error={true} sx={{ paddingLeft: "12px" }}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
}

export default DatePicker;
