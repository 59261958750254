export function ArrowSVG() {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25612 0.078125L7.07761 1.25664L8.98836 3.16738H1.0002C0.539962 3.16738 0.166866 3.54048 0.166866 4.00071C0.166866 4.46095 0.539962 4.83405 1.0002 4.83405H8.98836L7.07761 6.74479L8.25612 7.9233L12.1787 4.00071L8.25612 0.078125Z"
        fill="#B2BAC9"
      />
    </svg>
  );
}
