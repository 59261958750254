import type { PropsWithChildren } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Typography } from "@mui/material";
import cn from "classnames";
import Link from "next/link";
import { BlaceLogoSmallSVG } from "@/src/asset";
import { useCMS } from "@/src/component/provider";
import { HOME_TO } from "@/src/const";
import styles from "./SplitLayoutFormContainer.module.scss";

interface SplitLayoutFormContainerProps {
  title: string;
  showTitleWithPadding?: boolean;
  message?: string;
  returnTo?: {
    label?: string;
    to?: string;
    hide?: boolean;
  };
}

function SplitLayoutFormContainer({
  title,
  showTitleWithPadding,
  message,
  returnTo,
  children,
}: PropsWithChildren<SplitLayoutFormContainerProps>) {
  const cms = useCMS();
  return (
    <div className={styles.splitLayoutFormContainer}>
      {!returnTo?.hide && (
        <Link
          href={returnTo?.to ?? cms.constructLink(HOME_TO)}
          aria-label={`Go to ${returnTo?.label ?? "Search"}`}
          className={styles.backToSearchLink}
        >
          <KeyboardBackspaceIcon />
          <Typography variant="body1">{returnTo?.label ?? "Home"}</Typography>
        </Link>
      )}
      <div className={styles.splitLayoutFormBlaceLogo}>
        <Link href={cms.constructLink(HOME_TO)} aria-label="Go to Search">
          <BlaceLogoSmallSVG color={"#000"} size={48} />
        </Link>
      </div>
      <div
        className={cn(
          styles.splitLayoutFormTitle,
          showTitleWithPadding ? styles.splitLayoutFormTitlePadding : undefined
        )}
      >
        <Typography variant="h2">{title}</Typography>
        {message && (
          <Typography variant="h4" fontWeight="400">
            {message}
          </Typography>
        )}
      </div>
      <div className={styles.splitLayoutFormChildren}>{children}</div>
    </div>
  );
}

export default SplitLayoutFormContainer;
