import { BlaceV2Type, StrapiCMSType } from "@/src/type";

/**
 * Builds the Catalog (discover) URL based on filters configured at Strapi CMS
 * @param {StrapiCMSType.CatalogFiltersList} filters - the location address from search
 * @returns {string}
 */
export function buildCatalogUrlFromStrapiFilters(
  filters?: StrapiCMSType.CatalogFiltersList
): string {

  const basePath = "/discover";
  if (!filters) {
    return basePath;
  }

  const parts: string[] = [];
  for (const [key, value] of Object.entries(filters)) {
    if (!value || !value.values || !value.values.length) {
      continue;
    }

    parts.push(`${key}=${buildCatalogUrlFilterValue(value.values)}`);
  }

  if (!parts.length) {
    return basePath;
  }

  return basePath + "?" + parts.join("&");
}

function buildCatalogUrlFilterValue(values: string[]): string {
  if (!values || !values.length) {
    return "";
  }

  return values.map(s => s.replace(/ /g, "+")).join("*");
}

/**
 * @param {BlaceV2Type.SearchType.SearchItem[]} catalogItems
 * @param {string[]} slugList
 * @returns {BlaceV2Type.SearchType.SearchItem[]}
 */
export function filterCatalogSearchItems(
    catalogItems: BlaceV2Type.SearchType.SearchItem[],
    slugList: string[]
): BlaceV2Type.SearchType.SearchItem[] {

  const catalogSearchItems = catalogItems.filter(
      (item: BlaceV2Type.SearchType.SearchItem) => slugList.includes(item.slug)
  );

  // keep the order of elements based on `slugList`
  catalogSearchItems.sort((a, b) => {
    return slugList.indexOf(a.slug) - slugList.indexOf(b.slug);
  });

  return catalogSearchItems;
}
