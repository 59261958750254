import React from "react";
import { FormatPhoneNumber } from "@/src/util";
import styles from "./PhoneLink.module.scss";

interface PhoneLinkProps {
  phoneNumber: string;
}

function PhoneLink({ phoneNumber }: PhoneLinkProps) {
  const formattedPhoneNumber = FormatPhoneNumber.getFormattedPhoneNumber(phoneNumber);

  return (
    <a className={styles.linkTo} href={`tel:${phoneNumber}`}>
      {formattedPhoneNumber}
    </a>
  );
}

export default PhoneLink;
