import { Typography } from "@mui/material";
import Link from "next/link";
import { Base } from "@/src/type/strapiCMS";
import styles from "@/src/component/partial/Header/Header.module.scss";
import { NavigationDrawerProps } from "./NavigationDrawer";

//Used links from cms.globalWebConfig?.data?.attributes?.header?.links

export function EvenMore({ headerLinks }: NavigationDrawerProps) {
  if (!headerLinks) {
    return <></>;
  }

  return (
    <>
      <Typography component="h3" className={styles.title}>
        Even more
      </Typography>
      <ul className={styles.pagesList}>
        {headerLinks.map(({ id, href, label }: Base.Link) => {
          return (
            <li key={id}>
              <Link href={href} className={styles.link}>
                {label}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}
