import { useEffect, useRef } from "react";

export interface UseScrollToInquiryProp {
  isSelected: boolean;
  scrollOffset: number;
  searchId: string;
}

export function useScrollToInquiry({ isSelected, scrollOffset, searchId }: UseScrollToInquiryProp) {
  const eventRef = useRef(null);
  const delay = 1500;

  useEffect(() => {
    setTimeout(() => {
      if (isSelected && eventRef && eventRef.current) {
        //@ts-ignore
        const targetPosition = eventRef.current.offsetTop - scrollOffset;
        //@ts-ignore
        eventRef.current.parentNode?.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      }
    }, delay);
  }, [isSelected, scrollOffset, searchId]);

  return {eventRef};
}
