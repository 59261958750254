export const KEYS = {
  //guest checkout auth
  GUEST_TOKEN: "GUEST_TOKEN",
  GUEST_TOKEN_EXP: "GUEST_TOKEN_EXP",
  GUEST_TOKEN_IS_GUEST: "GUEST_TOKEN_IS_GUEST",

  //identity / auth
  OLD_AUTH: "auth",
  OLD_AUTH_CURRENT_USER: "currentUser",
  AUTH_CALLBACK_PATH: "AUTH_CALLBACK_PATH",
  V2_AUTH_TOKEN: "V2_AUTH_TOKEN",
  V2_AUTH_TOKEN_EXP: "V2_AUTH_TOKEN_EXP",
  V2_AUTH_USER_ID: "V2_AUTH_USER_Id",

  //debug
  DEBUG_DEV_MODE: "DEBUG_DEV_MODE",

  //tracking
  BLACE_EMPLOYEE: "BLACE_EMPLOYEE",
  GOOGLE_ADS_CONVERSION_ID: "CONVERSION_ID",
  LINKEDIN_COVERSION_ID: "LINKEDIN_COVERSION_ID",

  //strapi
  STRAPI_API_URL: "STRAPI_API_URL",
  STRAPI_API_TOKEN: "STRAPI_API_TOKEN",

  //config keys
  ASSET_URL: "ASSET_URL",
  TEXT_CDN_URL: "TEXT_CDN_URL",
  TEXT_CDN_FRONTEND: "TEXT_CDN_FRONTEND",
  ENV: "ENV",
  APP_NAME: "APP_NAME",
  DATADOG_TOKEN: "DATADOG_TOKEN",
  GOOGLE_MAPS_KEY: "GOOGLE_MAPS_KEY",
  BLACE_V2_API: "BLACE_V2_API",
  APP_API: "APP_API",
  APP_TENANT: "APP_TENANT",
};
