import { ImageItem } from "./BlaceV1Type";

export interface Vendor {
  id: number;
  name: string;
  slug: string;
  cities?: VendorCity[];
  contact_name?: string;
  description?: string;
  orderPrice: string;
  exclusiveVenueId: number;
  type: string;
  info: VendorInfo;
  form_images?: string[];
  packages: VendorPackageData[];
  labels?: VendorLabel[];
  images?: ImageItem[];
  //calendar?: ServerCalendar
  //contact: BusinessContactData
  exclusive?: boolean;
  status: string;
  address?: string;
  hasAdvantages: boolean;
  advantageDescription: string;
  hasPromotion: boolean;
  promotionDescription: string;
  pivot: VendorPivot;
  order_price_min?: number;
  location?: {
    formatted_address: string;
    address_line_2?: string;
    route?: string;
    street_number?: string;
    city?: string;
    city_long?: string;
    zip?: number;
    lat?: number;
    lng?: number;
    neighborhood?: string;
  };
}

export type VendorCity = {
  id: number;
  name: string;
  short_name: string;
  status: string;
  slug: string;
};

export type VendorLabel = {
  exclusivity?: string;
  type?: string;
  icon_class?: string;
  slug?: string;
  name?: string;
};

export interface VendorInfo {
  about?: string;
  proTip?: string;
  specialFeatures?: string;
  exclusiveVendors?: string;
}

export interface VendorType {
  type?: string;
  slug?: string;
  id: string;
  name: string;
  description: string;
  vendors_count: number;
  vendors?: Vendor[];
}

export interface VendorTypesQueryOptions {
  venue?: number | undefined;
  perPage?: number;
  page?: number;
}

export interface VendorPackageData {
  id: number;
  vendor_id: number;
  name: string;
  headcount_max?: number;
  headcount_min?: number;
  description?: string;
  attachment?: string;
  images?: ImageItem[];
  is_active?: number;
}

export interface VendorPivot {
  type?: string;
  vendor_id?: string;
  vendor_type?: string;
  venue_id?: string;
}

export type VendorPackageDataIded = VendorPackageData & { id: number };

export enum VendorCategory {
  "av-lightning" = "A/V Lighting",
}
