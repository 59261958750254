export function noLinks(html?: string | null, toReplaceText: string = "") {
  if (!html) {
    return html;
  }

  if (!html.includes("<a") && !html.includes("href")) {
    return html;
  }

  const instances = (html.split("<a") ?? []).length - 1;
  if (instances <= 0) {
    return html;
  }

  let counter = 0;
  while (counter < instances) {
    //@ts-ignore
    const toReplace = html.substring(
      html.indexOf("<a"),
      html.indexOf("</a>") + 4
    );
    html = html.replace(toReplace, toReplaceText);
    counter++;
  }

  //TODO: remove script tags, other dangerous tags

  return html;
}
