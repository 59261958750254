import { StrapiCMSType } from "@/src/type";
import { StrapiService } from "./";

export async function getDataCapture() {
  return await StrapiService.get<StrapiCMSType.DataCaptureResponse>(
    "data-captures",
    "en"
  );
}

export async function getSitemapURLs() {
  return await StrapiService.get<StrapiCMSType.SitemapURLResponse>(
    "sitemap-urls",
    "en"
  );
}

export async function getSimilarCarouselOverridesUsingFilter(filter: string) {
  return await StrapiService.get<StrapiCMSType.SimilarCarouselResponse>(
    "similar-carousel-overrides",
    "en",
    filter
  );
}

export async function getVenueMarketingAsset(filter: string) {
  return await StrapiService.get<StrapiCMSType.VenueMarketingAssetResponse>(
    "venue-marketing-assets",
    "en",
    filter
  );
}

export async function getBusinessFAQ() {
  return await StrapiService.get<StrapiCMSType.BusinessFAQResponse>(
    "business-faqs",
    "en",
    "filters[FAQs][published]=1"
  );
}

export async function getLandingPages(locale?: string, deep: boolean = true) {
  const thisLocale = !locale || locale === "undefined" ? "en" : locale;
  return await StrapiService.get<StrapiCMSType.LandingPageType>(
      "landing-pages",
      thisLocale,
      "filters[addToSitemap]=true",
      deep
  );
}
