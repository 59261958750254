import { createContext, useContext } from "react";
import type { PropsWithChildren } from "react";
import { CircularProgress } from "@mui/material";
import dynamic from "next/dynamic";
import styles from "./NoSSR.module.scss";

interface NoSSRContextType {
  loaderWidth?: string;
  loaderHeight?: string;
}

const NoSSRContext = createContext<NoSSRContextType>({});

const NoSSRDynamic = dynamic<PropsWithChildren<{}>>(
  () => import("@/src/component/partial/NoSSR/NoSSRComponent"),
  {
    ssr: false,
    loading: () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const noSSRContext = useContext(NoSSRContext);
      return (
        <div
          style={{
            height: noSSRContext?.loaderHeight,
            width: noSSRContext.loaderWidth,
          }}
          className={styles.noSSRLoadingLayout}
        >
          <CircularProgress size={80} color="secondary" />
        </div>
      );
    },
  }
);

interface NoSSRProps extends NoSSRContextType {}

function NoSSR({
  children,
  loaderWidth,
  loaderHeight,
}: PropsWithChildren<NoSSRProps>) {
  return (
    //@ts-ignore //TODO: unsure why typescript does not like dynamic import
    <NoSSRContext.Provider
      value={{
        loaderWidth,
        loaderHeight,
      }}
    >
      {/*//@ts-ignore //TODO: unsure why typescript does not like dynamic import*/}
      <NoSSRDynamic>{children}</NoSSRDynamic>
    </NoSSRContext.Provider>
  );
}

export default NoSSR;
