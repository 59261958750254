import type { PropsWithChildren } from "react";
import { Divider, Typography } from "@mui/material";
import Link from "next/link";
import { BaseButton } from "@/src/component/base";
import { SplitLayoutFormContainer } from "@/src/component/partial/SplitLayoutFormContainer";
import { useCMS } from "@/src/component/provider";
import { AuthLogic } from "@/src/model";
import styles from "./RequireAuth.module.scss";

export interface RequireAuthProps {
  lastTokenIntervalTime?: number;
}

function RequireAuth({ children }: PropsWithChildren<RequireAuthProps>) {
  const cms = useCMS();
  //TODO: lastTokenIntervalTime will check if login should be refreshed
  //TODO: ex. Payment will require a login every 8 hours

  if (AuthLogic.hasAuthToken()) {
    return <>{children}</>;
  }

  return (
    <SplitLayoutFormContainer
      title={"NOT AUTHORIZED"}
      message={"Please log in or sign up to access this page"}
    >
      <div className={styles.container}>
        <Link
          href={cms.constructLink("/auth")}
          aria-label="Go to Log in"
          className={styles.buttonLink}
        >
          <BaseButton type="button" variant="contained" size="large" fullWidth>
            LOG IN
          </BaseButton>
        </Link>
        <Divider>
          <Typography variant="subtitle2">OR</Typography>
        </Divider>
        <Link
          href={cms.constructLink("/auth/sign-up")}
          aria-label="Go to Free Sign Up"
          className={styles.buttonLink}
        >
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            <div>FREE SIGN UP</div>
          </BaseButton>
        </Link>
      </div>
    </SplitLayoutFormContainer>
  );
}

export default RequireAuth;
