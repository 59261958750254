import { useEffect, useState } from "react";
import type { PropsWithChildren } from "react";

interface LoadAfterProps {
  type: "scroll";
  scrollFromTop?: number;
}

function LoadAfter({
  children,
  type,
  scrollFromTop,
}: PropsWithChildren<LoadAfterProps>) {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    function handleListener() {
      if (window.pageYOffset > (scrollFromTop ?? 0)) {
        setShow(true);
        window.removeEventListener("scroll", handleListener);
      }
    }

    window.addEventListener("scroll", handleListener, { passive: true });
    return () => window.removeEventListener("scroll", handleListener);
  }, [type, scrollFromTop]);

  return show ? <>{children}</> : <></>;
}

export default LoadAfter;
