import { NumberHelper } from "@src/util/helper";

export const formatToCurrency = (
  value?: string | number | null,
  isFloat = true,
  skipCents = false
) => {
  let amount = value || 0;

  if (typeof amount === "string" && amount[0] === "$") {
    amount = amount.replace("$", "");
  }

  if (typeof amount === "number") {
    amount = isFloat ? amount.toFixed(2) : amount.toString();
  }

  const formatted = "$" + amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$&,");

  return skipCents ? formatted.split(".")[0] : formatted;
};

export const currencyConvertToDecimal = (value: string | number) => {
  if (typeof value === "string") {
    value = value.replace(/\$|,/g, "");

    return parseFloat(value) || 0;
  }
  return value;
};

export const getPercentFromPrice = (
  price: number | string,
  percent: number
) => {
  if (typeof price === "string") {
    price = currencyConvertToDecimal(price);
  }
  const value = (percent / 100) * price;

  return parseFloat(value.toFixed(2));
};

export const percentSubstraction = (
  price: number | string,
  percent: number
) => {
  if (typeof price === "string") {
    price = currencyConvertToDecimal(price);
  }

  return price - getPercentFromPrice(price, percent);
};

export function centsToDollars(val: number): number {
  if (!val || val === 0) {
    return 0;
  }
  return val * 1.00 / 100;
}

export function dollarsToCents(val: number): number {
  if (!val || val === 0) {
    return 0;
  }
  return parseInt((val * 100).toFixed(0), 10);
}

export function formatFormInputValue(x: string, trimCents: boolean = false): string {
  const value: string = NumberHelper.formatFormInputValue(x, trimCents, 2);
  if (value.length === 0) {
    return "";
  }

  return `$${value}`;
}
