import { Typography } from "@mui/material";
import { BaseButton } from "@src/component/base";
import styles from "./InquiryForm.module.scss";

interface CreateInquiryFooterProps {
  setInquiryDrawerOpen: () => void;
  explainPriceText?: string;
  pricing: string;
}

function CreateInquiryFooter({
  explainPriceText,
  pricing,
  setInquiryDrawerOpen,
}: CreateInquiryFooterProps) {
  return (
    <div className={styles.createInquiryFooter}>
      <div className={styles.createInquiryFooterPrice}>
        {typeof explainPriceText === "string" && (
          <Typography
            variant="h6"
            className={styles.createInquiryFooterPricePrefix}
          >
            {explainPriceText}
          </Typography>
        )}
        <Typography variant="h4">{pricing}</Typography>
      </div>
      <BaseButton
        type="submit"
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
        onClick={setInquiryDrawerOpen}
      >
        Contact Us
      </BaseButton>
    </div>
  );
}

export default CreateInquiryFooter;
