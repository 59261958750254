export function EmailIconSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#ECF5FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0372 6.66602L8.98844 6.66602C8.33178 6.66598 7.76599 6.66595 7.31291 6.7286C6.82735 6.79575 6.3619 6.94718 5.98437 7.33549C5.60685 7.7238 5.45963 8.20255 5.39434 8.70199C5.33343 9.16801 5.33346 9.74997 5.3335 10.4254L5.3335 10.4755L5.3335 10.5973L5.3335 10.5973L5.3335 10.5973L5.3335 13.5733C5.33346 14.2487 5.33343 14.8307 5.39434 15.2967C5.45963 15.7962 5.60685 16.2749 5.98437 16.6632C6.3619 17.0515 6.82735 17.2029 7.31291 17.2701C7.76599 17.3327 8.33177 17.3327 8.98842 17.3327H8.98844H15.0119H15.0119C15.6686 17.3327 16.2343 17.3327 16.6874 17.2701C17.173 17.2029 17.6384 17.0515 18.016 16.6632C18.3935 16.2749 18.5407 15.7962 18.606 15.2967C18.6669 14.8307 18.6669 14.2487 18.6668 13.5733V13.5733L18.6668 10.5973L18.6668 10.4254V10.4254C18.6669 9.74997 18.6669 9.16801 18.606 8.70199C18.5407 8.20255 18.3935 7.7238 18.016 7.33549C17.6384 6.94718 17.173 6.79575 16.6874 6.7286C16.2343 6.66595 15.6685 6.66598 15.0119 6.66602L9.0372 6.66602ZM17.1815 9.68176C17.1764 9.36138 17.1647 9.11158 17.1377 8.90503C17.0945 8.57456 17.0248 8.47098 16.9684 8.41298C16.912 8.35498 16.8113 8.28325 16.49 8.23882C16.1474 8.19145 15.6824 8.18983 14.9631 8.18983H9.0372C8.31788 8.18983 7.85291 8.19145 7.51032 8.23882C7.18903 8.28325 7.08833 8.35498 7.03194 8.41298C6.97555 8.47098 6.90581 8.57456 6.86261 8.90503C6.83562 9.11158 6.8239 9.36138 6.81883 9.68176L11.6472 11.8442C11.8694 11.9437 12.1309 11.9437 12.3531 11.8442L17.1815 9.68176ZM6.81498 11.2608L10.9413 13.1088C11.6079 13.4073 12.3924 13.4073 13.059 13.1088L17.1853 11.2608V13.5232C17.1853 14.263 17.1838 14.7413 17.1377 15.0937C17.0945 15.4241 17.0248 15.5277 16.9684 15.5857C16.912 15.6437 16.8113 15.7154 16.49 15.7599C16.1474 15.8073 15.6824 15.8089 14.9631 15.8089H9.0372C8.31788 15.8089 7.85291 15.8073 7.51032 15.7599C7.18903 15.7154 7.08833 15.6437 7.03194 15.5857C6.97555 15.5277 6.90581 15.4241 6.86261 15.0937C6.81655 14.7413 6.81498 14.263 6.81498 13.5232V11.2608Z"
        fill="#205CDF"
      />
    </svg>
  );
}
