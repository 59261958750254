// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global gtag*/
import { KEYS } from "@/src/const";
import { BlaceV2API, External } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import { URLHelper } from "@/src/util/helper";
import { StorageHelper } from "@/src/util/helper/StorageHelper";
import { Log } from "@/src/util/log";
import { uniqueId } from "@/src/util/uuid";

//NOTE: all tracking for google, meta, hubspot is turned off in _document with ENV/LOCALHOST const from .env

/**
 * set a unique persistent id
 */
export const SESSION_ID = async () => {
  const EXPIRATION = 6 * 60 * 60 * 1000;
  const id = await StorageHelper().getFromCache("SESSION_ID");
  if (typeof id === "string" && id.length > 1) {
    StorageHelper().setToCache("SESSION_ID", `${id}`, EXPIRATION);
    return id;
  }

  const newId = uniqueId();
  StorageHelper().setToCache("SESSION_ID", `${newId}`, EXPIRATION);
  return newId;
};

/**
 * set a unique persistent id
 */
export const PERSISTENT_ID = async () => {
  const id = await StorageHelper().getItem("PERSISTENT_ID");
  if (typeof id === "string" && id.length > 1) {
    return id;
  }

  const newId = uniqueId();
  StorageHelper().setItem("PERSISTENT_ID", `${newId}`);
  return newId;
};

/**
 * Use google maps to get approximate location of the person using Blace
 *
 * @returns
 */
export async function getGeoLocation(): Promise<
  BlaceV2Type.ActivityGeoLocation | undefined
> {
  const GEO_LOCATION = "GEO_LOCATION";
  const EXPIRATION = 6 * 60 * 60 * 1000;
  const data = await StorageHelper().getFromCache(GEO_LOCATION);
  if (data) {
    //refresh for 6 hours everytime this is called
    StorageHelper().setToCache(GEO_LOCATION, data, EXPIRATION);
    return data;
  }

  const response = await External.GoogleMapsAPI.geoLocate();
  if (!response.failure) {
    const reverseGeoCodeResponse =
      await BlaceV2API.GoogleServiceV2.getGoogleReverseGeoCode(
        response.location.lat,
        response.location.lng
      );

    const toStore: BlaceV2Type.ActivityGeoLocation = {
      latitude: response.location.lat,
      longitude: response.location.lng,
      state: undefined,
      city: undefined,
      country: undefined,
      firstResult: undefined,
      results: undefined,
    };

    //TODO: consider a more type safe approach for this
    if ((reverseGeoCodeResponse?.body?.payload?.results ?? []).length > 0) {
      const result = reverseGeoCodeResponse?.body?.payload?.results[0];
      for (const addressComponent of result.address_components) {
        if (
          (addressComponent.types ?? []).includes("administrative_area_level_1")
        ) {
          toStore.state = addressComponent.short_name;
        }

        if ((addressComponent.types ?? []).includes("locality")) {
          toStore.city = addressComponent.long_name;
        }

        if ((addressComponent.types ?? []).includes("country")) {
          toStore.country = addressComponent.long_name;
        }
      }
    }

    StorageHelper().setToCache(GEO_LOCATION, toStore, EXPIRATION);
    return toStore;
  }
}

/**
 *
 * @param eventType - the type of event that is being sent to facebook
 * @param eventTypeId  - an id for the event
 * @param data - data for the event
 */
export function facebookTracking(
  eventType: "init" | "track" | "trackCustom",
  eventTypeId: string,
  data?: any
) {
  const ENV = SharedConfigManager.getValue(KEYS.ENV);
  if (typeof window === "undefined" || ENV !== "prod" || isEmployee()) {
    return;
  }

  //@ts-ignore
  if (window?.fbq) {
    //@ts-ignore
    fbq(eventType, eventTypeId, data);

    Log.logToConsoleDebug("ActivityLogic.ts", "Facebook", [
      { eventType, eventTypeId, data },
    ]);
  }
}

/**
 * @ref https://support.google.com/analytics/answer/9267735
 * @param eventType - the type of event in FA
 * @param data - an associated data properties
 * @returns
 */
export function ga4Tracking(
  eventType:
    | "generate_lead"
    | "login"
    | "sign_up"
    | "share"
    | "search"
    | "select_content"
    | string,
  data: any,
  isConversion?: boolean
) {
  const ENV = SharedConfigManager.getValue(KEYS.ENV);
  if (typeof window === "undefined" || ENV !== "prod" || isEmployee()) {
    return;
  }

  //@ts-ignore
  if (window?.gtag) {
    //@ts-ignore
    window.gtag("event", eventType, data);

    const conversionId = localStorage.getItem(KEYS.GOOGLE_ADS_CONVERSION_ID);
    if (isConversion && conversionId) {
      //@ts-ignore
      window.gtag("event", "conversion", { send_to: conversionId });

      //add linkedin tracking on conversion
      linkedinConversion();
    }

    Log.logToConsoleDebug("ActivityLogic.ts", "GA4 event", [
      { eventType, data, isConversion, conversionId },
    ]);
  }
}

/**
 * @ref https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
 * @param tagId - the GTM tag id for GA
 * @param clientId - the ID associated with the client
 * @returns
 */
export function ga4InitConfig(tagId: string, clientId?: string) {
  const ENV = SharedConfigManager.getValue(KEYS.ENV);
  if (typeof window === "undefined" || ENV !== "prod" || isEmployee()) {
    return;
  }

  //@ts-ignore
  if (window?.gtag) {
    //@ts-ignore
    window.gtag("config", tagId, { user_id: clientId });

    Log.logToConsoleDebug("ActivityLogic.ts", "GTM config", [
      { clientId, tagId },
    ]);
  }
}

export async function linkedinConversion() {
  const conversionId = localStorage.getItem(KEYS.LINKEDIN_COVERSION_ID);
  //@ts-ignore
  if (window?.lintrk && typeof conversionId === "string") {
    //@ts-ignore //TODO: make this part of the CMS someday
    window.lintrk("track", { conversion_id: conversionId });
  }
}

export async function toActivityService(
  event: BlaceV2Type.ActivityEvent,
  overrides?: {
    sessionId?: string;
    applicationVersion?: string;
    url?: string;
  }
) {
  await BlaceV2API.ActivityServiceV2.postActivity(event, overrides);
}

export function isEmployee(forceIsEmployee?: boolean): boolean {
  const isEmployee = URLHelper.urlGetParameter("blaceEmployee");
  if (isEmployee || forceIsEmployee) {
    localStorage.setItem(KEYS.BLACE_EMPLOYEE, "true");
    return true;
  }
  const checkEmployeeStorage = localStorage.getItem(KEYS.BLACE_EMPLOYEE);
  return typeof checkEmployeeStorage === "string";
}
