export function CapacityUShapedSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        d="M6.52513 19.4716L6.52513 6.47412L24.9761 6.47412V8.41156L8.46256 8.41156L8.46256 17.5342L24.9761 17.5342V19.4716L6.52513 19.4716Z"
        fill="black"
      />
      <path
        d="M22.8814 3.45418C20.744 3.12403 21.2392 -0.0820894 23.3766 0.248061C25.5092 0.577469 25.014 3.78359 22.8814 3.45418Z"
        fill="#1D39C4"
      />
      <path
        d="M16.306 3.45418C14.1685 3.12403 14.6638 -0.0820894 16.8012 0.248061C18.9338 0.577469 18.4386 3.78359 16.306 3.45418Z"
        fill="#1D39C4"
      />
      <path
        d="M9.73027 3.45418C7.59286 3.12403 8.08809 -0.0820894 10.2255 0.248061C12.3581 0.577469 11.8629 3.78359 9.73027 3.45418Z"
        fill="#1D39C4"
      />
      <path
        d="M22.8814 25.753C20.744 25.4229 21.2392 22.2167 23.3766 22.5469C25.5092 22.8763 25.014 26.0824 22.8814 25.753Z"
        fill="#1D39C4"
      />
      <path
        d="M16.3062 25.753C14.1688 25.4229 14.664 22.2167 16.8014 22.5469C18.934 22.8763 18.4388 26.0824 16.3062 25.753Z"
        fill="#1D39C4"
      />
      <path
        d="M9.73052 25.753C7.59311 25.4229 8.08833 22.2167 10.2257 22.5469C12.3583 22.8763 11.8631 26.0824 9.73052 25.753Z"
        fill="#1D39C4"
      />
      <path
        d="M1.60308 11.3673C-0.534332 11.0371 -0.0391073 7.831 2.0983 8.16115C4.2309 8.49056 3.73568 11.6967 1.60308 11.3673Z"
        fill="#1D39C4"
      />
      <path
        d="M1.60308 17.9444C-0.534332 17.6143 -0.0391073 14.4081 2.0983 14.7383C4.2309 15.0677 3.73568 18.2738 1.60308 17.9444Z"
        fill="#1D39C4"
      />
    </svg>
  );
}
