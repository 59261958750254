import { REPLACE_STORAGE_URL, TEXT_CDN_URL } from "@/src/const";

//TODO - make this dynamic such that it can detect `d` OR locale and build correct path
//TODO - this needs fallback logic for locale to use html lang =
export function constructLink(path: string, locale?: string) {
  let localeDynamicValue: string | undefined | null = locale;
  if (!localeDynamicValue && typeof window !== "undefined") {
    localeDynamicValue = document.querySelector("html")?.getAttribute("lang");
  }

  if (localeDynamicValue === "undefined" || !localeDynamicValue) {
    localeDynamicValue = "en";
  }

  if (path.includes(`/d/${localeDynamicValue}`)) {
    return path;
  }

  return `/d/${localeDynamicValue}${path}`;
}

export function replaceStorageUrlWithCdnUrl(url: string): string | undefined {
  if (typeof url !== "string") {
    return;
  }
  return url.replace(REPLACE_STORAGE_URL ?? "", TEXT_CDN_URL ?? "");
}

export function citySelection(id: number | string) {
  if (id === 1) {
    return "New York";
  }
  if (id === 2) {
    return "Los Angeles";
  }
}
