import { KEYS } from "@/src/const";
import { BlaceV2Type } from "@/src/type";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import { HTTP, HTTPResponse } from "./HTTP";

export async function postAuth(
  v1Token: string,
  timeZone: string
): Promise<HTTPResponse<BlaceV2Type.AuthTokenResponse>> {
  const BLACE_V2_API: string = SharedConfigManager.getValue(KEYS.BLACE_V2_API);
  const APP_TENANT: string = SharedConfigManager.getValue(KEYS.APP_TENANT);

  const payload: BlaceV2Type.AuthTokenRequest = {
    v1Token: v1Token,
    timeZone: timeZone
  };

  const action = new HTTP<BlaceV2Type.AuthTokenResponse>(
    `${BLACE_V2_API}/auth`,
    HTTP.methods.POST,
    payload
  );
  action.setAllEnvId(APP_TENANT);

  return action.execute();
}
