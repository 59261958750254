import { useEffect, useRef, useState } from "react";
import type { MouseEvent, ReactElement } from "react";
import { useBreakPointDown } from "@/src/hook";
import { BaseButton } from "@src/component/base";
import styles from "./LineClampText.module.scss";

interface LineClampTextProps {
  lineHeight: number;
  linesToShowMobile?: number;
  linesToShowDesktop?: number;
  ComponentWithText: ReactElement;
  readMoreOnClick?: () => void;
}

function LineClampText({
  lineHeight,
  linesToShowDesktop = 7,
  linesToShowMobile = 4,
  ComponentWithText,
  readMoreOnClick,
}: LineClampTextProps) {
  const [readMore, setReadMore] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useBreakPointDown("md");
  const contentHeight = isMobile
    ? lineHeight * linesToShowMobile
    : lineHeight * linesToShowDesktop;

  useEffect(() => {
    const toShow = (elementRef?.current?.scrollHeight ?? 0) > contentHeight;
    if (toShow != showButton) {
      setShowButton(toShow);
    }
  }, [elementRef?.current?.scrollHeight, contentHeight, showButton]);

  function handleOnClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (typeof readMoreOnClick === "function") {
      readMoreOnClick();
    } else {
      setReadMore(!readMore);
    }
  }

  return (
    <div className={styles.clampBox}>
      <div
        ref={elementRef}
        data-testid="test-clampText"
        className={!readMore ? styles.clampLines : undefined}
        style={{
          WebkitLineClamp: isMobile ? linesToShowMobile : linesToShowDesktop,
          lineClamp: isMobile ? linesToShowMobile : linesToShowDesktop,
        }}
      >
        {ComponentWithText}
      </div>
      {showButton && (
        <>
          <div className={styles.buttonPaddingTop} />
          <BaseButton
            type="button"
            variant="text"
            color="secondary"
            classes={{
              root: styles.readMoreButton,
            }}
            onClick={e => handleOnClick(e)}
          >
            {readMore ? "Read less" : "Read more"}
          </BaseButton>
        </>
      )}
    </div>
  );
}

export default LineClampText;
