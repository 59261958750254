export function CapacityClassroomSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <rect y="7" width="11" height="1" fill="black" />
      <circle cx="2" cy="12" r="2" fill="#1D39C4" />
      <circle cx="9" cy="12" r="2" fill="#1D39C4" />
      <rect x="15" y="7" width="11" height="1" fill="black" />
      <circle cx="17" cy="12" r="2" fill="#1D39C4" />
      <circle cx="24" cy="12" r="2" fill="#1D39C4" />
      <rect y="19" width="11" height="1" fill="black" />
      <circle cx="2" cy="24" r="2" fill="#1D39C4" />
      <circle cx="9" cy="24" r="2" fill="#1D39C4" />
      <rect x="15" y="19" width="11" height="1" fill="black" />
      <circle cx="17" cy="24" r="2" fill="#1D39C4" />
      <circle cx="24" cy="24" r="2" fill="#1D39C4" />
      <circle cx="13" cy="2" r="2" fill="#1D39C4" />
    </svg>
  );
}
