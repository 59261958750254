import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { BlaceV2API } from "@/src/service";
import { BlaceV2Type } from "@/src/type";

export function useInquiryData() {
  const [inquiryData, setInquiryData] = useState<
    BlaceV2Type.EventType.Inquiry | undefined
  >(undefined);
  const { query } = useRouter();

  const getInquiryData = async (inquiryId: string) => {
    const inquiry = await BlaceV2API.EventServiceV2.getInquiry(inquiryId);
    if (inquiry.body?.metadata.statusCode === 200) {
      setInquiryData(inquiry.body?.payload);
    }
  };

  useEffect(() => {
    if (query.inquiryId) {
      getInquiryData(query.inquiryId as string);
    }
  }, [query]);

  if (!inquiryData) {
    return;
  }

  return {
    eventName: inquiryData.eventName,
    phone: inquiryData.phone,
    startDate: inquiryData.startDate,
    endDate: inquiryData.endDate,
    budget: inquiryData.budget,
    guests: inquiryData.guests,
    notes: inquiryData.notes,
  };
}
