import { ImageItem } from "./BlaceV1Type";
import { Vendor } from "./VendorType";

export interface HouseRules {
  allows_cooking: boolean;
  allows_fog_haze_machine: boolean;
  allows_glitter_confetti: boolean;
  allows_music: boolean;
  allows_open_flame: boolean;
  allows_red_wine: boolean;
  allows_smoking: boolean;
  allows_ticket_sales_at_all: boolean;
  allows_ticket_sales_on_prem: boolean;
  allows_underage: boolean;
  created_at: string;
  guest_curfew_add_details: string | null;
  has_guest_curfew: boolean;
  has_load_out_curfew: boolean;
  has_music_curfew: boolean;
  has_show_restriction: boolean;
  id: number;
  load_out_curfew_add_details: string | null;
  music_curfew_add_details: string | null;
  shoe_restriction_add_details: string | null;
  updated_at: string;
}

export interface VenueCapacity {
  seated: number;
  standing: number;
  theater: number;
  boardroom: number;
  classroom: number;
}

export interface VenueFeatures {
  back_of_house: boolean;
  breakout_rooms: boolean;
  commercial_kitchen: boolean;
  dressing_room: boolean;
  fireplace: boolean;
  glass_frontage: boolean;
  grill: boolean;
  high_ceilings: boolean;
  kitchen: boolean;
  outdoor_kitchen: boolean;
  outdoor_space: boolean;
  prep_kitchen: boolean;
  rooftop: boolean;
  security_system: boolean;
  showroom_kitchen: boolean;
  storage_space: boolean;
}

export interface VenueParams {
  minimum_starting_at: number;
  inhouse_vendor_types: string[];
  sq_footage: number;
  ceiling_height: number;
  re_walkthrough: string;
  things_you_should_know?: string;
  number_of_floors_label: string;
  cancellation_policy?: string;
  notes_to_sales: string;
  instagram: string;
  display_pricing: boolean;
  short_pricing_details: null | string;
  short_pricing_details_label: null | string;
  // facebook: string;
}

export type VenueLabelType = "amenities" | "best used for";

export type VenueLabel = {
  exclusivity?: string;
  type?: VenueLabelType;
  icon_class?: string;
  slug?: string;
  name?: string;
};

export type VenueCategory = {
  id: number;
  name: string;
  is_legacy: boolean;
  created_at: string;
  updated_at: string;
  pivot?: {
    venue_id: number;
    category_id: number;
  };
};

export interface VenueItem {
  id: number;
  name: string;
  slug: string;
  description: string;
  address?: {
    formatted_address: string;
    address_line_1?: string;
    address_line_2?: string;
    city?: string;
  };
  city: { slug: string };
  categories: VenueCategory[];
  labels?: Array<VenueLabel>;
  capacity?: VenueCapacity;
  website?: string;
  city_id: number;
  location_address?: {
    formatted_address: string;
    address_line_2?: string;
    route?: string;
    street_number?: string;
    city?: string;
    city_long?: string;
    zip?: number;
    lat?: number;
    lng?: number;
  };
  exclusive_vendors?: Array<Vendor>;
  params?: VenueParams;
  payment_type: string;
  floorplans?: string[];
  security_deposit?: string;
  rating?: number;
  max_head_count?: number;
  location?: string;
  form_wp_images: string[];
  rooms?: Room[];
  images: ImageItem[];
  photo?: ImageItem[];
  form_google_images?: string[];
  status?: string;
  client_owned?: boolean;
  is_exclusive?: boolean;
  primary_contact?: any;
  inhouse_exclusive_vendors: Array<{ id: number; name: string; type: string }>;
  partner?: string | null;
  has_promotion: boolean;
  promotion_description: string;
  payment_structure?: {
    pricing_details: string | null;
  };
}

export interface VenuesQueryOptions {
  name?: string;
  location?: string[];
  corners?: object;
  perPage?: number;
  page?: number | string;
  price?: string;
  orderBy?: string;
  scope?: number;
}

export interface VenuesQueryLocationOptions {
  location?: object;
}

export interface VenueLocation {
  id: number;
  name: string;
  location_address: Location;
}

export interface Location {
  formatted_address?: string;
  lat?: number;
  lng?: number;
}

export interface VenueCategoriesOptions {
  legacy: number;
}

export interface Room {
  floors: number;
  capacity: number;
  space: number;
  created_at: string;
  details: string;
  id: number;
  linked_room?: Room;
  linked_room_id: number;
  name: string;
  updated_at: string;
  venue_id: number;
  image?: ImageItem;
  images?: ImageItem[];
  price: number | null;
  short_pricing_details: string | null;
  short_pricing_details_label: string | null;
  pricing_details: string | null;
  display_pricing: boolean;
}

export enum SelectVenueStep {
  FindVenue = "findVenue",
  CreateVenue = "createVenue",
}

export interface VenueItemReduced {}
