import { useEffect, useState } from "react";
import { KEYS } from "@/src/const";
import { useNextNavigation } from "@/src/hook/useNextNavigation";
import { AuthLogic, CMSLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { AuthType } from "@/src/type";
import { StorageHelper } from "@/src/util";

const storageHelperUseAuth = StorageHelper();

export type UseAuthType = {
  guestIsValid: boolean;
  identityIsValid: boolean;
  requireAuthToContinue: (
    maxTokenAge?: number,
    queryParams?: string,
    callbackPath?: string,
    signup?: boolean
  ) => boolean;
};

export function useAuth(): UseAuthType {
  const nextNavigation = useNextNavigation();
  const [identityIsValid, setIdentityIsValid] = useState<boolean>(false);
  const [guestIsValid, setGuestIsValid] = useState<boolean>(false);
  const guestTokenChange = storageHelperUseAuth.useLocalStorage<
    string | undefined
  >(KEYS.GUEST_TOKEN, undefined);
  const oldAuthStorageChange = storageHelperUseAuth.useLocalStorage<
    AuthType.AuthStorage | undefined
  >(KEYS.OLD_AUTH, undefined);

  /**
   * determine the user authenticated, if not create guest session
   */
  useEffect(() => {
    (async () => {
      if (AuthLogic.hasToken()) {
        return;
      }

      const guestTokenResponse =
        await BlaceV2API.GuestServiceV2.getGuestToken();
      if (guestTokenResponse?.body?.metadata?.statusCode === 200) {
        AuthLogic.storeGuestToken(guestTokenResponse?.body?.payload.guestToken);
      }
    })();
  }, []);

  /**
   * determine if the guest is valid
   */
  useEffect(() => {
    const hasToken: boolean = typeof AuthLogic.getGuestToken() === "string";
    if ((hasToken && guestIsValid) || (!hasToken && !guestIsValid)) {
      return;
    }
    setGuestIsValid(typeof AuthLogic.getGuestToken() === "string");
  }, [guestTokenChange, guestIsValid]);

  /**
   * determine if the guest is valid
   */
  useEffect(() => {
    const hasToken: boolean = typeof AuthLogic.getIdentityToken() === "string";
    if ((hasToken && identityIsValid) || (!hasToken && !identityIsValid)) {
      return;
    }
    setIdentityIsValid(hasToken);
  }, [oldAuthStorageChange, identityIsValid]);

  /**
   * determines if auth required for given time interval
   *
   * @param {number} maxTokenAge - [OPTIONAL] when provided will check if the token is older than max age and require new signin
   * @param {string} queryParams - [OPTIONAL] when provided will add query params to login url
   */
  function requireAuthToContinue(
    maxTokenAge?: number,
    queryParams?: string,
    callbackPath?: string,
    signup?: boolean
  ): boolean {
    //TODO: add logic to check token max age for payments
    if (AuthLogic.hasAuthToken()) {
      return false;
    }

    if (callbackPath) {
      AuthLogic.setAuthCallbackPath(callbackPath);
    }

    const authPath = signup ? "auth/sign-up" : "auth";
    nextNavigation.router.push(
      CMSLogic.constructLink(
        `/${authPath}${queryParams ? `${queryParams}` : ""}`
      )
    );
    return true;
  }

  return {
    guestIsValid,
    identityIsValid,
    requireAuthToContinue,
  };
}
