export const urlGetParameter = (parameter: string) => {
  if (typeof window === "undefined") {
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(parameter);
  if (!value) {
    return value;
  }
  return decodeURIComponent(value);
};

export function urlRemoveParameter(parameter: string) {
  if (typeof window === "undefined") {
    return;
  }

  const url = new URL(window.location.href);
  url.searchParams.delete(parameter);
  //@ts-ignore
  window.history.pushState(null, null, url.href);
}

export function urlPutParameter(parameter: any, value: any) {
  if (typeof window === "undefined") {
    return;
  }

  if (!value) {
    urlRemoveParameter(parameter);
  } else {
    const url = new URL(window.location.href);
    url.searchParams.set(parameter, encodeURIComponent(value));
    //@ts-ignore
    window.history.pushState(null, null, url.href);
  }
}
