import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { BaseButton } from "@/src/component/base";
import { MagicLinkSent } from "@/src/component/partial/MagicLinkSent";
import { SplitLayoutFormContainer } from "@/src/component/partial/SplitLayoutFormContainer";
import { useResetPassword } from "@/src/hook";
import { FormHelper } from "@/src/util";
import type { ResetPasswordTypeFormik } from "@/src/hook";
import styles from "./ResetPasswordForm.module.scss";

function ResetPasswordForm() {
  const resetPassword = useResetPassword();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  function isDisabled(): boolean {
    if (
      (resetPassword.formik.values.password ?? "").length > 7 &&
      !FormHelper.formikCheckError<ResetPasswordTypeFormik>(
        resetPassword.formik,
        "password"
      )
    ) {
      return false;
    }

    return true;
  }

  if (resetPassword.saveComplete) {
    return (
      <MagicLinkSent
        title={"SUCCESS"}
        message={"Your password was successfully reset."}
      />
    );
  }

  return (
    <SplitLayoutFormContainer title={"RESET PASSWORD"}>
      <>
        <form
          className={styles.resetPasswordForm}
          onSubmit={resetPassword.formik.handleSubmit}
        >
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            onChange={e => {
              resetPassword.formik.setFieldValue(
                "password",
                e?.target?.value ?? ""
              );
            }}
            value={resetPassword.formik.values.password}
            helperText={FormHelper.formikErrorMessage<ResetPasswordTypeFormik>(
              resetPassword.formik,
              "password"
            )}
            error={FormHelper.formikCheckError<ResetPasswordTypeFormik>(
              resetPassword.formik,
              "password"
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={showConfirm ? "text" : "password"}
            fullWidth
            onChange={e => {
              resetPassword.formik.setFieldValue(
                "confirm",
                e?.target?.value ?? ""
              );
            }}
            value={resetPassword.formik.values.confirm}
            helperText={FormHelper.formikErrorMessage<ResetPasswordTypeFormik>(
              resetPassword.formik,
              "confirm"
            )}
            error={FormHelper.formikCheckError<ResetPasswordTypeFormik>(
              resetPassword.formik,
              "confirm"
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setShowConfirm(!showConfirm)}
                >
                  {showConfirm ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </InputAdornment>
              ),
            }}
          />
          <BaseButton
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            loading={resetPassword.isLoading}
            disabled={isDisabled()}
          >
            RESET PASSWORD
          </BaseButton>
          {resetPassword.hasError && (
            <Typography color="error">{resetPassword.hasError}</Typography>
          )}
        </form>
      </>
    </SplitLayoutFormContainer>
  );
}

export default ResetPasswordForm;
