import { TextField, Typography } from "@mui/material";
import { BaseButton } from "@/src/component/base";
import { MagicLinkSent } from "@/src/component/partial/MagicLinkSent";
import { SplitLayoutFormContainer } from "@/src/component/partial/SplitLayoutFormContainer";
import { useForgotPassword } from "@/src/hook";
import { FormHelper } from "@/src/util";
import type { ForgotPasswordTypeFormik } from "@/src/hook";
import styles from "./ForgotPasswordForm.module.scss";

function ForgotPasswordForm() {
  const forgotPassword = useForgotPassword();

  function isDisabled(): boolean {
    if (
      forgotPassword.formik.values.email &&
      !FormHelper.formikCheckError<ForgotPasswordTypeFormik>(
        forgotPassword.formik,
        "email"
      )
    ) {
      return false;
    }
    return true;
  }

  /**
   * after a registration is complete let the user now they must confirm email
   */
  if (forgotPassword.saveComplete) {
    return (
      <MagicLinkSent
        title={"RESET PASSWORD LINK SENT"}
        message={`If an account exists for ${forgotPassword.formik.values.email} a reset password link will arrive soon.  Please check your email to reset your password.`}
      />
    );
  }

  return (
    <SplitLayoutFormContainer
      title={"FORGOT PASSWORD"}
      message={"Enter your email to reset your password"}
    >
      <>
        <form
          className={styles.forgotPasswordFormEmail}
          onSubmit={forgotPassword.formik.handleSubmit}
        >
          <TextField
            data-testid="email-password-forgot-field"
            label="Email"
            fullWidth
            onChange={e => {
              forgotPassword.formik.setFieldValue(
                "email",
                e?.target?.value ?? ""
              );
            }}
            value={forgotPassword.formik.values.email}
            helperText={FormHelper.formikErrorMessage<ForgotPasswordTypeFormik>(
              forgotPassword.formik,
              "email"
            )}
            error={FormHelper.formikCheckError<ForgotPasswordTypeFormik>(
              forgotPassword.formik,
              "email"
            )}
          />
          <BaseButton
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            loading={forgotPassword.isLoading}
            disabled={isDisabled()}
          >
            SEND RESET PASSWORD LINK
          </BaseButton>
          {forgotPassword.hasError && (
            <Typography color="error">{forgotPassword.hasError}</Typography>
          )}
        </form>
      </>
    </SplitLayoutFormContainer>
  );
}

export default ForgotPasswordForm;
