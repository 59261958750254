import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import cn from "classnames";
import styles from "./SimilarCarousel.module.scss";

interface SimilarCarouselButtons {
  nextClassName: string;
  prevClassName: string;
  beginOrEnd: "begin" | "end" | undefined;
  className?: string;
  sideButtons?: boolean;
}

function SimilarCarouselButtons({
  prevClassName,
  nextClassName,
  beginOrEnd,
  className,
  sideButtons,
}: SimilarCarouselButtons) {
  const leftButtonIconColor = beginOrEnd === "begin" ? "inherit" : "primary";
  const rightButtonIconColor = beginOrEnd === "end" ? "inherit" : "primary";
  const leftButtonLabel = "Back Button";
  const rightButtonLabel = "Forward Button";

  return (
    <div className={cn(styles.buttonsContainer, className)}>
      {sideButtons ? (
        <>
          <IconButton
            className={cn(prevClassName, styles.leftButton)}
            aria-label={leftButtonLabel}
          >
            <KeyboardArrowLeftIcon color={leftButtonIconColor} />
          </IconButton>
          <IconButton
            className={cn(nextClassName, styles.rightButton)}
            aria-label={rightButtonLabel}
          >
            <KeyboardArrowRightIcon color={rightButtonIconColor} />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton className={prevClassName} aria-label={leftButtonLabel}>
            <ArrowBackIcon color={leftButtonIconColor} />
          </IconButton>
          <IconButton className={nextClassName} aria-label={rightButtonLabel}>
            <ArrowForwardIcon color={rightButtonIconColor} />
          </IconButton>
        </>
      )}
    </div>
  );
}

export default SimilarCarouselButtons;
