interface LinkedInColorLogoSVGProps {
  size: number;
}

export function LinkedInColorLogoSVG({ size }: LinkedInColorLogoSVGProps) {
  return (
    <svg
      id="LinkedInSVG"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
      width={`${size}px`}
      height={`${size}px`}
      dangerouslySetInnerHTML={{
        __html: `<defs>
        <style>
          .cls-1 {
            isolation: isolate;
          }
          .cls-2 {
            fill: #0277b5;
          }
          .cls-2, .cls-3 {
            fill - rule: evenodd;
          }
          .cls-3 {
            fill: #fff;
          }
        </style>
      </defs>
      <title>2</title>
      <g>
        <g class="cls-1">
          <path class="cls-2" d="M367.5,3.4H32.4c-16,0-29,12.7-29,28.3V368.4c0,15.7,13,28.4,29,28.4H367.5c16,0,29.1-12.7,29.1-28.4V31.8c0-15.6-13.1-28.3-29.1-28.3" />
        </g>
        <path class="cls-3" d="M61.7,338.6H120V150.9H61.7Zm29.2-281A33.8,33.8,0,1,1,57,91.4,33.8,33.8,0,0,1,90.8,57.6Z" />
        <path class="cls-3" d="M156.6,150.9h56v25.6h.8c7.8-14.8,26.8-30.3,55.2-30.3,59.1,0,70,38.9,70,89.4v103H280.2V247.3c0-21.8-.4-49.8-30.3-49.8s-35,23.7-35,48.2v92.9H156.6V150.9" />
      </g>`,
      }}
    ></svg>
  );
}
