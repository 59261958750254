import SendIcon from "@mui/icons-material/Send";
import { CircularProgress, Typography } from "@mui/material";
import cn from "classnames";
import { useClientLeadCapture } from "@/src/hook";
import { StrapiCMSType } from "@/src/type";
import { FormHelper } from "@/src/util";
import styles from "./FooterSignup.module.scss";

interface FooterSignupProps {
  footer: StrapiCMSType.Partial.Footer;
}

function FooterSignup({ footer }: FooterSignupProps) {
  const clientLeadCapture = useClientLeadCapture({ occurredAt: "footer" });

  return (
    <div className={styles.footerSignup}>
      <div className={styles.footerSignupTitle}>
        <Typography variant="h2" color="white">
          {footer.signUpHeader}
        </Typography>
      </div>
      <div className={styles.footerSignupDescription}>
        <Typography variant="body1" color="white">
          {footer.signUpDescription}
        </Typography>
      </div>
      {!clientLeadCapture.saveComplete && (
        <form onSubmit={clientLeadCapture.formik.handleSubmit} data-testid="sign-up-form">
          <div className={styles.footerSignupInputContainer}>
            <Typography variant="body1" sx={{ width: "100%" }}>
              <input
                className={cn(
                  styles.footerSignupInput,
                  styles.footerSignupInputWhite
                )}
                placeholder="Email"
                onChange={e => {
                  clientLeadCapture.formik.setFieldValue(
                    "email",
                    e?.target?.value ?? ""
                  );
                }}
                value={clientLeadCapture.formik.values.email}
              />
            </Typography>
            <button
              type="submit"
              aria-label="Submit email to newsletter"
              className={styles.footerSignupBttn}
            >
              {clientLeadCapture.isLoading ? (
                <CircularProgress size={22} color="secondary" />
              ) : (
                <SendIcon fontSize="inherit" color="secondary" />
              )}
            </button>
          </div>
          {(clientLeadCapture.hasError ||
            FormHelper.formikCheckError(clientLeadCapture.formik, "email")) && (
            <div className={styles.footerSignupInputMessage}>
              <Typography variant="body1" color="white">
                {clientLeadCapture.hasError ??
                  FormHelper.formikErrorMessage(
                    clientLeadCapture.formik,
                    "email"
                  )}
              </Typography>
            </div>
          )}
        </form>
      )}
      {clientLeadCapture.saveComplete && (
        <Typography variant="h3" color="white">
          You're in! BLACE will send you trending event &amp; production news.
        </Typography>
      )}
    </div>
  );
}

export default FooterSignup;
