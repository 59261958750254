import React from "react";

export function CheckFillSVG() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM12.6931 7.3832C12.9878 7.02964 13.5132 6.98187 13.8668 7.2765C14.2204 7.57114 14.2681 8.09661 13.9735 8.45017L10.9752 12.0481L10.9444 12.0851L10.9444 12.0851C10.6981 12.3808 10.4611 12.6653 10.2353 12.8707C9.98225 13.1008 9.64401 13.3264 9.18617 13.3471C8.72833 13.3679 8.37106 13.1739 8.09826 12.9676C7.85472 12.7835 7.59298 12.5216 7.3209 12.2495L7.28688 12.2154L6.07739 11.0059C5.75195 10.6805 5.75195 10.1529 6.07739 9.82743C6.40283 9.502 6.93046 9.502 7.2559 9.82743L8.46539 11.0369C8.78453 11.3561 8.96367 11.5325 9.1035 11.6382L9.10884 11.6423L9.11379 11.6378C9.24347 11.5198 9.4059 11.3279 9.69483 10.9812L12.6931 7.3832Z"
        fill="#B2BAC9"
      />
    </svg>
  );
}
