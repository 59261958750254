import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import { KEYS } from "@src/const";
import type { ImageLoaderProps } from "next/image";

export function imageSizeAndQuality(
  path: string,
  quality: number = 100,
  width: number = 750,
  addDomain: boolean = false
): string {
  if (!path) {
    return "";
  }

  const prefix: string = addDomain ? `${SharedConfigManager.getValue(KEYS.ASSET_URL)}/` : "";

  let resultPath: string = `${prefix}${path}` + (path.includes("?") ? "&" : "?");

  // format
  const autoFormat: string = "auto=format";
  if (!resultPath.includes(autoFormat)) {
    resultPath += `${autoFormat}&`;
  }
  // quality
  const qualityPrefix: string = "q=";
  if (resultPath.includes(qualityPrefix)) {
    resultPath = resultPath.replace(/q=\d*/g, `${qualityPrefix}${quality}`);
  } else {
    resultPath += `${qualityPrefix}${quality}&`;
  }
  // width
  const widthPrefix: string = "w=";
  if (resultPath.includes(widthPrefix)) {
    resultPath = resultPath.replace(/w=\d*/g, `${widthPrefix}${width}`);
  } else {
    resultPath += `${widthPrefix}${width}&`;
  }
  // trim the end
  resultPath = resultPath.replace(/[&?]+$/, "");

  return resultPath;
}

export function loaderForNextJS({ src, quality, width }: ImageLoaderProps) {
  return imageSizeAndQuality(src, quality, width);
}
