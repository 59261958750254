import { useEffect } from "react";
import type { PropsWithChildren } from "react";
import { AlertColor } from "@mui/material";
import cn from "classnames";
import Image from "next/legacy/image";
import { BlaceLogoSVG } from "@/src/asset";
import { ToastMessage } from "@/src/component/base";
import { useBreakPointDown, useToast } from "@/src/hook";
import { ImageFromCDN } from "@/src/util";
import styles from "./SplitLayout.module.scss";

function SplitLayout({ children }: PropsWithChildren<{}>) {
  const isMobile = useBreakPointDown("md");
  const toast = useToast();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [children]);

  return (
    <div className={styles.splitLayout}>
      <div className={styles.splitLayoutRightContainer}>
        {toast?.show && (
          <ToastMessage severity={toast.level?.toLowerCase() as AlertColor}>
            <>{toast.message}</>
          </ToastMessage>
        )}
        <div
          className={cn(
            styles.splitLayoutFormWrapper,
            toast.show ? "toast" : undefined
          )}
          suppressHydrationWarning={true}
        >
          {children}
        </div>
      </div>
      <div className={styles.splitLayoutImgContainer}>
        <div className={styles.splitLayoutImgWrapper}>
          <div className={styles.splitLayoutLogoWrapper}>
            <BlaceLogoSVG width={isMobile ? 200 : 400} />
          </div>
          <Image
            layout={"fill"}
            loader={ImageFromCDN.loaderForNextJS}
            className={styles.splitLayoutImg}
            objectFit={"cover"}
            priority={true}
            src={"https://blace-prod.imgix.net/blaceBackground1.jpeg"}
            alt={"Account View Background"}
          />
          <div className={styles.splitLayoutBgCover} />
        </div>
      </div>
    </div>
  );
}

export default SplitLayout;
