import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useGeneralInquiryForm } from "@/src/hook";
import { AuthLogic } from "@/src/model";
import { FormHelper } from "@/src/util";
import { BaseButton } from "@src/component/base";
import type { GeneralInquiryFormTypeFormik } from "@/src/hook";
import styles from "./GeneralInquiryForm.module.scss";

interface GeneralInquiryFormProps {
  onSuccess?: () => void;
}

function GeneralInquiryForm({ onSuccess }: GeneralInquiryFormProps) {
  const email = AuthLogic.listenAuthCurrentUser()?.email;
  const [emailAtInit, setEmailAtInit] = useState<string | undefined>(email);
  const inquiryForm = useGeneralInquiryForm();

  useEffect(() => {
    if (AuthLogic.getIdentityToken()) {
      const thisEmail = AuthLogic.getAuthCurrentUser()?.email;
      setEmailAtInit(thisEmail);
    }
  }, []);

  useEffect(() => {
    if (emailAtInit) {
      if (emailAtInit !== inquiryForm.formik.values.email) {
        const name = AuthLogic.getAuthCurrentUser()?.name;
        const hasName: boolean = (name ?? "").length > 2;
        inquiryForm.formik.setFieldValue("email", emailAtInit, !hasName);
        if (hasName) {
          inquiryForm.formik.setFieldValue("clientName", name, false);
        }
      }
    }
  }, [emailAtInit]);

  useEffect(() => {
    if (inquiryForm.saveComplete) {
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    }
  }, [inquiryForm.saveComplete]);

  function isDisabled(): boolean {
    if (
      inquiryForm.formik.values.clientName &&
      !FormHelper.formikCheckError<GeneralInquiryFormTypeFormik>(
        inquiryForm.formik,
        "clientName"
      ) &&
      inquiryForm.formik.values.email &&
      !FormHelper.formikCheckError<GeneralInquiryFormTypeFormik>(
        inquiryForm.formik,
        "email"
      ) &&
      inquiryForm.formik.values.eventName &&
      !FormHelper.formikCheckError<GeneralInquiryFormTypeFormik>(
        inquiryForm.formik,
        "eventName"
      ) &&
      inquiryForm.formik.values.notes &&
      !FormHelper.formikCheckError<GeneralInquiryFormTypeFormik>(
        inquiryForm.formik,
        "notes"
      )
    ) {
      return false;
    }
    return true;
  }

  return (
    <div className={styles.inquiryForm}>
      <form
        className={styles.inquiryFormContainer}
        onSubmit={inquiryForm.formik.handleSubmit}
      >
        <TextField
          label="Your Name"
          placeholder="How should we address you"
          fullWidth
          onChange={e => {
            inquiryForm.formik.setFieldValue(
              "clientName",
              e?.target?.value ?? ""
            );
          }}
          value={inquiryForm.formik.values.clientName ?? ""}
          helperText={FormHelper.formikErrorMessage<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "clientName"
          )}
          error={FormHelper.formikCheckError<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "clientName"
          )}
        />
        <TextField
          label="Contact Email"
          placeholder="The best email to reach you"
          fullWidth
          onChange={e => {
            inquiryForm.formik.setFieldValue("email", e?.target?.value ?? "");
          }}
          value={inquiryForm.formik.values.email ?? ""}
          helperText={FormHelper.formikErrorMessage<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "email"
          )}
          error={FormHelper.formikCheckError<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "email"
          )}
        />
        <TextField
          label="Phone Number"
          placeholder="The phone number to reach you"
          fullWidth
          type="number"
          onChange={e => {
            inquiryForm.formik.setFieldValue("phone", e?.target?.value ?? "");
          }}
          value={inquiryForm.formik.values.phone}
          helperText={FormHelper.formikErrorMessage<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "phone"
          )}
          error={FormHelper.formikCheckError<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "phone"
          )}
          inputProps={{ inputMode: "numeric" }}
        />
        <TextField
          label="Subject"
          placeholder="How can we help you?"
          fullWidth
          onChange={e => {
            inquiryForm.formik.setFieldValue(
              "eventName",
              e?.target?.value ?? ""
            );
          }}
          value={inquiryForm.formik.values.eventName}
          helperText={FormHelper.formikErrorMessage<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "eventName"
          )}
          error={FormHelper.formikCheckError<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "eventName"
          )}
        />
        <TextField
          label="Message"
          placeholder="What else should we know?"
          fullWidth
          multiline={true}
          rows={6}
          onChange={e => {
            inquiryForm.formik.setFieldValue("notes", e?.target?.value ?? "");
          }}
          value={inquiryForm.formik.values.notes}
          helperText={FormHelper.formikErrorMessage<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "notes"
          )}
          error={FormHelper.formikCheckError<GeneralInquiryFormTypeFormik>(
            inquiryForm.formik,
            "notes"
          )}
        />
        <BaseButton
          type="submit"
          variant="contained"
          color="secondary"
          loading={inquiryForm.isLoading}
          disabled={isDisabled()}
        >
          Contact Us
        </BaseButton>
      </form>
    </div>
  );
}

export default GeneralInquiryForm;
