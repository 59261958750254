import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import cn from "classnames";
import styles from "./Tag.module.scss";

interface TagProps {
  title: string;
  className?: string;
  onClose?: () => void;
}

function Tag({ title, className, onClose }: TagProps) {
  return (
    <div className={styles.tag}>
      <Typography
        variant="subtitle2"
        className={cn(styles.tagTitle, className)}
      >
        {title}
      </Typography>
      {typeof onClose === "function" && (
        <CloseIcon
          onClick={onClose}
          fontSize="inherit"
          cursor="pointer"
          data-testid="close-tag-icon"
        />
      )}
    </div>
  );
}

export default Tag;
