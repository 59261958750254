import type { ReactElement } from "react";
import { Typography } from "@mui/material";
import cn from "classnames";
import styles from "./DetailSectionInfo.module.scss";

interface DetailSectionInfoProps {
  title: string;
  Component: ReactElement;
  className?: string;
}

function DetailSectionInfo({
  title,
  Component,
  className,
}: DetailSectionInfoProps) {
  return (
    <section className={cn(styles.detailSectionInfo, className)}>
      {title && (
        <Typography
          variant="h4"
          color="grey.900"
          textTransform="uppercase"
          fontWeight="900"
        >
          {title}
        </Typography>
      )}
      <div>{Component}</div>
    </section>
  );
}

export default DetailSectionInfo;
