import { FeedbackClientData } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/FeedbackMessage/FeedbackMessage";
import { APP_TENANT, BLACE_V2_API } from "@/src/const";
import { BlaceV2Type } from "@/src/type";
import { HTTP, HTTPResponse } from "./HTTP";

export async function postSearchQuery(
  request: BlaceV2Type.AzureSearchQueryType.Request
): Promise<HTTPResponse<BlaceV2Type.InquiryResponseType>> {
  const action = new HTTP<BlaceV2Type.InquiryResponseType>(
    `${BLACE_V2_API}/event/inquiry/query`,
    HTTP.methods.POST,
    request
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getAllUserEvents(): Promise<
  HTTPResponse<BlaceV2Type.EventResponseType>
> {
  const action = new HTTP<BlaceV2Type.EventResponseType>(
    `${BLACE_V2_API}/event`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getInquiryLogs(
  inquiryId: string
): Promise<HTTPResponse<BlaceV2Type.InquiryLogsResponseType>> {
  const action = new HTTP<BlaceV2Type.InquiryLogsResponseType>(
    `${BLACE_V2_API}/event/inquiry/logs?inquiryId=${inquiryId}`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getInquiryLog(
  inquiryLogId: string
): Promise<HTTPResponse<BlaceV2Type.InquiryLogResponseType>> {
  const action = new HTTP<BlaceV2Type.InquiryLogResponseType>(
    `${BLACE_V2_API}/event/inquiry/logs/${inquiryLogId}`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function postInquiryFeedback(
  inquiryLogId: string,
  data: FeedbackClientData
): Promise<HTTPResponse<BlaceV2Type.InquiryLogResponseType>> {
  const action = new HTTP<BlaceV2Type.InquiryLogResponseType>(
    `${BLACE_V2_API}/event/inquiry/logs/${inquiryLogId}/feedback`,
    HTTP.methods.PUT,
    data
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}

export async function getEmailLogAttachment(
  inquiryLogId: string, blobName: string, download?: boolean
): Promise<HTTPResponse<BlaceV2Type.EmailLogAttachmentResponseType>> {
  const downloadQuery = download ? "?download" : "";
  const action = new HTTP<BlaceV2Type.EmailLogAttachmentResponseType>(
    `${BLACE_V2_API}/event/inquiry/logs/${inquiryLogId}/attachment/${blobName}/link${downloadQuery}`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}
