export function CapacityTheaterSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="26"
      viewBox="0 0 39 26"
      fill="none"
    >
      <path d="M39 7.98486V17.0592H36.8106V7.98486H39Z" fill="black" />
      <path
        d="M8.2833 2.32509C7.93133 4.69761 4.51331 4.14791 4.86528 1.77539C5.21646 -0.591797 8.63448 -0.0420973 8.2833 2.32509Z"
        fill="#1D39C4"
      />
      <path
        d="M16.1917 2.32509C15.8398 4.69761 12.4218 4.14791 12.7737 1.77539C13.1249 -0.591797 16.5429 -0.0420973 16.1917 2.32509Z"
        fill="#1D39C4"
      />
      <path
        d="M24.6275 2.32509C24.2756 4.69761 20.8575 4.14791 21.2095 1.77539C21.5607 -0.591797 24.9787 -0.0420973 24.6275 2.32509Z"
        fill="#1D39C4"
      />
      <path
        d="M33.0633 2.32509C32.7114 4.69761 29.2933 4.14791 29.6453 1.77539C29.9965 -0.591797 33.4145 -0.0420973 33.0633 2.32509Z"
        fill="#1D39C4"
      />
      <path
        d="M3.68198 9.62343C3.33001 11.996 -0.0880122 11.4463 0.263959 9.07373C0.615139 6.70654 4.03316 7.25624 3.68198 9.62343Z"
        fill="#1D39C4"
      />
      <path
        d="M11.5904 9.62343C11.2385 11.996 7.82044 11.4463 8.17241 9.07373C8.52359 6.70654 11.9416 7.25624 11.5904 9.62343Z"
        fill="#1D39C4"
      />
      <path
        d="M20.0262 9.62343C19.6742 11.996 16.2562 11.4463 16.6082 9.07373C16.9594 6.70654 20.3774 7.25624 20.0262 9.62343Z"
        fill="#1D39C4"
      />
      <path
        d="M28.462 9.62343C28.11 11.996 24.692 11.4463 25.044 9.07373C25.3952 6.70654 28.8132 7.25624 28.462 9.62343Z"
        fill="#1D39C4"
      />
      <path
        d="M3.68198 16.9218C3.33001 19.2943 -0.0880122 18.7446 0.263959 16.3721C0.615139 14.0049 4.03316 14.5546 3.68198 16.9218Z"
        fill="#1D39C4"
      />
      <path
        d="M11.5904 16.9218C11.2385 19.2943 7.82044 18.7446 8.17241 16.3721C8.52359 14.0049 11.9416 14.5546 11.5904 16.9218Z"
        fill="#1D39C4"
      />
      <path
        d="M20.0262 16.9218C19.6742 19.2943 16.2562 18.7446 16.6082 16.3721C16.9594 14.0049 20.3774 14.5546 20.0262 16.9218Z"
        fill="#1D39C4"
      />
      <path
        d="M28.462 16.9218C28.11 19.2943 24.692 18.7446 25.044 16.3721C25.3952 14.0049 28.8132 14.5546 28.462 16.9218Z"
        fill="#1D39C4"
      />
      <path
        d="M8.2833 24.2206C7.93133 26.5931 4.51331 26.0434 4.86528 23.6709C5.21646 21.3037 8.63448 21.8534 8.2833 24.2206Z"
        fill="#1D39C4"
      />
      <path
        d="M16.1917 24.2206C15.8398 26.5931 12.4218 26.0434 12.7737 23.6709C13.1249 21.3037 16.5429 21.8534 16.1917 24.2206Z"
        fill="#1D39C4"
      />
      <path
        d="M24.6275 24.2206C24.2756 26.5931 20.8575 26.0434 21.2095 23.6709C21.5607 21.3037 24.9787 21.8534 24.6275 24.2206Z"
        fill="#1D39C4"
      />
      <path
        d="M33.0633 24.2206C32.7114 26.5931 29.2933 26.0434 29.6453 23.6709C29.9965 21.3037 33.4145 21.8534 33.0633 24.2206Z"
        fill="#1D39C4"
      />
    </svg>
  );
}
