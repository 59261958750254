export function getAppliedFiltersAmount(filtersValues: any[]) {
  let appliedFiltersAmount = 0;

  for (let i = 0; i < filtersValues.length; i++) {
    if (!filtersValues[i]) {
      continue;
    }
    if (
      typeof filtersValues[i] === "object" &&
      !(filtersValues[i] instanceof Date)
    ) {
      Object.keys(filtersValues[i]).forEach(key => {
        if (filtersValues[i][key]) {
          ++appliedFiltersAmount;
        }
      });
    } else {
      ++appliedFiltersAmount;
    }
  }

  return appliedFiltersAmount;
}
