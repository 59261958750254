import { Theme } from "@mui/material/styles";

export type ThemeCssVarType = {
  vars: Array<{ propertyName: string; propertyValue: string }>;
  cssString?: string;
};

/**
 * Run recursively through all MUI colors to set as CSS :root vars
 *
 * @param {Theme} colors - a MUI theme with all the colors for the app
 * @returns {ThemeCssVarType}
 */
export function themeToRootCssVars(colors: Theme): ThemeCssVarType {
  const themeCssVars: ThemeCssVarType = {
    vars: [],
  };

  if (!colors?.palette) {
    return themeCssVars;
  }

  /**
   * parse the colors from the pallete and create all as css var
   */
  const colorKeys: Array<string> = Object.keys(colors.palette);
  for (const colorKey of colorKeys) {
    //@ts-ignore
    const colorKeyObject = colors.palette?.[colorKey];
    if (typeof colorKeyObject === "object") {
      const colorPropertyKeys = Object.keys(colorKeyObject);

      for (const colorPropertyKey of colorPropertyKeys) {
        themeCssVars.vars.push({
          propertyName: `--${colorKey}-${colorPropertyKey}`,
          propertyValue: colorKeyObject[colorPropertyKey],
        });
      }
    }
  }

  themeCssVars.cssString = transformObjToRootStyle(themeCssVars.vars);
  return themeCssVars;
}

export function transformObjToRootStyle(
  holder: { propertyName: string; propertyValue: string }[]
): string {
  let root = "";
  for (const obj of holder) {
    root += `${obj.propertyName}:${obj.propertyValue} !important;`;
  }

  return root;
}
