import { useEffect, useState } from "react";
import { URL_PARAMS } from "@/src/const";
import { useNextNavigation } from "./useNextNavigation";

type ToastType = {
  message: string;
  level: keyof typeof URL_PARAMS.TOAST.VALUES.TOAST_LEVEL;
  messageId: string;
};

type UseToastType = {
  show: boolean;
  message?: string;
  level?: keyof typeof URL_PARAMS.TOAST.VALUES.TOAST_LEVEL;
  messageId?: string;
};

export function useToast(): UseToastType {
  const [show, setShow] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastType | undefined>(undefined);
  const nextNavigation = useNextNavigation();

  useEffect(() => {
    if (!nextNavigation.searchParams) {
      return;
    }

    const params = new URLSearchParams(nextNavigation.searchParams.toString());
    if (!params.get(URL_PARAMS.TOAST.TOAST_MESSAGE)) {
      return;
    }

    setToast({
      messageId: params.get(URL_PARAMS.TOAST.TOAST_MESSAGE) ?? "",
      message:
        //@ts-ignore
        TOAST_MESSAGE_EXCHANGE[params.get(URL_PARAMS.TOAST.TOAST_MESSAGE)] ??
        "",
      //@ts-ignore
      level: params.get(URL_PARAMS.TOAST.TOAST_LEVEL) ?? "SUCCESS",
    });
    setShow(true);
  }, [nextNavigation.searchParams]);

  return {
    show,
    ...toast,
  };
}

//TODO: move this to the CMS
const TOAST_MESSAGE_EXCHANGE = {
  [URL_PARAMS.TOAST.VALUES.TOAST_MESSAGE.INQUIRY_COMPLETE]:
    "Your inquiry has been submitted! Please create a free account or log in to continue",
};
