export function getUTMValues(url?: string | null, referrer?: string | null) {
  if (typeof window === "undefined") {
    return {};
  }

  const search = url?.includes("?") ? url.split("?")[1] : undefined;
  const STORAGE_TRACKING_PARAMS = "STORAGE_TRACKING_PARAMS";
  const searchParams = new URLSearchParams(search ?? window.location.search);

  const newParamsExist: boolean =
    typeof searchParams.get("utm_medium") === "string" ||
    typeof searchParams.get("utm_source") === "string" ||
    typeof searchParams.get("utm_campaign") === "string" ||
    typeof searchParams.get("utm_term") === "string" ||
    typeof searchParams.get("utm_content") === "string" ||
    typeof searchParams.get("mLinkId") === "string" ||
    typeof searchParams.get("fbclid") === "string" ||
    typeof searchParams.get("gclid") === "string";

  const storedParams = localStorage.getItem(STORAGE_TRACKING_PARAMS);
  if (storedParams && !newParamsExist) {
    return JSON.parse(storedParams);
  }

  const params = {
    referrer: referrer ?? document.referrer,
    utmContent: searchParams.get("utm_content"),
    utmTerm: searchParams.get("utm_term"),
    utmMedium: searchParams.get("utm_medium"),
    utmSource: searchParams.get("utm_source"),
    utmCampaign: searchParams.get("utm_campaign"),
    marketingLinkId: searchParams.get("mLinkId"),
    googleCLID: searchParams.get("gclid"),
    facebookCLID: searchParams.get("fbclid"),
  };
  localStorage.setItem(STORAGE_TRACKING_PARAMS, JSON.stringify(params));
  return params;
}
