/* eslint-disable no-console */
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import { KEYS } from "@src/const";
import { DataDogLogger } from "./DatadogLogger";

const dataDogLogger: any = DataDogLogger.createLogger(
  SharedConfigManager.getValue(KEYS.DATADOG_TOKEN),
  SharedConfigManager.getValue(KEYS.ENV) ?? "missing",
  SharedConfigManager.getValue(KEYS.APP_NAME) ?? "blace-datadog-logger"
);

const ERROR = {
  BG_COLOR: "#8b0000",
  COLOR: "#FFFFFF",
};

const STATE_CHANGE = {
  BG_COLOR: "#0A1172",
  COLOR: "#FFFFFF",
};

const BASE_LOG = {
  BG_COLOR: "#000000",
  COLOR: "#32CD32",
};

const WARN = {
  BG_COLOR: "#FEC20C",
  COLOR: "#000000",
};

export const LOG_COLORS = {
  STATE_CHANGE,
  ERROR,
  BASE_LOG,
  WARN,
};

export enum LogLevel {
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  FATAL = "FATAL",
}

export function logToConsole(
  fileName: string,
  groupName: string,
  events: any[] = [],
  backgroundColor: string = BASE_LOG.BG_COLOR,
  textColor: string = BASE_LOG.COLOR
) {
  console.group(
    `%c(${fileName}): ${groupName.toUpperCase()}`,
    `background: ${backgroundColor}; color: ${textColor}`
  );
  if (Array.isArray(events)) {
    for (const opt of events) {
      console.log(opt);
    }
  }
  console.groupEnd();
}

export function logToConsoleDebug(
  fileName: string,
  groupName: string,
  events: any[] = [],
  backgroundColor: string = BASE_LOG.BG_COLOR,
  textColor: string = BASE_LOG.COLOR
) {
  //todo: consider a key/value state manager, move local storage check to a util function
  let debug: boolean = SharedConfigManager.getValue(KEYS.DEBUG_DEV_MODE);
  if (typeof localStorage !== "undefined") {
    const hasValue = localStorage.getItem(KEYS.DEBUG_DEV_MODE);
    if (hasValue) {
      debug = hasValue === "true";
    }
  }

  if (debug) {
    logToConsole(fileName, groupName, events, backgroundColor, textColor);
    return;
  }
}

export async function logToDataDog(
  level: LogLevel,
  fileName: string,
  groupName: string,
  events: any[] = [],
  message: string | undefined = undefined
) {
  const isError = level === LogLevel.ERROR || level === LogLevel.FATAL;
  const backgroundColor = isError ? ERROR.BG_COLOR : undefined;
  const textColor = isError ? ERROR.COLOR : undefined;
  logToConsoleDebug(fileName, groupName, events, backgroundColor, textColor);

  const toLog: any = {};
  if (Array.isArray(events)) {
    let i = 0;
    for (const opt of events) {
      toLog[`event${i}`] = opt;
      i++;
    }
  }

  const appName = SharedConfigManager.getValue(KEYS.APP_NAME);
  const log = {
    ddsource: appName,
    ddtags: `env:${SharedConfigManager.getValue(KEYS.ENV)},app:${appName}`,
    message: message ?? groupName,
    level: level,
    data: {
      ...toLog,
      authId: null,
    },
    service: appName,
    locationInApp: fileName,
    authId: null,
  };

  try {
    dataDogLogger.log(message ?? groupName, log);
  } catch (err) {}
}
