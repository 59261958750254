//https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions.gestureHandling
//https://tomchentw.github.io/react-google-maps/#googlemap
import React, { useEffect, useState } from "react";
import ZoomInMapTwoToneIcon from "@mui/icons-material/ZoomInMapTwoTone";
import {
  AdvancedMarker,
  APIProvider,
  Map as GoogleMap,
} from "@vis.gl/react-google-maps";
import { BaseButton } from "@/src/component/base";
import { ASSET_URL, KEYS, LA, NYC } from "@/src/const";
import { useBreakPointDown } from "@/src/hook";
import { BlaceV2API, External } from "@/src/service";
import { SharedConfigManager } from "@/src/util";
import styles from "./Map.module.scss";

const DEFAULT_LOCATION = {
  NYC: {
    lat: 40.748817,
    lng: -73.985428,
    states: ["NY", "NJ", "CT", "MA", "PA", "NH", "VT", "DE", "MD"],
  },
  LA: {
    lat: 34.058605,
    lng: -118.413124,
    states: ["CA", "WA", "OR", "NV", "AZ", "UT", "CO", "ID", "MT", "WY", "NM"],
  },
};

export type MapLocationMarker = {
  lat: number;
  lng: number;
  to?: string;
};

interface Props {
  markers?: MapLocationMarker[];
  centerMarker?: MapLocationMarker;
  markerOnClick?: (marker: MapLocationMarker) => void;
  hideMapButton?: boolean;
  handleMapToggle?: () => void;
  userMarkers?: MapLocationMarker[];
  visible?: boolean;
  defaultZoom?: number;
  additionalOptions?: google.maps.MapOptions;
  city?: string;
}

function Map({
  markers,
  centerMarker,
  hideMapButton,
  defaultZoom,
  visible,
  additionalOptions,
  city,
  markerOnClick,
  handleMapToggle,
}: Props) {
  const isMobile = useBreakPointDown("md");
  const [showMap, setShowMap] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(defaultZoom ?? 13);
  const [zoomLoaded, setZoomLoaded] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<MapLocationMarker | undefined>(
    centerMarker
  );

  useEffect(() => {
    if (zoomLoaded || defaultZoom) {
      return;
    }

    if (isMobile) {
      setZoom(11);
      setZoomLoaded(true);
    }
  }, [isMobile, defaultZoom]);

  /**
   * This prevents the component from loading immediately when the map is not needed
   */
  useEffect(() => {
    if (visible) {
      setShowMap(true);
    }
  }, [visible]);

  /**
   * use google geo location to approximate user location
   * this is not perfect and use cell towers / wifi to find location
   */
  useEffect(() => {
    if (!visible || coordinates?.lat || city) {
      return;
    }

    External.GoogleMapsAPI.geoLocate().then(async (response: any) => {
      if (!response.failure) {
        const reverseGeoCodeResponse =
          await BlaceV2API.GoogleServiceV2.getGoogleReverseGeoCode(
            response.location.lat,
            response.location.lng
          );

        //TODO: consider a more type safe approach for this
        if ((reverseGeoCodeResponse?.body?.payload?.results ?? []).length > 0) {
          const result = reverseGeoCodeResponse?.body?.payload?.results[0];
          for (const addressComponent of result.address_components) {
            if (
              DEFAULT_LOCATION.NYC.states.includes(addressComponent.short_name)
            ) {
              setCoordinates(DEFAULT_LOCATION.NYC);
              return;
            }

            if (
              DEFAULT_LOCATION.LA.states.includes(addressComponent.short_name)
            ) {
              setCoordinates(DEFAULT_LOCATION.LA);
              return;
            }
          }
        }
      }

      //fallback to NYC if not other coordinates set
      setCoordinates(DEFAULT_LOCATION.NYC);
    });
  }, [visible, city, coordinates]);

  useEffect(() => {
    if (city === LA) {
      setCoordinates(DEFAULT_LOCATION.LA);
    } else if (city === NYC) {
      setCoordinates(DEFAULT_LOCATION.NYC);
    }
  }, [city]);

  const handleMarkerOnClick = (marker: MapLocationMarker) => {
    if (typeof markerOnClick === "function") {
      markerOnClick(marker);
    }
  };

  if (!coordinates || !showMap) {
    return <></>;
  }

  return (
    <APIProvider apiKey={SharedConfigManager.getValue(KEYS.GOOGLE_MAPS_KEY)}>
      {typeof handleMapToggle === "function" && !hideMapButton && (
        <div className={styles.mapTurnOnOffBttnContainer}>
          <div className={styles.mapTurnOnOffBttnInner}>
            <BaseButton
              loading={false}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleMapToggle}
              sx={{
                display: "flex",
                gap: "6px",
                /*
                backgroundColor: "var(--common-white)",
                "&:hover": {
                  backgroundColor: "var(--common-white)",
                  opacity: ".98"
                }
                */
              }}
            >
              <ZoomInMapTwoToneIcon /> Hide Map
            </BaseButton>
          </div>
        </div>
      )}
      <GoogleMap
        defaultCenter={coordinates}
        defaultZoom={zoom}
        mapId="locationsMap"
        className={styles.detailsMap}
        style={{ width: "100%", height: "100%" }}
        gestureHandling={"cooperative"}
        disableDefaultUI
        zoomControl
        scrollwheel={true}
        streetViewControl={false}
        {...additionalOptions}
      >
        {(markers ?? []).map((marker, m) => (
          <AdvancedMarker
            key={m}
            position={marker}
            aria-label="View Venue"
            onClick={() => handleMarkerOnClick(marker)}
          >
            <img
              src={`${ASSET_URL}/blacePinLogo.png?auto=format&w=40&q=100`}
              alt="map marker"
            />
          </AdvancedMarker>
        ))}
      </GoogleMap>
    </APIProvider>
  );
}

export default React.memo(Map);
