//https://developer.mozilla.org/en-US/docs/Web/API/URL_Pattern_API
import { forwardRef, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, IconButton, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import UrlPattern from "url-pattern";
import { StrapiCMSAPI } from "@/src/service";
import { StrapiCMSType } from "@/src/type";
import { StorageHelper } from "@/src/util";
import styles from "./DataCapture.module.scss";
import { DataCaptureSwitch } from "./DataCaptureSwitch";

const DATA_CAPTURE_STORAGE = "DATA_CAPTURE_STORAGE";

type DataCaptureItem = {
  type: string;
  lastShown: number;
  complete: boolean;
};

type DataCaptureStorage = Record<string, DataCaptureItem>;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DataCapture() {
  const [allowTrigger, setAllowTrigger] = useState<
    undefined | StrapiCMSType.DataCaptureSettings
  >(undefined);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [didTrigger, setDidTrigger] = useState<boolean>(false);

  //TODO: allow the did trigger to reset on the page router
  //TODO: (i.e. this won't pop up twice if pages change right now)
  //TODO: don't allow contact capture to occur if user is signed in
  useEffect(() => {
    (async () => {
      const dataCaptureResponse: StrapiCMSType.DataCaptureResponse | undefined =
        await (
          await StrapiCMSAPI.getDataCapture()
        ).response;
      const data: DataCaptureStorage =
        ((await StorageHelper().getFromCache(
          DATA_CAPTURE_STORAGE
        )) as DataCaptureStorage) ?? {};

      if (dataCaptureResponse?.data?.[0]) {
        for (const dataCapture of dataCaptureResponse?.data) {
          //TODO: should refind this logic based on lastShow and current timestamp
          if (!data[dataCapture.attributes.settings.dataType]) {
            const pattern = new UrlPattern(
              dataCapture.attributes.settings.path
            );
            const match = pattern.match(window.location.href);
            if (match) {
              setAllowTrigger(dataCapture.attributes);
              break;
            }
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!allowTrigger || didTrigger) {
      return;
    }

    function handleScroll() {
      const midWindowHeight = window.innerHeight / 2;
      if (
        window.scrollY >
        (allowTrigger?.settings.triggerAfterScrollToPixel ?? midWindowHeight)
      ) {
        setDidTrigger(true);
        setOpenDialog(true);
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [didTrigger, allowTrigger]);

  async function handleClose(complete?: boolean) {
    if (!allowTrigger?.settings.dataType) {
      setOpenDialog(false);
      return;
    }

    const data: DataCaptureStorage =
      ((await StorageHelper().getFromCache(
        DATA_CAPTURE_STORAGE
      )) as DataCaptureStorage) ?? {};
    data[allowTrigger?.settings.dataType] = {
      type: allowTrigger?.settings.dataType,
      lastShown: Date.now(),
      complete: !!complete,
    };

    StorageHelper().setToCache(DATA_CAPTURE_STORAGE, data);
    setOpenDialog(false);
  }

  function handleBackgroundExit() {
    if (
      allowTrigger?.settings.exitPolicy === "showExitButton" ||
      allowTrigger?.settings.exitPolicy === "exitByClickingBackground"
    ) {
      handleClose(false);
    }
  }

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      onClose={handleBackgroundExit}
    >
      <DialogContent>
        {allowTrigger?.settings.exitPolicy === "showExitButton" && (
          <div className={styles.dataCaptureExitContainer}>
            <div className={styles.dataCaptureExitInner}>
              <IconButton color="inherit" onClick={() => handleClose(false)}>
                <CloseIcon color="inherit" />
              </IconButton>
            </div>
          </div>
        )}
        <DataCaptureSwitch
          dataType={allowTrigger?.settings.dataType}
          handleClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}

export default DataCapture;
