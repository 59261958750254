import * as ReactDeviceDetect from "react-device-detect";
import { KEYS } from "@/src/const";
import { ActivityLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import { UTMHelper } from "@/src/util/helper";
import { uniqueId } from "@/src/util/uuid";
import { HTTP } from "./HTTP";

/**
 * Post activity data to the database
 * @returns {void}
 */
export async function postActivity(
  event: BlaceV2Type.ActivityEvent,
  overrides?: {
    sessionId?: string;
    applicationVersion?: string;
    url?: string | null;
    referrer?: string | null;
  }
) {
  const BLACE_V2_API: string = SharedConfigManager.getValue(KEYS.BLACE_V2_API);
  const APP_TENANT: string = SharedConfigManager.getValue(KEYS.APP_TENANT);

  let localTimeZone: string | undefined = undefined;
  let localTime: string | undefined = undefined;
  try {
    localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    localTime = new Date().toLocaleString();
  } catch (err) {
    /*this error does not matter*/
  }

  let locationData: BlaceV2Type.ActivityGeoLocation | undefined = undefined;
  try {
    locationData = await ActivityLogic.getGeoLocation();
  } catch (err) {
    /*this error does not matter*/
  }

  //make sure and actionId and actionIdType is always assigned
  const mergeEvent: BlaceV2Type.ActivityEvent = !event?.actionId
    ? { ...event, ...{ actionId: uniqueId(), actionIdType: "uuid" } }
    : { ...event, ...(event.actionIdType ? {} : { actionIdType: "missing" }) };

  //b/c of next make sure the window is available
  const windowCheck: any = typeof window !== "undefined" ? window : {};

  function getPathname(url?: string | null) {
    if (!url) {
      return;
    }
    const pathAndQuery = url.split(".com");
    if (!pathAndQuery?.[1]) {
      return;
    }
    return (pathAndQuery[1] ?? "").split("?")[0];
  }

  const payload: BlaceV2Type.Activity = {
    isEmployee: ActivityLogic.isEmployee(),
    allEnvId: APP_TENANT ?? "",
    sessionId: overrides?.sessionId ?? (await ActivityLogic.SESSION_ID()),
    persistentId: await ActivityLogic.PERSISTENT_ID(),
    url: overrides?.url ?? window.location.href,
    pathname: overrides?.url
      ? getPathname(overrides?.url)
      : window.location.pathname,
    applicationVersion: overrides?.applicationVersion ?? "web",
    browserName: ReactDeviceDetect.browserName,
    browserEngine: ReactDeviceDetect.engineName,
    browserEngineVersion: ReactDeviceDetect.engineVersion,
    browserVersion: ReactDeviceDetect.browserVersion,
    osName: ReactDeviceDetect.osName,
    osVersion: ReactDeviceDetect.osVersion,
    mobileBrand: ReactDeviceDetect.mobileVendor,
    mobileBrandModel: ReactDeviceDetect.mobileModel,
    appType: ReactDeviceDetect.isMobile ? "mobileWeb" : "desktopWeb",
    localTimeZone,
    localTime,
    latitude: locationData?.latitude,
    longitude: locationData?.longitude,
    city: locationData?.city,
    state: locationData?.state,
    country: locationData?.country,
    deviceWidth: windowCheck.innerWidth,
    deviceHeight: windowCheck.innerHeight,
    ...UTMHelper.getUTMValues(overrides?.url, overrides?.referrer),
    ...mergeEvent,
  };

  const action = new HTTP(
    `${BLACE_V2_API}/activity/event`,
    HTTP.methods.POST,
    payload
  );
  action.setAllEnvId(APP_TENANT);
  action.setAllowGuest(true);
  await action.execute();
}

/**
 * Post activity session data to the database
 * @returns {void}
 */
export async function postActivitySession(
  objectId: string,
  data: BlaceV2Type.ActivitySession
) {
  const BLACE_V2_API: string = SharedConfigManager.getValue(KEYS.BLACE_V2_API);
  const APP_TENANT: string = SharedConfigManager.getValue(KEYS.APP_TENANT);

  const action = new HTTP(
    `${BLACE_V2_API}/activity/${objectId}/session`,
    HTTP.methods.POST,
    data
  );
  action.setAllEnvId(APP_TENANT);
  action.setAllowGuest(true);
  action.execute();
}
