import { ReactElement } from "react";
import styles from "./FooterSocialButton.module.scss";

interface FooterSocialButtonProps {
  SocialLogo: ReactElement;
  href: string;
  socialNetwork: string;
}

function FooterSocialButton({ SocialLogo, href, socialNetwork }: FooterSocialButtonProps) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={styles.footerSocialButton}
      aria-label={`BLACE ${socialNetwork} Link`}
      id={`FooterSocialButton.${socialNetwork}`}
    >
      <div className={styles.footerSocialButtonIcon}>{SocialLogo}</div>
    </a>
  );
}

export default FooterSocialButton;
