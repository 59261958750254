import React from "react";
import { Paper, PaperProps } from "@mui/material";
import { DraggableEventHandler } from "react-draggable";
import { DraggableComponent } from "@/src/component/base/DraggableComponent";

interface DraggableComponentProps extends PaperProps {
  handleStop: () => void;
  handleDrag: DraggableEventHandler;
  dragId: string;
}

const DraggablePaper = ({
  handleStop,
  handleDrag,
  dragId,
  ...props
}: DraggableComponentProps) => {
  return (
    <DraggableComponent
      handleStop={handleStop}
      handleDrag={handleDrag}
      dragId={`#${dragId}`}
    >
      <Paper {...props} />
    </DraggableComponent>
  );
};

export default DraggablePaper;
