import { KEYS, STRAPI_API_TOKEN, STRAPI_API_URL } from "@/src/const";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";

export type StrapiApiService<T> = {
  success: boolean;
  status: number;
  response?: T;
  error?: any;
};

export async function get<T>(
  id?: string,
  locale?: string,
  filter?: string,
  deep: boolean = true
): Promise<StrapiApiService<T>> {
  // it's needed for SSR (Next.js)
  const strapiApiUrl =
    SharedConfigManager.getValue(KEYS.STRAPI_API_URL) ?? STRAPI_API_URL;
  const strapiApiToken =
    SharedConfigManager.getValue(KEYS.STRAPI_API_TOKEN) ?? STRAPI_API_TOKEN;

  const url = `${strapiApiUrl}/${id}?${
    deep ? "populate=deep&" : ""
  }locale=${locale ?? "en"}${filter ? `&${filter}` : ""}`;

  return await new Promise(async (resolve) => {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `bearer ${strapiApiToken}`,
      },
    })
      .then((response) => {
        const { status } = response;
        switch (status) {
          case 200:
            response.json().then((response) => {
              resolve({ success: true, status, response });
              return;
            });
            return;
          default:
            response.text().then((response) => {
              resolve({ success: false, status, error: response });
            });
            return;
        }
      })
      .catch((error) => {
        resolve({ success: false, status: 500, error: error.toString() });
      });
  });
}
