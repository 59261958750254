import { useEffect, useState } from "react";

export type StorageHelperResponse = {
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, val: string) => void;
  removeItem: (key: string) => void;
  getFromCache: (key: string) => Promise<any>;
  setToCache: (key: string, val: any, exp?: number) => void;
  clearFromCache: (key: string) => void;
  useLocalStorage: <T>(key: string, defaultValue: T) => [T, (val: T) => void];
};

export function StorageHelper(): StorageHelperResponse {
  async function getItem(key: string): Promise<string | null> {
    return await new Promise(async resolve => {
      resolve(localStorage.getItem(key));
      return;
    });
  }

  async function setItem(key: string, value: any) {
    return await new Promise(async resolve => {
      localStorage.setItem(key, value);
      resolve(true);
      return;
    });
  }

  async function removeItem(key: string) {
    return await new Promise(async resolve => {
      localStorage.removeItem(key);
      resolve(true);
      return;
    });
  }

  async function getFromCache(key: string) {
    const stored: string | null = await getItem(key);
    if (!stored) {
      return;
    }

    try {
      const cache = JSON.parse(stored);
      const { val, ts } = cache;
      return val && ts > Date.now() ? val : undefined;
    } catch (error) {
      removeItem(key);
      return;
    }
  }

  //exp default = 7 days
  async function setToCache(
    key: string,
    val: any,
    exp: number = 7 * 24 * 60 * 60 * 1000
  ) {
    return setItem(key, JSON.stringify({ val, ts: Date.now() + exp }));
  }

  //exp default = 7 days
  async function clearFromCache(key: string) {
    return await removeItem(key);
  }

  function useLocalStorage<T>(
    key: string,
    defaultValue: T
  ): [T, (val: T) => void] {
    const [value, setValue] = useState<any>(() => {
      const storedValue =
        typeof window !== "undefined" ? localStorage.getItem(key) : null;
      return storedValue === null ? defaultValue : storedValue;
    });

    useEffect(() => {
      const listener = (e: StorageEvent | CustomEvent) => {
        //@ts-ignore
        if (typeof window !== "undefined" && e && e.key === key) {
          //@ts-ignore
          setValue(e.newValue ?? e.detail?.newValue);
        }
      };
      window.addEventListener("storage", listener);

      return () => {
        window.removeEventListener("storage", listener);
      };
    }, [key, defaultValue]);

    const setValueInLocalStorage = (newValue: T) => {
      setValue((currentValue: T) => {
        const result =
          typeof newValue === "function" ? newValue(currentValue) : newValue;
        if (typeof window !== "undefined") {
          localStorage.setItem(key, result);
          window.dispatchEvent(
            new CustomEvent("storage", {
              detail: {
                key,
                newValue,
                storageArea: "localStorage",
              },
            })
          );
        }
        return result;
      });
    };

    return [value, setValueInLocalStorage];
  }

  return {
    getItem,
    setItem,
    removeItem,
    getFromCache,
    setToCache,
    clearFromCache,
    useLocalStorage,
  };
}
