import React from "react";
import styles from "./EmailLink.module.scss";

interface EmailLinkProps {
  email: string;
}

function EmailLink({ email }: EmailLinkProps) {
  return (
    <a className={styles.linkTo} href={`mailto:${email}`}>
      {email}
    </a>
  );
}

export default EmailLink;
