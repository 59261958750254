import TextsmsTwoToneIcon from "@mui/icons-material/TextsmsOutlined";
import { Divider, Typography } from "@mui/material";
import Link from "next/link";
import { BaseButton } from "@/src/component/base";
import { useCMS } from "@/src/component/provider";
import styles from "@/src/component/partial/Header/Header.module.scss";

export function Question() {
  const cms = useCMS();
  return (
    <div className={styles.questionsWrapper}>
      <Typography
        variant="caption"
        component="h3"
        className={styles.contactQuestionText}
      >
        Have a question?
      </Typography>
      <div className={styles.contactButtonHolder}>
        <Link
          href={cms.constructLink("/inquiry/general")}
          className={styles.linkButton}
        >
          <BaseButton variant="contained" className={styles.contactButton}>
            <TextsmsTwoToneIcon /> Contact a BLACE expert
          </BaseButton>
        </Link>
      </div>
      <Divider className={styles.drawerDivider} />
    </div>
  );
}
