interface Props {
  size: number;
  stroke: string;
  fill: string;
}

export function DressSVG({
  size = 22,
  fill = "currentColor",
  stroke = "currentColor",
}: Props) {
  return (
    <svg
      height={`${size}px`}
      version="1.1"
      viewBox="0 0 62 62"
      width={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />
      <defs />
      <g fillRule="evenodd" stroke={`${stroke}`} strokeWidth="5">
        <path
          d="M23,21.0001804 L23,19.0530923 L20,7.79452705 L20,0 L23,0 L23,5.25531758 C28,7.5 30,9.49108887 30,9.49108887 C30,9.49108887 32,7.5 37,5.25531758 L37,0 L40,0 L40,7.88297638 L37,19.0530923 L37,21.0001804 L56,53.4597213 C56,53.4597213 38.2332453,60 30,60 C20.9344916,60.0000011 4,53.4597213 4,53.4597213 L23,21.0001804 Z M23,21.0001804"
          fill={`${fill}`}
          id="dress"
        />
      </g>
    </svg>
  );
}
