import { PropsWithChildren, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { ToastMessage } from "@/src/component/base/ToastMessage";
import { AuthLogic } from "@/src/model";
import { DataCapture, Footer, Header } from "@src/component/partial";
import styles from "./PageLayout.module.scss";

interface Props {
  pageHasMobileFooter?: boolean;
  transparentBeforeScroll?: boolean;
}

function PageLayout({
  pageHasMobileFooter,
  transparentBeforeScroll,
  children,
}: PropsWithChildren<Props>) {
  const [userName, setUserName] = useState<string | undefined>("");
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (AuthLogic.hasAuthToken()) {
      const name = AuthLogic.getAuthCurrentUser()?.name;
      const isAdmin = AuthLogic.getAuthCurrentUser()?.is_admin;

      setUserName(name);
      setIsAdmin(isAdmin);
    }
  }, []);

  return (
    <>
      <DataCapture />
      <Header transparentBeforeScroll={transparentBeforeScroll} />
      {userName && isAdmin ? (
        <div className={styles.bannerSticky}>
          <ToastMessage severity="warning">
            <Typography variant="h5">{`You are logged in as ${userName}`}</Typography>
          </ToastMessage>
        </div>
      ) : null}
      <main className={styles.pageLayoutBody}>{children}</main>
      <Footer pageHasMobileFooter={pageHasMobileFooter} />
    </>
  );
}

export default PageLayout;
