import React from "react";

export function InquiryChangedSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#FFF6D7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 6.66634C9.05464 6.66634 6.66683 9.05416 6.66683 11.9997C6.66683 14.9452 9.05464 17.333 12.0002 17.333C14.9457 17.333 17.3335 14.9452 17.3335 11.9997C17.3335 9.05416 14.9457 6.66634 12.0002 6.66634ZM5.3335 11.9997C5.3335 8.31778 8.31826 5.33301 12.0002 5.33301C15.6821 5.33301 18.6668 8.31778 18.6668 11.9997C18.6668 15.6816 15.6821 18.6663 12.0002 18.6663C8.31826 18.6663 5.3335 15.6816 5.3335 11.9997ZM12.0002 8.66634C12.3684 8.66634 12.6668 8.96482 12.6668 9.33301V12.6663C12.6668 13.0345 12.3684 13.333 12.0002 13.333C11.632 13.333 11.3335 13.0345 11.3335 12.6663V9.33301C11.3335 8.96482 11.632 8.66634 12.0002 8.66634ZM12.6668 14.9997C12.6668 14.6315 12.3684 14.333 12.0002 14.333C11.632 14.333 11.3335 14.6315 11.3335 14.9997V15.333C11.3335 15.7012 11.632 15.9997 12.0002 15.9997C12.3684 15.9997 12.6668 15.7012 12.6668 15.333V14.9997Z"
        fill="#F0A506"
      />
    </svg>
  );
}
