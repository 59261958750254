import { AuthLogic } from "@/src/model";
import { AuthType, BlaceV1Type } from "@/src/type";
import { HTTP } from "./";

export async function getProfile(token?: string): Promise<
  | BlaceV1Type.V1ServiceResponse<AuthType.LoginResponse>
  | undefined
> {
  if (!token && !AuthLogic.hasAuthToken()) {
    return;
  }

  return await HTTP.v1Client<AuthType.LoginResponse>(
    "/user/profile",
    undefined,
    "GET",
    {
      Authorization: `Bearer ${token ?? AuthLogic.getIdentityToken()}`,
    }
  );
}
