import type { PropsWithChildren } from "react";
import cn from "classnames";
import Image from "next/legacy/image";
import { ImageFromCDN } from "@src/util";
import type { ImageLoader } from "next/image";
import styles from "./ImgResponsive.module.scss";

export interface ImageResponsiveProps {
  immediateLoad?: boolean | undefined;
  objectFit?: "cover" | "contain";
  loader?: ImageLoader;
  className?: string;
  imgClassName?: string;
  src: string;
  alt: string;
  id?: string;
  quality?: number;
  width?: number;
  onClick?: () => void;
}

/**
 * A base component for handling responsive images and lazy loading with NextJS
 *
 * @component ImgResponsive
 * @interface ImageResponsiveProps
 * @property {boolean | undefined} immediateLoad - when true this will load the image immediately, when undefined defaults to true, when false will lazy load
 * @property {"cover" | "contain"} objectFit - when the object fit should cover or contain, the default is cover
 * @property {ImageLoader} loader - [OPTIONAL] an image CDN loader;
 * @property {string} className - [OPTIONAL] a CSS class to for the outer conainer of the image;
 * @property {string} imgClassName - [OPTIONAL] a CSS class to for the image;
 * @property {string} src - the image link;
 * @property {string} alt - the alternate text that represents what the image is;
 * @property {string} id - an id for the element rendering the image
 * @property {number} quality - a number representing the % of image desired 100% = best quality
 * @property {numbebr} width - the width of the image in pixels
 * @property {()=>void} onClick - an function that will be executed on the click of the image
 * @returns
 */
export function ImgResponsive({
  className,
  immediateLoad,
  objectFit,
  loader,
  children,
  imgClassName,
  src,
  alt,
  id,
  quality,
  width,
  onClick,
}: PropsWithChildren<ImageResponsiveProps>) {
  //use the cdn when available
  const loaderProps = { loader: loader ?? ImageFromCDN.loaderForNextJS };

  return (
    <div
      className={cn(styles.image, styles.imgResponsive, className)}
      onClick={onClick}
    >
      <Image
        layout="fill"
        {...loaderProps}
        className={cn(
          styles.imgResponsiveContain,
          (objectFit ?? "cover") === "cover"
            ? styles.imgResponsiveCover
            : styles.imgResponsiveContain,
          imgClassName
        )}
        objectFit={objectFit ?? "cover"}
        priority={immediateLoad ?? true}
        src={src}
        alt={alt}
        id={id}
        quality={quality}
        width={width}
      />
      {children}
    </div>
  );
}
