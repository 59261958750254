import { ContactCapture } from "@/src/component/partial/ContactCapture";
import { StrapiCMSType } from "@/src/type";

interface DataCaptureSwitchProps {
  dataType?: StrapiCMSType.Partial.DataCapture["dataType"];
  handleClose: (complete?: boolean) => void;
}

export function DataCaptureSwitch({
  dataType,
  handleClose,
}: DataCaptureSwitchProps) {
  switch (dataType) {
    case "ContactCapture":
      return <ContactCapture handleClose={handleClose} />;
  }
  return <div />;
}
