import React, { useMemo } from "react";
import styles from "./TextFieldHelperText.module.scss";

interface TextFieldHelperTextProps {
  value: string;
  minLength: number;
  maxLength: number;
}

function TextFieldHelperText({ 
  value, 
  minLength,
  maxLength
}: TextFieldHelperTextProps) {
  const textFieldMessage = useMemo(() => {
    if (!value.length) {
      return;
    }
    return `${minLength - value.length} characters remaining`;
  }, [value, minLength]);

  const messageClassName = useMemo(() => {
    return value.length < minLength || value.length > maxLength
      ? styles.error
      : styles.success;
  }, [value, minLength, maxLength]);

  if (value.length > maxLength) {
    return <span className={messageClassName}>Too many characters</span>;
  }

  if (minLength - value.length <= 0) {
    return <></>;
  }

  return <span className={messageClassName}>{textFieldMessage}</span>;
}

export default TextFieldHelperText;
