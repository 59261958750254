import { Divider, Typography } from "@mui/material";
import styles from "./InquiryForm.module.scss";

interface InquiryFormFooterProps {
  isExclusive?: boolean;
}

function InquiryFormFooter({ isExclusive }: InquiryFormFooterProps) {
  return (
    <>
      {isExclusive && (
        <>
          <Divider />
          <div className={styles.inquiryFormRespondsIn}>
            <Typography variant="h5">Responds within 2-3 hours M-F</Typography>
          </div>
        </>
      )}
    </>
  );
}

export default InquiryFormFooter;
