import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PASSWORD_REGEX } from "@/src/const";
import { BlaceV1API } from "@/src/service";
import type { FormikProps } from "formik";

export type ResetPasswordTypeFormik = {
  password: string;
  confirm: string;
};

export type ResetPasswordType = {
  isLoading: boolean;
  saveComplete: boolean;
  hasError?: string;
  formik: FormikProps<ResetPasswordTypeFormik>;
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 6 characters")
    .matches(
      PASSWORD_REGEX,
      "Your password must contain a capital letter, lower case letter, and number"
    )
    .required("A password is required"),
  confirm: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords don't match")
    .required("Confirm your password"),
});

export function useResetPassword(): ResetPasswordType {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveComplete, setSaveComplete] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string | undefined>(undefined);

  const formik = useFormik<ResetPasswordTypeFormik>({
    initialValues: {
      password: "",
      confirm: "",
    },
    validationSchema,
    onSubmit: async values => {
      setIsLoading(true);
      const response = await BlaceV1API.AuthService.postResetpassword(
        values.password
      );
      if (response.success) {
        setSaveComplete(true);
      } else {
        setHasError("Unable to send a reset password, please try again");
      }

      setIsLoading(false);
    },
  });

  /**
   * after the user starts changing the form remove the error
   */
  useEffect(() => {
    if (!hasError) {
      return;
    }

    function handleKeyDown() {
      setHasError(undefined);
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [hasError]);

  return {
    isLoading,
    saveComplete,
    hasError,
    formik,
  };
}
