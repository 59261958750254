import React, { MouseEvent, useEffect, useState } from "react";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { BaseButton } from "@/src/component/base/BaseButton";
import { KEYS } from "@/src/const";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import styles from "./CopyButton.module.scss";

type Props = {
  textToCopy: string;
  hasOnlyIcon?: boolean;
};

const CopyButton = ({ textToCopy, hasOnlyIcon = false }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToCliboard = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsCopied(true);
    navigator.clipboard.writeText(textToCopy);
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsCopied(false), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isCopied]);

  const copyText = isCopied ? "Copied" : "Copy";

  return (
    <BaseButton
      onClick={copyToCliboard}
      startIcon={
        isCopied ? (
          <DoneRoundedIcon />
        ) : (
          <img
            src={`${SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND)}/copyIcon.svg`}
            width="16"
            height="16"
            alt="copy icon"
          />
        )
      }
      className={styles.copyButton}
    >
      {!hasOnlyIcon && copyText}
    </BaseButton>
  );
};

export default CopyButton;
