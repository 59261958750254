import { SimilarCarousel } from "@/src/component/partial/SimilarCarousel";
import { useBreakPointDown } from "@/src/hook";
import { BlaceV2Type } from "@/src/type";
import DetailSectionInfo from "./DetailSectionInfo";
import styles from "./DetailSectionInfo.module.scss";

interface DetailSectionSimilarCarousel {
  title: string;
  slug: string;
  similarSearchItems: BlaceV2Type.SearchType.Search[];
}

function DetailSectionSimilarCarousel({
  title,
  slug,
  similarSearchItems,
}: DetailSectionSimilarCarousel) {
  const isLarge = useBreakPointDown("lg");
  const isMobile = useBreakPointDown("md");
  const isSmall = useBreakPointDown("sm");

  if (!slug) {
    return <div />;
  }

  return (
    <div className={styles.detailSimiliarSectionInfo}>
      <DetailSectionInfo
        title={title}
        Component={
          <SimilarCarousel
            classes={{
              container: styles.detailSimiliarCarousel,
              swiper: styles.detailSimiliarCarouselSwiper,
              buttonsContainer: styles.detailSimiliarCarouselButtonsContainer,
              swiperEmptySlot: isMobile
                ? undefined
                : styles.detailSimiliarCarouselEmptySlot,
            }}
            slidesPerView={isSmall ? 1.2 : isMobile ? 1.8 : isLarge ? 2.4 : 2.1}
            cards={similarSearchItems}
          />
        }
      />
    </div>
  );
}

export default DetailSectionSimilarCarousel;
