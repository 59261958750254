import type { PropsWithChildren } from "react";
import dynamic from "next/dynamic";
import { PageLoading } from "@/src/component/partial";
import type { RequireAuthProps } from "./RequireAuth";

const RequireAuthDyanmic = dynamic<PropsWithChildren<RequireAuthProps>>(
  () => import("@/src/component/partial/RequireAuth/RequireAuth"),
  {
    ssr: false,
    loading: () => <PageLoading />,
  }
);

function RequireAuthNoSSR({
  children,
  lastTokenIntervalTime,
}: PropsWithChildren<RequireAuthProps>) {
  return (
    //@ts-ignore //TODO: unsure why typescript does not like dynamic import
    <RequireAuthDyanmic lastTokenIntervalTime={lastTokenIntervalTime}>
      {children}
    </RequireAuthDyanmic>
  );
}

export default RequireAuthNoSSR;
