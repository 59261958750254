import { KEYS } from "@/src/const";
import { BlaceV1Type } from "@/src/type";
import { Log, SharedConfigManager } from "@/src/util";

export async function v1Client<T>(
  path: string,
  body: any,
  method: "GET" | "POST" = "POST",
  headers?: any
): Promise<BlaceV1Type.V1ServiceResponse<T>> {
  return await new Promise(async resolve => {
    const url = `${SharedConfigManager.getValue(KEYS.APP_API)}${path}`;
    const request = {
      method,
      body: method === "POST" ? JSON.stringify(body) : undefined,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
    };

    const returnResponse = (toResolve: BlaceV1Type.V1ServiceResponse<T>) => {
      Log.logToConsoleDebug("HTTP.ts", "API REQUEST", [url, toResolve]);
      if (
        !toResolve.success &&
        toResolve.status !== 404 &&
        toResolve.status !== 200
      ) {
        Log.logToDataDog(Log.LogLevel.ERROR, "HTTP.ts", "API REQUEST ERROR", [
          url,
          toResolve,
        ]);
      }
      resolve(toResolve);
    };

    try {
      fetch(url, request)
        .then(response => {
          const { status } = response;
          switch (status) {
            case 200:
              response
                .json()
                .then(response => {
                  returnResponse({ success: true, status, body: response });
                  return;
                })
                .catch(error => {
                  returnResponse({ success: false, status, error });
                });
              return;
            case 422:
              response.json().then(response => {
                returnResponse({ success: false, status, body: response });
                return;
              });
              return;
            default:
              response.text().then(response => {
                returnResponse({ success: false, status, error: response });
                return;
              });
              return;
          }
        })
        .catch(error => {
          returnResponse({ success: false, status: 500, error });
        });
    } catch (error: any) {
      returnResponse({ success: false, status: 599, error });
    }
  });
}
