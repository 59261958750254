import { APP_TENANT, BLACE_V2_API } from "@/src/const";
import { BlaceV2Type } from "@/src/type";
import { HTTP, HTTPResponse } from "./HTTP";

export async function getGuestToken(): Promise<
  HTTPResponse<BlaceV2Type.GuestTokenResponse>
> {
  const action = new HTTP<BlaceV2Type.GuestTokenResponse>(
    `${BLACE_V2_API}/guest/create`,
    HTTP.methods.POST
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}
