import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import cn from "classnames";
import { ImgResponsive } from "@/src/component/base";
import { CarouselImage } from "@/src/component/partial";
import { useBreakPointDown } from "@/src/hook";
import { ComponentType } from "@/src/type";
import styles from "./ImageGrid.module.scss";

interface ImageGridProps {
  images: ComponentType.Carousel.ImageType[];
  OverlayComponent?: ReactElement;
}

function ImageGrid({ images, OverlayComponent }: ImageGridProps) {
  const isMobile = useBreakPointDown("md");
  const isSmall = useBreakPointDown("sm");
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [openImageIndex, setOpenImageIndex] = useState<number>(0);
  const [startAutoPlay, setStartAutoPlay] = useState<boolean>(false);

  //disable backdrop, could switch to MUI dialog but this was easier for styling atm
  useEffect(() => {
    if (openImageDialog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
  }, [openImageDialog]);

  //start animation after page scroll or after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      setStartAutoPlay(true);
      window.removeEventListener("scroll", handleListener);
      clearTimeout(timeout);
    }, 5000);

    function handleListener() {
      setStartAutoPlay(true);
      window.removeEventListener("scroll", handleListener);
      clearTimeout(timeout);
    }

    window.addEventListener("scroll", handleListener, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleListener);
      clearTimeout(timeout);
    };
  }, []);

  function handleOpenImageDialog(url?: string) {
    let index = 0;
    for (const img of images) {
      if (img.url === url) {
        break;
      }
      index++;
    }
    setOpenImageIndex(index);
    setOpenImageDialog(true);
  }

  function handleOpenImageDialogByIndex(index?: number) {
    setOpenImageIndex(index ?? 0);
    setOpenImageDialog(true);
  }

  function handleCloseImageDialog() {
    setOpenImageDialog(false);
  }

  const numberOfImages = (images ?? []).length;
  const showSmallImages = numberOfImages > 2 || !isSmall;
  return (
    <div className={styles.imageGridContainer}>
      {OverlayComponent}
      {/* prettier-ignore */}
      <div
        className={cn(
          styles.imageGridDetailsGrid,
          numberOfImages === 3 && !isSmall && styles.imageGridDetailsGrid3Images,
          numberOfImages < 3 && styles.imageGridDetailsGrid1Image
        )}
      >
        <div className={styles.imageGridDetailsGridMain}>
          <CarouselImage
            images={images}
            autoPlay={startAutoPlay}
            imageClassName={styles.imageGridImageMain}
            imageOnClick={handleOpenImageDialogByIndex}
            navButtonsAlwaysVisible={!isMobile}
          />
          <noscript>
            <ImgResponsive
              className={styles.imageGridImageMain}
              src={images?.[0]?.url ?? ""}
              alt={"Image"}
              onClick={handleOpenImageDialog}
            />
          </noscript>
        </div>
        {showSmallImages && (
          <>
            {images?.[1]?.url && (
              <div
                className={styles.imageGridDetailsGridSecond}
                onClick={() => handleOpenImageDialog(images?.[1]?.url)}
              >
                <ImgResponsive
                  className={styles.imageGridImageSecond}
                  src={images?.[1]?.url ?? ""}
                  alt={"Image"}
                />
              </div>
            )}
            {images?.[2]?.url && (
              <div
                className={styles.imageGridDetailsGridThird}
                onClick={() => handleOpenImageDialog(images?.[2]?.url)}
              >
                <ImgResponsive
                  className={styles.imageGridImageSmall}
                  src={images?.[2]?.url ?? ""}
                  alt={"Image"}
                />
              </div>
            )}
            {images?.[3]?.url && !isSmall && (
              <div
                className={styles.imageGridDetailsGridFourth}
                onClick={() => handleOpenImageDialog(images?.[3]?.url)}
              >
                <ImgResponsive
                  className={styles.imageGridImageSmall}
                  src={images?.[3]?.url ?? ""}
                  alt={"Image"}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.imageGridCount}>
        <div
          className={styles.imageGridCountInner}
          onClick={() => handleOpenImageDialog()}
        >
          <Typography variant="body1" color="white">
            See all
          </Typography>
          <Typography variant="body1" color="white">
            {(images ?? []).length} photos
          </Typography>
        </div>
      </div>
      {openImageDialog && (
        <div className={styles.imageGridDialogContainer}>
          <div
            className={styles.imageGridDialogExit}
            onClick={handleCloseImageDialog}
          >
            <CloseIcon fontSize="large" sx={{ color: "#FFF" }} />
          </div>
          <div className={styles.imageGridDialogCarousel}>
            <CarouselImage
              index={openImageIndex}
              images={images}
              autoPlay={false}
              //imageClassName={styles.imageGridImageMain}
              imageClassName={styles.imageGridDialogImage}
              navButtonsAlwaysVisible={true}
              animation="slide"
              objectFit="contain"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGrid;
