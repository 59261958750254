export function getFormattedPhoneNumber(initialNumber: string) {
  const cleaned = ("" + initialNumber).replace(/\D/g, "");

  if (cleaned.length === 10) {
    const matchInitialNumber = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);

    if (matchInitialNumber) {
      let formattedNumber = "";
      if (matchInitialNumber[1])
        formattedNumber += "(" + matchInitialNumber[1] + ") ";
      if (matchInitialNumber[2]) formattedNumber += matchInitialNumber[2];
      if (matchInitialNumber[3]) formattedNumber += "-" + matchInitialNumber[3];
      return formattedNumber;
    }
  } else if (cleaned.length === 11) {
    const matchInitialNumber = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

    if (matchInitialNumber) {
      let formattedNumber = "";
      if (matchInitialNumber[1]) formattedNumber += matchInitialNumber[1] + "(";
      if (matchInitialNumber[2]) formattedNumber += matchInitialNumber[2] + ")";
      if (matchInitialNumber[3]) formattedNumber += " " + matchInitialNumber[3];
      if (matchInitialNumber[4]) formattedNumber += "-" + matchInitialNumber[4];
      return formattedNumber;
    }
  }
  return initialNumber;
}
