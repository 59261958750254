export function withCommas(x: string, trimCents: boolean = false, digitAfterDot: number|undefined = undefined): string {
  const defaultResult = "";
  if (x.length === 0) {
    return defaultResult;
  }

  const numberValue: number = Number(x);
  if (isNaN(numberValue)) {
    return defaultResult;
  }
  if (numberValue === 0) {
    return numberValue.toString();
  }

  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (trimCents || parts.length === 1) {
    return parts[0];
  }

  return parts[0] + "." + (digitAfterDot ? parts[1].substring(0, digitAfterDot) : parts[1]);
}

export function formatFormInputValue(x: string, trimCents: boolean = false, digitAfterDot: number|undefined = undefined): string {
  return withCommas(x.replace(/[^\d.]/g, ""), trimCents, digitAfterDot);
}

export function isNull(num?: number, replace?: number) {
  return num ?? replace;
}

export function isNullOrZero(num?: number, replace?: number) {
  return !num || num === 0 ? replace : num;
}

export function clearInputValue(value:string) {
  return value.replace(/[^\d.]/g, "");
}
