import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { KEYS } from "@/src/const";
import {
  useAuth,
  useNextNavigation,
  useSearchFilters,
  useWindow,
} from "@/src/hook";
import { ActivityLogic, AuthLogic, CMSLogic, SearchLogic } from "@/src/model";
import { StrapiCMSType } from "@/src/type";
import { URLHelper } from "@/src/util";
import type { AppContextSearchType, AppContextType } from "./AppContext";
import type { UseSearchFiltersProps } from "@/src/hook";
import { AppContext } from "./AppContext";

const LAST_LINK_ID = "LAST_LINK_ID";

interface WithAppProps extends UseSearchFiltersProps {
  locale?: string;
  disablePageTracking?: boolean;
  globalWebConfig: StrapiCMSType.GlobalWebConfigResponse | undefined;
  initGlobalSearch?: {
    searchTerm?: string;
    queryType?: "full" | "simple";
  };
}

function WithApp({
  children,
  initSearch,
  initGlobalSearch,
  locale,
  disablePageTracking,
  globalWebConfig,
}: PropsWithChildren<WithAppProps>) {
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const [search, setSearch] = useState<AppContextSearchType>({
    searchTerm: initGlobalSearch?.searchTerm ?? "",
    searchQueryType: initGlobalSearch?.queryType ?? "full",
    searchTermId: "",
  });
  const [trackingLoaded, setTrackingLoaded] = useState<boolean>(false);
  const auth = useAuth();
  const navigation = useNextNavigation();
  const searchFilters = useSearchFilters({ initSearch });

  /**
   * init social tracking / one-off tracking
   * this effect will only run on mount
   */
  useEffect(() => {
    if (disablePageTracking || trackingLoaded) {
      return;
    }

    if (globalWebConfig) {
      //GA
      if (globalWebConfig?.data?.attributes?.tracking?.googleAnalytics) {
        ActivityLogic.ga4InitConfig(
          globalWebConfig.data.attributes.tracking?.googleAnalytics
        );
      }
      //Ads
      if (globalWebConfig?.data?.attributes?.tracking?.googleAds) {
        ActivityLogic.ga4InitConfig(
          globalWebConfig.data.attributes.tracking?.googleAds
        );

        //Ads Conversion ID
        if (globalWebConfig?.data?.attributes?.tracking?.googleAdsConversion) {
          localStorage.setItem(
            KEYS.GOOGLE_ADS_CONVERSION_ID,
            globalWebConfig?.data?.attributes?.tracking?.googleAdsConversion
          );
        }
      }
      //Linkedin
      if (globalWebConfig?.data?.attributes?.tracking?.linkedinCoversionId) {
        localStorage.setItem(
          KEYS.LINKEDIN_COVERSION_ID,
          globalWebConfig?.data?.attributes?.tracking?.linkedinCoversionId
        );
      }
      //FB
      if (globalWebConfig?.data?.attributes?.tracking?.facebookPixel) {
        const user = AuthLogic.getAuthCurrentUser();
        ActivityLogic.facebookTracking(
          "init",
          globalWebConfig.data.attributes.tracking?.facebookPixel,
          {
            em: user?.email,
            fn: user?.name,
            ln: user?.name,
            external_id: user?.id,
            ph: user?.phone,
          }
        );
      }

      //indicate that tracking was loaded so that this doesn't trigger twice
      setTrackingLoaded(true);
    }

    //checking short link / redirect link
    const linkId = URLHelper.urlGetParameter("linkId");
    const lastLinkId = localStorage.getItem(LAST_LINK_ID);
    if (linkId && linkId !== lastLinkId) {
      localStorage.setItem(LAST_LINK_ID, linkId);
      ActivityLogic.toActivityService({
        action: "redirect",
        actionId: linkId,
        actionIdType: "linkId",
        actionMessage: window.location.href,
        locationInApp: "WithAppContact.tsx",
      });
    }
  }, [disablePageTracking, globalWebConfig, trackingLoaded]);

  /**
   * click tracking
   */
  useEffect(() => {
    if (disablePageTracking || !globalWebConfig) {
      return;
    }

    window.addEventListener("click", async (e: any) => {
      if (e?.target?.localName === "a" || e?.target?.localName === "button") {
        await ActivityLogic.toActivityService({
          action: "click",
          actionId: e?.target.id ?? e?.target?.innerText,
          actionIdType: e?.target?.localName,
          actionMessage: e?.target?.ariaLabel ?? e?.target?.innerText,
          data1Type: "x",
          data1Value: e?.target?.x,
          data2Type: "y",
          data2Value: e?.target?.y,
          locationInApp: "WithAppContact.tsx",
        });
      }
    });
  }, [disablePageTracking, globalWebConfig]);

  /**
   * init page tracking on each page change
   */
  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (!useWindow()?.location.href || disablePageTracking || !trackingLoaded) {
      return;
    }

    ActivityLogic.toActivityService({
      action: "pageLoad",
      locationInApp: "WithAppContext.tsx",
    });
    AuthLogic.checkAndClearAuthTokenWhenExpired();
    ActivityLogic.facebookTracking("track", "PageView");
  }, [useWindow()?.location.href, disablePageTracking, trackingLoaded]);

  /**
   * list to search params on url
   */
  useEffect(() => {
    const params = new URLSearchParams(navigation.searchParams.toString());
    const urlSearchTerm = params.get("searchTerm") ?? "";
    const toCheckSearchTerm = urlSearchTerm ? atob(urlSearchTerm) : "";
    if (
      toCheckSearchTerm === search.searchTerm ||
      (toCheckSearchTerm === "" && search.searchTerm === "")
    ) {
      return;
    }

    setSearch({
      searchTerm: atob(params.get("searchTerm") ?? "") ?? "",
      searchQueryType:
        params.get("searchQueryType") === "simple" ? "simple" : "full",
      userChange: search.userChange ?? toCheckSearchTerm !== "",
    });
  }, [navigation.searchParams, search]);

  function setSearchTermToParams(val: AppContextSearchType) {
    if (!val.searchTerm) {
      navigation.updateQueryStringBulk([
        { name: "searchTerm", value: "" },
        { name: "searchQueryType", value: "" },
        { name: "page", value: "1" },
      ]);
      return;
    }

    if (!navigation.router.pathname.includes("/d/[locale]/discover")) {
      const data = [
        { name: "searchTerm", value: btoa(val.searchTerm) },
        { name: "searchQueryType", value: val.searchQueryType ?? "full" },
        { name: "page", value: "1" },
        ...SearchLogic.constructQueryParams("searchBarQuery", {}),
      ];
      const params = new URLSearchParams();
      data.map(opt => params.set(opt.name, opt.value));

      /*
      window.open(CMSLogic.constructLink(
        `/discover?${params.toString()}`,
        locale ?? "en"
      ),"_self");
      */
      navigation.router.push(
        CMSLogic.constructLink(`/discover?${params.toString()}`, locale ?? "en")
      );
    } else {
      navigation.updateQueryStringBulk([
        { name: "searchTerm", value: btoa(val.searchTerm) },
        { name: "searchQueryType", value: val.searchQueryType ?? "full" },
        { name: "page", value: "1" },
      ]);
    }
  }

  return (
    <AppContext.Provider
      value={{
        searchFocus,
        setSearchFocus,
        ...search,
        setSearch: setSearchTermToParams,
        searchFilters,
        auth,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

function useApp(): AppContextType {
  return useContext(AppContext);
}

export { WithApp, useApp };
