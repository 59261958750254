import { decode } from "@mapbox/polyline";
import { KEYS } from "@/src/const";
import { SharedConfigManager } from "@/src/util";

/**
 * Get the "best guess" geolocation of the device from google
 *
 * @documentation https://developers.google.com/maps/documentation/geolocation/overview
 * @returns
 */
export async function geoLocate() {
  const url: string = `https://www.googleapis.com/geolocation/v1/geolocate?key=${SharedConfigManager.getValue(KEYS.GOOGLE_MAPS_KEY)}`;
  const response = await fetch(url, { method: "POST" });
  if (response.ok) {
    return await response.json();
  }
  return { failure: true };
}

/**
 * Get directions from google maps
 *
 * @param startLatitude
 * @param startLongitude
 * @param endLatitude
 * @param endLongitude
 * @returns
 */
export async function getDirections(
  startLatitude: number,
  startLongitude: number,
  endLatitude: number,
  endLongitude: number
): Promise<{ latitude: number; longitude: number }[]> {
  const url: string =
    "https://maps.googleapis.com/maps/api/directions/json" +
    `?origin=${startLatitude},${startLongitude}` +
    `&destination=${endLatitude},${endLongitude}` +
    `
    + &key=${SharedConfigManager.getValue(KEYS.GOOGLE_MAPS_KEY)}`;
  const response = await fetch(url, { method: "GET" });
  const json = await response.json();
  const points: any[] = decode(json.routes[0].overview_polyline.points);
  const coordinates = points.map(point => {
    return {
      latitude: point[0],
      longitude: point[1],
    };
  });
  return coordinates;
}

/**
 * Get the address for a latitude / longitude from google
 *
 * @documentation https://developers.google.com/maps/documentation/geocoding/requests-reverse-geocoding
 * @param latitude
 * @param longitude
 * @returns
 */
export async function getReverseGeoCoding(latitude: number, longitude: number) {
  const url: string = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${SharedConfigManager.getValue(KEYS.GOOGLE_MAPS_KEY)}`;
  const response = await fetch(url, { method: "POST" });
  if (response.ok) {
    const location = await response.json();
    return { success: true, location };
  }
  return { success: false };
}

export interface AddressComponents {
  address1: string;
  city: string;
  state: string;
  postalCode: string;
  placeId: string;
}

/**
 * Take an address result from google and parse into a usable address
 *
 * @param addressResult
 * @returns
 */
export function parseAddressComponents(
  addressResult: any
): AddressComponents | undefined {
  if (!addressResult || !Array.isArray(addressResult.address_components)) {
    return;
  }

  const obj: AddressComponents = {
    address1: "",
    city: "",
    state: "",
    postalCode: "",
    placeId: addressResult.place_id,
  };

  let streetNumber = "";
  let streetName = "";
  for (const component of addressResult.address_components) {
    if (Array.isArray(component.types)) {
      if (component.types.includes("street_number")) {
        streetNumber = component.short_name;
      }
      if (component.types.includes("route")) {
        streetName = component.short_name;
      } else if (component.types.includes("locality")) {
        obj.city = component.short_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        obj.state = component.short_name;
      } else if (component.types.includes("postal_code")) {
        obj.postalCode = component.short_name;
      }
    }
  }
  obj.address1 = `${streetNumber} ${streetName}`;

  return obj;
}
