import type { PropsWithChildren, ReactElement } from "react";
import cn from "classnames";
import styles from "./DetailSectionInfo.module.scss";
import DetailSectionInfoBody, {
  DetailSectionInfoBodyProps,
} from "./DetailSectionInfoBody";

interface DetailSectionInfoBodyIconProps extends DetailSectionInfoBodyProps {
  Icon: ReactElement;
}

function DetailSectionInfoBodyIcon({
  tag,
  elementType,
  strikeThrough,
  Icon,
  children,
}: PropsWithChildren<DetailSectionInfoBodyIconProps>) {
  return (
    <div
      className={cn(
        styles.detailSectionInfoBodyIconContainer,
        strikeThrough ? styles.diabledColor : styles.iconDefaults
      )}
    >
      {Icon}
      <DetailSectionInfoBody
        tag={tag}
        elementType={elementType}
        strikeThrough={strikeThrough}
      >
        {children}
      </DetailSectionInfoBody>
    </div>
  );
}

export default DetailSectionInfoBodyIcon;
