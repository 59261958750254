import React from "react";
import styles from "./DragBar.module.scss";

interface DragBarProps {
  dragId: string;
}

function DragBar({ dragId }: DragBarProps) {
  return (
    <div
      className={styles.dragBarContainer}
      id={dragId}
      data-testid="draggable-bar"
    >
      <span className={styles.dragBar} />
    </div>
  );
}

export default DragBar;
