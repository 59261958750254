import { List, Typography } from "@mui/material";
import { FooterLinkListItem } from "@/src/component/partial/Footer/component/FooterLinkListIem";
import { useCMS } from "@/src/component/provider";
import { Landing } from "@/src/type/strapiCMS/components/type";
import styles from "./FooterLandingSection.module.scss";

interface FooterLandingSectionProps {
  landingList?: Landing[];
}

function FooterLandingSection({ landingList }: FooterLandingSectionProps) {
  const cms = useCMS();

  if (!landingList?.length) {
    return <></>;
  }

  return (
    <div className={styles.landingSection}>
      <Typography variant="h3" className={styles.landingSectionTitle}>
        Discover BLACE
      </Typography>
      <List disablePadding className={styles.landingList}>
        {landingList.map(({ id, attributes }) => (
          <FooterLinkListItem
            key={id}
            href={cms.constructLink(`/s/${attributes.slug}`)}
            title={attributes.title}
            isFromLandingList={true}
          />
        ))}
      </List>
    </div>
  );
}

export default FooterLandingSection;
