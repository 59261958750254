import type { FormikProps } from "formik";

export function formikCheckError<T>(
  formik: FormikProps<T>,
  fieldName: string
): boolean {
  return (
    //@ts-ignore
    (Boolean(formik.values?.[fieldName]) ||
      //@ts-ignore
      Boolean(formik.touched?.[fieldName])) &&
    //@ts-ignore
    Boolean(formik.errors?.[fieldName])
  );
}

export function formikErrorMessage<T>(
  formik: FormikProps<T>,
  fieldName: string
): string {
  if (formikCheckError<T>(formik, fieldName)) {
    //@ts-ignore
    return typeof formik.errors?.[fieldName] === "string"
      ? //@ts-ignore
        formik.errors?.[fieldName]
      : "";
  }
  return "";
}
