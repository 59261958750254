import React from "react";
import { AlertColor, AlertProps, Alert as MuiAlert, useMediaQuery, useTheme } from "@mui/material";
import cn from "classnames";
import styles from "./ToastMessage.module.scss";

interface ToastMessageProps {
  severity: AlertColor;
  children: JSX.Element;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ToastMessage({ children, severity }: ToastMessageProps) {
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const getToastCustomStyles = (severity: string) => {
    const toCheck = (severity ?? "success").toLowerCase();
    switch (toCheck) {
      case "success":
        return cn([styles.commonAlertStyles, styles.alertCustomSuccessStyles]);
      case "error":
        return cn([styles.commonAlertStyles, styles.alertCustomErrorStyles]);
      default:
        return styles.commonAlertStyles;
    }
  };

  return (
    <Alert
      severity={(severity ?? "success").toLowerCase() as AlertColor}
      className={getToastCustomStyles(severity)}
      sx={{
        "& .MuiAlert-message": {
          display: "flex",
          flexDirection: matchDownSm ? "column" : "row",
          alignItems: matchDownSm ? "flex-start" : "center",
        },
      }}
    >
      {children}
    </Alert>
  );
}

export default ToastMessage;
