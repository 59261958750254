import { Search } from "@/src/type/blaceV2/search/SearchType";
import { StrapiMeta } from "./StrapiType";
import {
  StrapiAssetAttributes,
  StrapiAssetData,
} from "./components/StrapiAsset";

export interface StrapiFaqQuestion {
  id: string;
  question: string;
  answer: string;
}

export interface StrapiFaqCategories {
  id: string;
  name: string;
  questions: StrapiFaqQuestion[];
}

export enum TextVariants {
  Title = "title",
  Paragraph = "paragraph",
  NumberedList = "numbered list",
  BulletedList = "bulleted list",
}

export interface TextItemValue {
  id: number;
  entry: string;
}

export interface TextEntry {
  id: number;
  nameInternal: "Title";
  style: TextVariants;
  text: TextItemValue[]
}

export interface CatalogImageLinkEntry {
  id: number;
  title: string;
  backgroundImage: {
    data: {
      id: number;
      attributes: StrapiAssetAttributes;
    };
  };
  filters: CatalogFiltersList;
}

export interface CatalogLinkEntry {
  id: number;
  text: string;
  catalogFilters?: CatalogFiltersList;
}

export interface CatalogFiltersList {
  id: number;
  isExclusive?: CatalogFilterValue;
  dataType?: CatalogFilterValue;
  regions?: CatalogFilterValue;
  areas?: CatalogFilterValue;
  neighborhood?: CatalogFilterValue;
  vendorType?: CatalogFilterValue;
  venueType?: CatalogFilterValue;
  bestUsedFor?: CatalogFilterValue;
  amenities?: CatalogFilterValue;
  vendorFeatures?: CatalogFilterValue;
  vendorPricing?: CatalogFilterValue;
}

export interface CatalogFilterValue {
  id: number;
  type: string;
  values: string[];
}

export interface CatalogItem {
  id: number;
  slug: string;
}

export interface EntryComponent {
  // general properties
  id: number;
  title: string;
  subtitle: string;
  __component: string;
  // title properties
  backgroundImage?: {
    data: {
      id: number;
      attributes: StrapiAssetAttributes;
    };
  };
  // catalog items carousel properties
  catalogItems?: CatalogItem[];
  detailsLink?: CatalogLinkEntry;
  tags?: CatalogLinkEntry[];
  // catalog multi links properties
  catalogLinks?: CatalogLinkEntry[];
  // spotlight properties
  catalogItem?: string;
  backgroundHexColor?: string;
  catalogSearchItem?: Search;
  // faq properties
  categories?: StrapiFaqCategories[];
  // images banner properties
  images?: {
    data: StrapiAssetData[];
  };
  // freeText properties
  defaultText?: {
    id: number;
    values: TextEntry[];
  };
  showMoreText?: {
    id: number;
    values: TextEntry[];
  };
  // catalogThreeLinks properties
  catalogImageLinks?: CatalogImageLinkEntry[];
}

export interface ComponentData {
  data: {
    id: number;
    attributes: {
      createdAt: string;
      updatedAt: string;
      publishedAt: string | null;
      entry: EntryComponent[];
    };
  };
}

export interface Component {
  id: number;
  __component: string;
  published: boolean;
  data: ComponentData;
}

export interface LandingData {
  createdAt: string;
  publishedAt: string;
  slug: string;
  updatedAt: string;
  components: Component[];
}

export interface LandingPageType {
  success: boolean;
  status: number;
  meta: StrapiMeta;
  data: [
    {
      id: number;
      attributes: LandingData;
    }
  ];
}

export enum StrapiLandingPageComponents {
  Title = "landing.title",
  ImagesBanner = "landing.images-banner",
  CatalogItemsCarousel = "landing.catalog-items-carousel",
  CatalogThreeLinks = "landing.catalog-three-links",
  Spotlight = "landing.spotlight",
  FreeText = "landing.free-text",
  CatalogMultiColumnsLinks = "landing.catalog-multi-columns-links",
  FAQ = "landing.faq",
}
