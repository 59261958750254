import { useEffect, useState } from "react";
import { AuthLogic } from "@/src/model";
import { AuthType, StrapiCMSType } from "@/src/type";
import styles from "@/src/component/partial/Header/Header.module.scss";
import { Account } from "./Account";
import { BusinessDashboard } from "./BusinessDashboard";
import { EvenMore } from "./EvenMore";
import { Events } from "./Events";
import { Profile } from "./Profile";
import { Question } from "./Question";

export interface NavigationDrawerProps {
  headerLinks?: Array<StrapiCMSType.Base.Link>;
}
export interface CurrentUserProps {
  currentUser?: AuthType.LoginResponseProfile;
}

export function NavigationDrawer({ headerLinks }: NavigationDrawerProps) {
  const [currentUserData, setCurrentUserData] = useState<
    AuthType.LoginResponseProfile | undefined
  >(undefined);

  useEffect(() => {
    const currentUser = AuthLogic.getAuthCurrentUser();
    setCurrentUserData(currentUser);
  }, []);

  return (
    <div className={styles.drawerWrapper}>
      <Profile currentUser={currentUserData} />
      <Question />
      {/**TODO: need to address this with a common function to detect business user vs client user */}
      {currentUserData?.role !== "vendor" &&
        currentUserData?.role !== "landlord" && 
        currentUserData?.role !== "blace-sales" && (
          <Events currentUser={currentUserData} />
        )}
      {(currentUserData?.role == "vendor" ||
        currentUserData?.role == "landlord" ||
        currentUserData?.role == "blace-sales") && (
          <BusinessDashboard currentUser={currentUserData} />
        )}  
      <Account
        currentUser={currentUserData}
        setCurrentUserData={setCurrentUserData}
      />
      <EvenMore headerLinks={headerLinks} />
    </div>
  );
}
