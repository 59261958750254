import { useEffect, useState } from "react";
import { useNextNavigation } from "@/src/hook";
import { SearchLogic } from "@/src/model";
import { Log, uniqueId } from "@/src/util";

export interface UseSearchFiltersProps {
  initSearch?: {
    filters?: {
      regions?: string[];
      dataType?: string[];
    };
    searchId?: string;
  };
}

export const SEARCH_ID_INITIALIZE = "initialize";

export interface SearchFiltersType {
  getFilterData: <T>(property: string) => T;
  setFilterData: <T>(property: string, data: T) => void;
  setMultiChoice: (dataKey: string, val: string) => void;
  userChange: boolean;
  filterState: Record<string, any>;
  searchId: string;
}

function combineInitSearchFilters(
  searchParams: string,
  filters?: {
    regions?: string[];
    dataType?: string[];
  }
): Record<string, any> {
  const filterData = SearchLogic.deconstructQueryParams(searchParams);

  //override regions if data is present
  if (filters?.regions) {
    filterData.regions = filters?.regions;
  }

  //override dataType if data is present
  if (filters?.dataType) {
    filterData.dataType = filters?.dataType;
  }

  return filterData;
}

export function useSearchFilters({
  initSearch,
}: UseSearchFiltersProps): SearchFiltersType {
  const nextNavigation = useNextNavigation();
  const [userChange, setUserChange] = useState<boolean>(false);
  const [searchId, setSearchId] = useState<string>(
    initSearch?.searchId ?? SEARCH_ID_INITIALIZE
  );
  const [filterState, setFilterState] = useState<Record<string, any>>({
    ...combineInitSearchFilters(
      nextNavigation.searchParams.toString(),
      initSearch?.filters
    ),
  });

  function getFilterData<T>(property: string) {
    return filterState[property] as T;
  }

  function setFilterData(property: string, data: any) {
    if (!userChange) {
      setUserChange(true);
    }

    const clone = JSON.parse(JSON.stringify(filterState));
    clone[property] = data;

    if (
      data === "" ||
      data === undefined ||
      data === null ||
      (Array.isArray(data) && (data ?? []).length === 0)
    ) {
      delete clone[property];
    }

    nextNavigation.updateQueryStringBulk([
      ...SearchLogic.constructQueryParams(uniqueId(), clone),
    ]);
  }

  function setMultiChoice(dataKey: string, val: string) {
    const clone = (getFilterData(dataKey) as string[]) ?? [];
    let add = true;
    let i = 0;

    for (const opt of clone) {
      if (opt === val) {
        clone.splice(i, 1);
        add = false;
        break;
      }
      i++;
    }

    if (add) {
      clone.push(val);
    }

    setFilterData(dataKey, clone);
  }

  useEffect(() => {
    if (searchId !== SEARCH_ID_INITIALIZE && !userChange) {
      setUserChange(true);
    }
  }, [userChange, searchId]);

  useEffect(() => {
    const params = new URLSearchParams(nextNavigation.searchParams.toString());
    if (params.get("searchId") === searchId || !params.get("searchId")) {
      if (initSearch?.filters && !window.location.search) {
        setFilterState({
          ...combineInitSearchFilters(
            nextNavigation.searchParams.toString(),
            initSearch?.filters
          ),
        });

        if ((params.get("searchId") ?? "").length > 5) {
          setSearchId(params.get("searchId") ?? uniqueId());
        }
      }
      return;
    }

    setSearchId(params.get("searchId") ?? uniqueId());
    setFilterState(
      SearchLogic.deconstructQueryParams(nextNavigation.searchParams.toString())
    );
  }, [nextNavigation.searchParams, searchId, initSearch]);

  useEffect(() => {
    if (!nextNavigation.router.pathname.includes("/d/[locale]/discover")) {
      setUserChange(false);
      setFilterState({});
      setSearchId(SEARCH_ID_INITIALIZE);
    }
  }, [nextNavigation.router.pathname]);

  useEffect(() => {
    Log.logToConsoleDebug(
      "useSearchFilters.ts",
      "Search State Changed",
      [{ filterState, userChange }, { initSearch }],
      Log.LOG_COLORS.STATE_CHANGE.BG_COLOR,
      Log.LOG_COLORS.STATE_CHANGE.COLOR
    );
  }, [filterState, initSearch, userChange]);

  return {
    userChange,
    filterState,
    searchId,
    getFilterData,
    setFilterData,
    setMultiChoice,
  };
}
