import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface DropdownItem<T> {
  value: T;
  label: string;
}

interface DropdownProps<T> {
  /** the options in the drop down that will be selectable by the user */
  items: Array<DropdownItem<T>>;
  /** the type specific value choosen by the user or set by default */
  value: T;
  /** the handler function that executes when the user selects an option from the drop */
  onChange: (e: SelectChangeEvent<T>) => void;
  /** the name of the dropdown shown to the user */
  label: string;
  /** the unique id for html of the drop down */
  id: string;
  /** an [OPTIONAL] message to the user. when error=true then this will show as error */
  helperText?: string;
  /** an [OPTIONAL] value that indicates if the drop down has an error */
  error?: boolean;
  /** an [OPTIONAL] parameter indicating that this dropdown is required to be answered */
  required?: boolean;
}

const Dropdown = <T extends string | number>({
  items,
  value,
  onChange,
  label,
  helperText,
  error,
  required = false,
  id,
}: DropdownProps<T>) => {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select<T>
        label={label}
        labelId={`${id}-label`}
        value={value}
        onChange={onChange}
        required={required}
        id={id}
        data-testid="dropdown-select"
      >
        {items.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
/**
 * A base component to create a basic dropdown for a form
 *
 * @component Dropdown
 * @interface DropdownProps
 * @property {Array<DropdownItem<T>>} items - the options in the drop down that will be selectable by the user
 * @property {T} value - the handler function that executes when the user selects an option from the drop
 * @property {(value: T) => void} handleChange -
 * @property {string} label - the name of the dropdown shown to the user
 * @property {string} id - the unique id for html of the drop down
 * @property {string} helperText - an [OPTIONAL] message to the user. when error=true then this will show as error
 * @property {boolean} error - an [OPTIONAL] value that indicates if the drop down has an error
 * @property {boolean} required - an [OPTIONAL] parameter indicating that this dropdown is required to be answered
 */
export default Dropdown;
