import { Divider, Typography } from "@mui/material";
import Image from "next/image";
import EventsIcon from "@/public/icons/eventsIcon.svg";
import { B2B_AUTH_ENDPOINT, B2B_URL } from "@/src/const";
import { AuthLogic } from "@/src/model";
import styles from "@/src/component/partial/Header/Header.module.scss";
import { CurrentUserProps } from "./NavigationDrawer";

export function BusinessDashboard({ currentUser }: CurrentUserProps) {
  if (!currentUser || !AuthLogic.getIdentityToken()) {
    return null;
  }

  function goToB2BDashboard() {
    AuthLogic.b2bRedirect(
      AuthLogic.getAuthCurrentUser()?.role,
      undefined,
      B2B_URL,
      B2B_AUTH_ENDPOINT,
      AuthLogic.getIdentityToken() ?? ""
    );
  }

  return (
    <>
      <div className={styles.eventsWrapper}>
        <Typography component="h3" className={styles.title}>
          Business Dashboard
        </Typography>
        <div className={styles.accountLinkContent} onClick={goToB2BDashboard}>
          <Image
            src={EventsIcon}
            alt="events icon"
            className={styles.arrowOffsetRight}
          />
          My Dashboard
        </div>
      </div>
      <Divider className={styles.drawerDivider} />
    </>
  );
}
