export function PintrestSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.492 19.191C8.515 18.855 8.563 18.52 8.635 18.19C8.698 17.895 8.889 17.06 9.169 15.85L9.176 15.82L9.563 14.152C9.642 13.812 9.703 13.548 9.744 13.46C9.54982 13.0111 9.45302 12.5261 9.46 12.037C9.46 10.7 10.216 9.664 11.196 9.664C11.556 9.658 11.9 9.814 12.138 10.09C12.376 10.366 12.486 10.733 12.44 11.086C12.44 11.539 12.355 11.884 11.986 13.121C11.929 13.3107 11.874 13.5011 11.821 13.692C11.77 13.88 11.726 14.05 11.689 14.214C11.593 14.6 11.681 15.011 11.926 15.319C12.0448 15.4702 12.1982 15.5906 12.3733 15.6702C12.5483 15.7497 12.74 15.786 12.932 15.776C14.424 15.776 15.532 13.791 15.532 11.228C15.532 9.258 14.242 7.954 12.1 7.954C11.5638 7.93407 11.0293 8.02563 10.5303 8.22289C10.0313 8.42015 9.57864 8.71881 9.201 9.1C8.81526 9.48954 8.51094 9.95197 8.30577 10.4603C8.1006 10.9687 7.9987 11.5128 8.006 12.061C7.98114 12.6518 8.16211 13.2328 8.518 13.705C8.699 13.845 8.768 14.088 8.693 14.295C8.652 14.463 8.553 14.847 8.516 14.975C8.50553 15.0376 8.48065 15.0969 8.44335 15.1483C8.40604 15.1997 8.35731 15.2417 8.301 15.271C8.24633 15.2997 8.18561 15.315 8.12386 15.3155C8.06212 15.316 8.00114 15.3018 7.946 15.274C6.786 14.795 6.15 13.496 6.15 11.834C6.15 8.849 8.641 6.25 12.342 6.25C15.477 6.25 17.823 8.579 17.823 11.39C17.823 14.922 15.891 17.494 13.133 17.494C12.7407 17.5054 12.3512 17.4245 11.9959 17.258C11.6405 17.0914 11.3292 16.8438 11.087 16.535L11.044 16.712L10.837 17.564L10.835 17.572C10.689 18.172 10.587 18.589 10.547 18.745C10.441 19.1 10.307 19.448 10.147 19.785C12.1368 20.2592 14.2327 19.9532 16.0038 18.9299C17.775 17.9066 19.0869 16.2437 19.6699 14.283C20.2528 12.3223 20.0626 10.2127 19.1383 8.38795C18.214 6.56317 16.6258 5.16177 14.7001 4.4718C12.7745 3.78182 10.6577 3.85568 8.78478 4.6782C6.91191 5.50073 5.42525 7.00942 4.63037 8.89419C3.83548 10.779 3.79276 12.8966 4.51099 14.8119C5.22922 16.7272 6.65382 18.2936 8.492 19.191ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z"
        fill="#FFF"
      />
    </svg>
  );
}
