import { useEffect, useState } from "react";
import Head from "next/head";
import Link from "next/link";
import { useCMS } from "@/src/component/provider";
import { useBreakPointDown } from "@/src/hook";
import { ImageLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import styles from "./ListingCard.module.scss";
import ListingCardInner from "./ListingCardInner";

interface ListingCardProps {
  item: BlaceV2Type.SearchType.SearchItem;
  eager: boolean;
  inquiryId?: string;
}

function ListingCard({ item, eager, inquiryId }: ListingCardProps) {
  const cms = useCMS();
  const color = "white";
  const id = `ListingCard.${item.slug}`;
  const location = item.locations?.[0];
  const [showCard, setShowCard] = useState<boolean>(eager);
  const [mainImageUrl, setMainImageUrl] = useState<string>(
    ImageLogic.getMainImageUrl(item)
  );
  const isMobile = useBreakPointDown("md");

  /**
   * if eager flag changes show the card
   */
  useEffect(() => {
    if (eager && !showCard) {
      setShowCard(true);
    }
  }, [eager, showCard]);

  /**
   * change the image
   */
  useEffect(() => {
    setMainImageUrl(ImageLogic.getMainImageUrl(item));
  }, [item]);

  /**
   * lazy load this card
   */
  useEffect(() => {
    if (showCard) {
      return;
    }

    function handleScrollListener() {
      const offset = 100;
      const thisElement = document.getElementById(id);
      const bottom = window.scrollY + window.innerHeight + offset;
      if (bottom > (thisElement?.getBoundingClientRect()?.top ?? 0)) {
        setShowCard(true);
      }
    }

    handleScrollListener();
    window.addEventListener("scroll", handleScrollListener, { passive: true });
    window.addEventListener("resize", handleScrollListener);
    return () => {
      window.removeEventListener("scroll", handleScrollListener);
      window.removeEventListener("resize", handleScrollListener);
    };
  }, [id, showCard]);

  return (
    <>
      {!showCard && mainImageUrl && (
        <Head>
          <link rel="preload" href={`${mainImageUrl}`} as="image" />
        </Head>
      )}
      <Link
        href={{
          pathname: cms.constructLink(`/${item?.dataType}/${item?.slug}`),
          ...(inquiryId ? { query: { inquiryId } } : {}),
        }}
        className={styles.hideLinkStyles}
        title={`${item.title} by BLACE, ${(item.categories ?? []).join(
          ", "
        )}`.trim()}
        id={id}
        aria-label={`Go To ${item.title}`}
      >
        <div className={styles.listingCard}>
          {showCard && (
            <ListingCardInner
              item={item}
              color={color}
              location={location}
              showCard={showCard}
              isMobile={isMobile}
              mainImageUrl={mainImageUrl}
            />
          )}
          <noscript>
            <ListingCardInner
              item={item}
              color={color}
              location={location}
              showCard={true}
              isMobile={isMobile}
              mainImageUrl={mainImageUrl}
            />
          </noscript>
        </div>
      </Link>
    </>
  );
}

export default ListingCard;
