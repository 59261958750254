interface Props {
  width: number;
}

export function BurgerSVG({ width = 22 }: Props) {
  return (
    <svg
      width={`${width}`}
      height={`${(18 / 22) * width}`}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.734784 0.137354 0.48043 0.381846 0.292893C0.626338 0.105357 0.95794 0 1.3037 0H20.6963C21.0421 0 21.3737 0.105357 21.6182 0.292893C21.8626 0.48043 22 0.734784 22 1C22 1.26522 21.8626 1.51957 21.6182 1.70711C21.3737 1.89464 21.0421 2 20.6963 2H1.3037C0.95794 2 0.626338 1.89464 0.381846 1.70711C0.137354 1.51957 0 1.26522 0 1ZM0 9C0 8.73478 0.137354 8.48043 0.381846 8.29289C0.626338 8.10536 0.95794 8 1.3037 8H20.6963C21.0421 8 21.3737 8.10536 21.6182 8.29289C21.8626 8.48043 22 8.73478 22 9C22 9.26522 21.8626 9.51957 21.6182 9.70711C21.3737 9.89464 21.0421 10 20.6963 10H1.3037C0.95794 10 0.626338 9.89464 0.381846 9.70711C0.137354 9.51957 0 9.26522 0 9ZM0 17C0 16.7348 0.137354 16.4804 0.381846 16.2929C0.626338 16.1054 0.95794 16 1.3037 16H12.8741C13.2198 16 13.5514 16.1054 13.7959 16.2929C14.0404 16.4804 14.1778 16.7348 14.1778 17C14.1778 17.2652 14.0404 17.5196 13.7959 17.7071C13.5514 17.8946 13.2198 18 12.8741 18H1.3037C0.95794 18 0.626338 17.8946 0.381846 17.7071C0.137354 17.5196 0 17.2652 0 17Z"
        fill="white"
      />
    </svg>
  );
}
