interface Props {
  width: number;
}

export function RightArrowSVG({ width = 9 }: Props) {
  return (
    <svg
      width={`${width}`}
      height={`${(16 / 9) * width}`}
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L8 8L1 15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
