import React from "react";
import { Box, CircularProgress } from "@mui/material";
import styles from "./BaseLoader.module.scss";

interface BaseLoaderProps {
  size?: number;
  color?:
    | "secondary"
    | "primary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "inherit";
  wrapperClassName?: string;
}

function BaseLoader({
  size,
  color = "secondary",
  wrapperClassName = styles.loaderWrapper,
}: BaseLoaderProps) {
  return (
    <Box className={wrapperClassName}>
      <CircularProgress size={size} color={color} data-testid="progress" />
    </Box>
  );
}

export default BaseLoader;
