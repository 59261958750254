// import { useState, useEffect } from "react";
import { /*Collapse,*/ Divider, Link, Typography } from "@mui/material";
//import cn from "classnames";
import Image from "next/image";
import EventsIcon from "@/public/icons/eventsIcon.svg";
//import ExpandDown from "@/public/icons/expandDown.svg";
//import ExpandUp from "@/public/icons/expandUp.svg";
//import NotificationsIcon from "@/public/icons/notificationsIcon.svg";
//import NotificationsIconUnread from "@/public/icons/notificationsIconUnread.svg";
import { useCMS } from "@/src/component/provider";
// import { BlaceV1API } from "@/src/service";
// import { NotificationType } from "@/src/type";
import styles from "@/src/component/partial/Header/Header.module.scss";
import { CurrentUserProps } from "./NavigationDrawer";

export function Events({ currentUser }: CurrentUserProps) {
  const cms = useCMS();
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [notifications, setNotifications] = useState<
  //   NotificationType.Notification[]
  // >([]);
  //
  // useEffect(() => {
  //   (async () => {
  //     const notifications =
  //       await BlaceV1API.NotificationService.getNotifications();
  //     if (
  //       !notifications?.success ||
  //       (notifications.body?.data ?? []).length === 0
  //     ) {
  //       return;
  //     }
  //     setNotifications(notifications.body?.data ?? []);
  //   })();
  // }, []);

  if (!currentUser) {
    return null;
  }

  // function getDaysOld(createdAt: string): string {
  //   if (!createdAt) {
  //     return "";
  //   }
  //   try {
  //     const diffIfMs = Date.now() - new Date(createdAt).getTime();
  //     const diffIfMinutes = diffIfMs / (1000 * 60);
  //     if (diffIfMinutes < 60) {
  //       return `${Math.round(diffIfMinutes)} minutes ago`;
  //     } else if (diffIfMinutes < 60 * 24) {
  //       return `${Math.round(diffIfMinutes / 60)} hours ago`;
  //     }
  //
  //     return `${Math.round(diffIfMinutes / (60 * 24))} days ago`;
  //   } catch (err) {
  //     return "";
  //   }
  // }

  // function getMessage(notification: NotificationType.Notification): string {
  //   if (typeof notification.event?.name === "string") {
  //     return `${notification.data.subject} for ${notification.event.name}`;
  //   }
  //   return `${notification.data.subject}`;
  // }

  return (
    <>
      <div className={styles.eventsWrapper}>
        <Typography component="h3" className={styles.title}>
          Events
        </Typography>
        {/*
        <div
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={cn(styles.accountLinkContent, styles.linkExpandWrapper)}
          >
            <Image
              src={
                notifications.length > 0
                  ? NotificationsIconUnread
                  : NotificationsIcon
              }
              alt="notifications icon"
              className={cn(
                styles.arrowOffsetRight,
                styles.notificationAlignUnread
              )}
            />
            Notifications
            <Image
              src={isOpen ? ExpandUp : ExpandDown}
              alt="expand icon"
              className={styles.expandIcon}
            />
          </div>
          <Collapse in={isOpen}>
            <div className={styles.collapseWrapper}>
              {notifications.map((notification, i) => (
                <div className={styles.paymentInfo} key={i}>
                  <Link href={notification.data.vars.actionUrl}>
                    <Typography component="h3" className={styles.paymentTitle}>
                      {getMessage(notification)}
                    </Typography>
                  </Link>
                  <Typography className={styles.inquiryTime}>
                    {getDaysOld(notification.created_at)}
                  </Typography>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
              */}
        <Link
          href={cms.constructLink("/dashboard")}
          className={styles.accountLink}
        >
          <div className={styles.accountLinkContent}>
            <Image
              src={EventsIcon}
              alt="events icon"
              className={styles.arrowOffsetRight}
            />
            My Events
          </div>
        </Link>
      </div>
      <Divider className={styles.drawerDivider} />
    </>
  );
}
