import { ASSET_URL } from "@/src/const";

export function convertFileStorageToAssetUrl(initialUrl: string) {
  if (ASSET_URL && initialUrl.indexOf(ASSET_URL) !== -1) {
    return initialUrl;
  }
  const regex = /http(s)?:\/\/[\w.-]+\/assets(.+)/;
  const newUrlSubString = ASSET_URL;
  const additionalParam = "?format=auto";

  if (!regex.test(initialUrl)) {
    return null;
  }

  const filePath = initialUrl.split(regex)[2];
  const newUrl = newUrlSubString + filePath + additionalParam;
 
  return newUrl;
}
