import jwt_decode from "jwt-decode";
import { B2B_AUTH_ENDPOINT, B2B_URL, DEFAULT_RETURN_AUTH_TO, KEYS } from "@/src/const";
import { ActivityLogic, CMSLogic } from "@/src/model";
import { BlaceV1API, BlaceV2API } from "@/src/service";
import { AuthType } from "@/src/type";
import { FormattedDateHelper, Log, StorageHelper } from "@/src/util";
import type { NextRouter } from "next/router";

const storageHelperUseAuth = StorageHelper();

/**
 * Store a guest token for future use
 *
 * @param {string} token - the guest token provided by the Blace V2 API
 * @returns
 */
export function storeGuestToken(token: string) {
  if (typeof localStorage === "undefined") {
    return;
  }

  if (typeof token !== "string") {
    return;
  }

  const decoded: any = jwt_decode(token);
  const { guestOrCustomerId, exp, isGuest } = decoded;
  Log.logToConsoleDebug("AuthLogic.ts", "New guest token", [
    { token },
    { guestOrCustomerId, isGuest },
  ]);

  localStorage.setItem(KEYS.GUEST_TOKEN, `${token}`);
  localStorage.setItem(KEYS.GUEST_TOKEN_EXP, `${exp}`);
  localStorage.setItem(KEYS.GUEST_TOKEN_IS_GUEST, `${isGuest}`);
}

/**
 * Retrieve the guest token for use
 *
 * @returns {string | null | undefined}
 */
export function getGuestToken(): string | undefined | null {
  if (typeof localStorage === "undefined") {
    return;
  }

  const exp = localStorage.getItem(KEYS.GUEST_TOKEN_EXP);
  if (exp && parseInt(exp, 10) > Date.now() / 1000) {
    return localStorage.getItem(KEYS.GUEST_TOKEN);
  }
  return undefined;
}

/**
 * Remove all session state associated with a guest token
 */
export async function clearGuestToken() {
  if (typeof localStorage === "undefined") {
    return;
  }

  localStorage.removeItem(KEYS.GUEST_TOKEN);
  localStorage.removeItem(KEYS.GUEST_TOKEN_EXP);
  localStorage.removeItem(KEYS.GUEST_TOKEN_IS_GUEST);
}

/**
 * Store a V2 auth data for future use
 *
 * @param {string} token - the auth token provided by the Blace V2 API
 * @returns void
 */
export function storeV2AuthData(token: string) {
  if (typeof localStorage === "undefined") {
    return;
  }

  if (typeof token !== "string") {
    return;
  }

  const decoded: any = jwt_decode(token);
  Log.logToConsoleDebug("AuthLogic.ts", "New V2 auth token", [
    { token },
    { decoded },
  ]);

  localStorage.setItem(KEYS.V2_AUTH_TOKEN, `${token}`);
  localStorage.setItem(KEYS.V2_AUTH_TOKEN_EXP, `${decoded.exp}`);
  localStorage.setItem(KEYS.V2_AUTH_USER_ID, `${decoded.v2UserId}`);
}

/**
 * Retrieve the V2 auth token for use
 *
 * @returns {string | null | undefined}
 */
export function getV2AuthToken(): string | undefined | null {
  if (typeof localStorage === "undefined") {
    return;
  }

  return localStorage.getItem(KEYS.V2_AUTH_TOKEN);
}

/**
 * Retrieve the V2 userId for use
 *
 * @returns {string | null | undefined}
 */
export function getV2AuthUserId(): string | undefined | null {
  if (typeof localStorage === "undefined") {
    return;
  }

  return localStorage.getItem(KEYS.V2_AUTH_USER_ID);
}

/**
 * Remove all session state associated with a V2 auth data
 */
export function clearV2AuthData() {
  if (typeof localStorage === "undefined") {
    return;
  }

  localStorage.removeItem(KEYS.V2_AUTH_TOKEN);
  localStorage.removeItem(KEYS.V2_AUTH_TOKEN_EXP);
  localStorage.removeItem(KEYS.V2_AUTH_USER_ID);
}

/**
 * Receive and store a V2 auth token for future use, also update user's time zone
 *
 * @param {string} token - the auth token provided by the Blace V1 API
 * @returns void
 */
export async function performV2AuthViaV1Token(
  token: string,
  timeZone: string
) {
  const v2TokenResponse = await BlaceV2API.AuthServiceV2.postAuth(token, timeZone);
  if (v2TokenResponse.body) {
    storeV2AuthData(v2TokenResponse.body.payload.token);
  }
}

/**
 * functionality to evaluate if the auth / identity token is valid
 *
 * @returns {string | undefined | null}
 */
export function getIdentityToken(): string | undefined | null {
  if (typeof localStorage === "undefined") {
    return;
  }
  const authData: AuthType.AuthStorage = JSON.parse(
    localStorage.getItem(KEYS.OLD_AUTH) ?? "{}"
  );
  return (authData?.expiry ?? 0) > Date.now() ? authData.value : undefined;
}

/**
 * Store an auth token for future use
 *
 * @param {string} token - the token provided by the Blace V1 API
 * @param {AuthType.LoginResponseProfile} currentUser - the profile returned when a user authenticates
 * @returns
 */
export async function storeAuthToken(
  token: string,
  currentUser?: AuthType.LoginResponseProfile
) {
  if (typeof localStorage === "undefined") {
    return;
  }

  if (typeof token !== "string") {
    return;
  }

  const decoded: any = jwt_decode(token);
  const { exp, scopes } = decoded;
  if (typeof exp !== "number") {
    return;
  }

  const authData: AuthType.AuthStorage = {
    expiry: Math.floor(exp) * 1000,
    value: token,
  };

  Log.logToConsoleDebug(
    "AuthLogic.ts",
    "New auth token being set",
    [authData, currentUser],
    Log.LOG_COLORS.STATE_CHANGE.BG_COLOR,
    Log.LOG_COLORS.STATE_CHANGE.COLOR
  );

  if (!currentUser) {
    const currentUserResponse = await BlaceV1API.UserService.getProfile(token);
    if (currentUserResponse?.body?.data) {
      currentUser = currentUserResponse.body.data;
      currentUser.is_admin = scopes && scopes.length && scopes.includes("is-admin");
    }
  }
  if (currentUser) {
    currentUser.token = "";
    currentUser.events = undefined;
    currentUser.vendors = undefined;
    currentUser.venues = undefined;
    currentUser.user_files = undefined;

    if (
      (currentUser.email ?? "").includes("@blace.com") ||
      (currentUser.email ?? "").includes("@revenueb.com")
    ) {
      ActivityLogic.isEmployee(true);
    }
  } else {
    const nowDateString = FormattedDateHelper.formatSystemDate(new Date());
    currentUser = {
      id: 0,
      name: "",
      email: "",
      agreed: false,
      created_at: nowDateString,
      updated_at: nowDateString,
      is_admin: scopes && scopes.length && scopes.includes("is-admin"),
      token: token,
    };
  }

  localStorage.setItem(KEYS.OLD_AUTH, JSON.stringify(authData));
  localStorage.setItem(KEYS.OLD_AUTH_CURRENT_USER, JSON.stringify(currentUser));
  
  // TODO move timeZone update logic to other place
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // TODO move it to the better place or refactor the logic
  // currently it's a better place to have it in one place instead of multiple places across the project
  await performV2AuthViaV1Token(token, localTimeZone);
}

/**
 * Provides a localstorage listener on the current user
 *
 * @returns {AuthType.LoginResponseProfile | undefined}
 */
export function listenAuthCurrentUser():
  | AuthType.LoginResponseProfile
  | undefined {
  const [value] = storageHelperUseAuth.useLocalStorage<
    AuthType.LoginResponseProfile | undefined
  >(KEYS.OLD_AUTH_CURRENT_USER, undefined);
  return value;
}

/**
 * Get the current user from localstorage
 *
 * @returns {AuthType.LoginResponseProfile | undefined}
 */
export function getAuthCurrentUser():
  | AuthType.LoginResponseProfile
  | undefined {
  const currentUser = localStorage.getItem(KEYS.OLD_AUTH_CURRENT_USER);
  if (!currentUser
      || currentUser === "undefined"
      || currentUser === "null"
  ) {
    return undefined;
  }
  return JSON.parse(currentUser);
}

export async function logOutCurrentUser() {
  localStorage.removeItem(KEYS.OLD_AUTH_CURRENT_USER);
  localStorage.removeItem(KEYS.OLD_AUTH);
  localStorage.removeItem("userEvents");

  clearV2AuthData();

  await clearAuthCallbackPath();
}
/**
 * Determine if a token is available for the backend
 *
 * @returns
 */
export function hasAuthToken(): boolean {
  return typeof getIdentityToken() === "string";
}

/**
 * Determine if a token is available for the backend
 *
 * @returns
 */
export function checkAndClearAuthTokenWhenExpired() {
  const val = localStorage.getItem(KEYS.OLD_AUTH);
  if (!val) {
    return;
  }

  try {
    const auth: AuthType.AuthStorage = JSON.parse(val);
    if (auth.expiry < Date.now()) {
      localStorage.removeItem(KEYS.OLD_AUTH);
      localStorage.removeItem(KEYS.OLD_AUTH_CURRENT_USER);

      Log.logToConsoleDebug(
        "AuthLogic.ts",
        "Token is expired and will be removed",
        [],
        Log.LOG_COLORS.WARN.BG_COLOR,
        Log.LOG_COLORS.WARN.COLOR
      );
    }
  } catch (err) {
    localStorage.removeItem(KEYS.OLD_AUTH);
    localStorage.removeItem(KEYS.OLD_AUTH_CURRENT_USER);
  }
}

/**
 * Determine if a token is available for the backend
 *
 * @returns if the client has a valid auth token or guest token
 */
export function hasToken(): boolean {
  return (
    typeof getGuestToken() === "string" ||
    typeof getIdentityToken() === "string"
  );
}

/**
 * Set the path that auth should call once login is complete
 *
 * @param path - a path that should be called once the user is loged in
 */
export function setAuthCallbackPath(path: string) {
  StorageHelper().setToCache(KEYS.AUTH_CALLBACK_PATH, path);
}

/**
 * Get the path to return the user to after auth is complete
 * @param {AuthType.LoginResponseProfile} currentUser - the profile returned when a user authenticates
 */
export async function getAuthCallbackPath(
  currentUser?: AuthType.LoginResponseProfile
): Promise<string | undefined | null> {
  if (currentUser && currentUser.is_admin) {
    // if impersonate mode was used, we should ignore the callback path
    // it's needed to prevent cases when guest inquiries will be linked to the wrong user
    return null;
  }

  return await StorageHelper().getFromCache(KEYS.AUTH_CALLBACK_PATH);
}

/**
 * After the user has been directed to correct callback clear the path
 */
export async function clearAuthCallbackPath() {
  const priorCallback = await getAuthCallbackPath();
  Log.logToConsoleDebug(
    "AuthLogic.ts",
    "Clear previous auth callback",
    [{ priorCallback }],
    Log.LOG_COLORS.WARN.BG_COLOR,
    Log.LOG_COLORS.WARN.COLOR
  );
  await StorageHelper().clearFromCache(KEYS.AUTH_CALLBACK_PATH);
}

/**
 * the redirect for the b2b portal
 * @param role
 * @param callbackPath
 * @param url
 * @param path
 * @param token
 */
export async function b2bRedirect(
  role?: string,
  callbackPath?: string | null,
  url?: string,
  path?: string,
  token?: string
): Promise<boolean> {
  if (
    role === "vendor" ||
    role === "landlord" ||
    role === "blace-sales" ||
    callbackPath?.includes("/a/")
  ) {
    await clearAuthCallbackPath();
    const b2bPath = (path ?? "").includes("#token=")
      ? `${url}${path}${token}`
      : `${url}${path}`;
    window.open(b2bPath, "_self");
    return true;
  }
  return false;
}

/**
 * performs the redirect after successful auth operation
 * @param {AuthType.LoginResponseProfile} currentUser - the profile returned when a user authenticates
 * @param {string} v1AuthToken
 * @param {string | null | undefined} redirectPath
 * @param {NextRouter} router
 */
export async function performRedirectAfterAuth(
    currentUser: AuthType.LoginResponseProfile,
    v1AuthToken: string,
    redirectPath: string | null | undefined,
    router: NextRouter
): Promise<void> {
  const isB2B = await b2bRedirect(
      currentUser.role,
      redirectPath,
      B2B_URL,
      B2B_AUTH_ENDPOINT,
      v1AuthToken
  );
  if (isB2B) {
    return;
  }

  const callbackUrl = redirectPath ?? DEFAULT_RETURN_AUTH_TO;
  router.push(CMSLogic.constructLink(callbackUrl));
}
