import { useState } from "react";
import type { PropsWithChildren } from "react";
import { ActivityLogic } from "@/src/model";
import { uniqueId } from "@/src/util";

interface MailToProps {
  href: string;
  className?: string;
  subject?: string;
  action: string;
}

function MailTo({
  href,
  action,
  className,
  subject = "BLACE Question / Inquiry",
  children,
}: PropsWithChildren<MailToProps>) {
  const [messageId] = useState<string>(uniqueId());

  function sendActionMessage() {
    ActivityLogic.ga4Tracking("contact_us", {});
    ActivityLogic.toActivityService({
      action: "contactUs",
      actionId: messageId,
      actionIdType: "messageId",
      actionMessage: action,
      locationInApp: "MailTo.tsx",
    });
  }

  const body = `id=${messageId}%0D%0APlease tell us how we can help below the line%0D%0A------------------%0D%0A`;
  return (
    <a
      suppressHydrationWarning={true}
      href={`mailto:${href}?subject=${subject}&body=${body}`}
      className={className}
      blace-action={action}
      onClick={sendActionMessage}
      aria-label="Contact Us"
      id={"MailTo.contactUs"}
    >
      {children}
    </a>
  );
}

export default MailTo;
