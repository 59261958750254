export function CapacityBoardroomSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="26"
      viewBox="0 0 33 26"
      fill="none"
    >
      <path
        d="M9.96769 22.5596C12.0966 22.8884 11.6033 26.0817 9.47443 25.7529C7.35032 25.4248 7.84357 22.2315 9.96769 22.5596Z"
        fill="#1D39C4"
      />
      <path
        d="M16.5168 22.5596C18.6457 22.8884 18.1524 26.0817 16.0235 25.7529C13.8994 25.4248 14.3926 22.2315 16.5168 22.5596Z"
        fill="#1D39C4"
      />
      <path
        d="M23.0658 22.5596C25.1947 22.8884 24.7015 26.0817 22.5726 25.7529C20.4485 25.4248 20.9417 22.2315 23.0658 22.5596Z"
        fill="#1D39C4"
      />
      <path
        d="M9.96769 0.246076C12.0966 0.574912 11.6033 3.76827 9.47443 3.43943C7.35032 3.11134 7.84357 -0.0820211 9.96769 0.246076Z"
        fill="#1D39C4"
      />
      <path
        d="M16.517 0.246076C18.6459 0.574912 18.1527 3.76827 16.0238 3.43943C13.8996 3.11134 14.3929 -0.0820211 16.517 0.246076Z"
        fill="#1D39C4"
      />
      <path
        d="M23.0661 0.246076C25.195 0.574912 24.7017 3.76827 22.5728 3.43943C20.4487 3.11134 20.942 -0.0820211 23.0661 0.246076Z"
        fill="#1D39C4"
      />
      <path
        d="M25.7175 6.55161V19.4482H6.44849L6.44849 6.55315L25.7175 6.55161ZM23.7951 17.5258V8.47555L8.37089 8.47405V17.5259L23.7951 17.5258Z"
        fill="black"
      />
      <path
        d="M30.7455 11.709C32.8744 12.0378 32.3812 15.2312 30.2523 14.9023C28.1282 14.5742 28.6214 11.3809 30.7455 11.709Z"
        fill="#1D39C4"
      />
      <path
        d="M2.08634 11.709C4.21525 12.0378 3.72199 15.2312 1.59309 14.9023C-0.531029 14.5742 -0.0377746 11.3809 2.08634 11.709Z"
        fill="#1D39C4"
      />
    </svg>
  );
}
