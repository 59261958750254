//@ts-nocheck
import { ReactElement } from "react";
import cn from "classnames";
import { ListingDisplayVariant } from "@/src/type";
import styles from "./ListingGrid.module.scss";

interface Props {
  variant: keyof typeof ListingDisplayVariant;
  cards: ReactElement[];
  isFromUpSell?: boolean;
}

function getClassName(id: string) {
  switch (id ?? "default") {
    case "ad":
      return styles.listingGridAdd;
    case "empty":
      return styles.listingGridEmpty;
  }
  return undefined;
}

function ListingGrid({ variant, cards, isFromUpSell = false }: Props) {
  const classesList = isFromUpSell
    ? cn(styles.listGridVariant, styles.listGridVarianUpSell, variant)
    : cn(styles.listGridVariant, variant);

  return (
    <ol className={classesList}>
      {(cards ?? []).map((card, i) => (
        <li key={i} className={cn(getClassName(card?.props?.id), variant)}>
          {card}
        </li>
      ))}
    </ol>
  );
}

export default ListingGrid;
