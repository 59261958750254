import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  EMAIL_VALIDATION_REGEXP,
  PASSWORD_REGEX,
} from "@/src/const";
import { useNextNavigation } from "@/src/hook/useNextNavigation";
import { ActivityLogic, AuthLogic } from "@/src/model";
import { BlaceV1API } from "@/src/service";
import { AuthType } from "@/src/type";
import { sleep, URLHelper } from "@/src/util";
import type { FormikProps } from "formik";

export type SignUpTypeFormik = {
  name: string;
  email: string;
  magicLink: boolean;
  agreed: boolean;
  password?: string;
};

export type SignUpType = {
  isLoading: boolean;
  saveComplete: boolean;
  hasError?: string;
  formik: FormikProps<SignUpTypeFormik>;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2, "Name is required").required("Name is required"),
  magicLink: Yup.boolean(),
  email: Yup.string()
    .matches(EMAIL_VALIDATION_REGEXP, "Must be a valid email")
    .required("Email is required"),
});

export function useSignUp(): SignUpType {
  const nextNavigation = useNextNavigation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveComplete, setSaveComplete] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string | undefined>(undefined);

  const formik = useFormik<SignUpTypeFormik>({
    initialValues: {
      name: "",
      email: "",
      magicLink: false,
      agreed: true,
      password: "",
    },
    validationSchema,
    onSubmit: async values => {
      setIsLoading(true);

      const signupRequest: AuthType.SignupRequest = {
        name: values.name,
        email: values.email,
        agreed: values.agreed ? 1 : 0,
        password: values.magicLink ? undefined : values.password,
        magicLink: values.magicLink,
      };

      const response = await BlaceV1API.AuthService.postSignUp(signupRequest);
      if (response.success && response.body?.success) {
        ActivityLogic.ga4Tracking("sign_up", {}, true);
        ActivityLogic.facebookTracking("track", "CompleteRegistration");
        await ActivityLogic.toActivityService({
          action: "signUp",
          locationInApp: "useSignUp.ts",
        });
        await sleep(150);

        const token = response.body?.data?.token;
        const currentUser = response.body?.data;
        if (typeof token === "string" && currentUser) {
          AuthLogic.storeAuthToken(token, currentUser);

          const callbackPath = await AuthLogic.getAuthCallbackPath(currentUser);
          await AuthLogic.performRedirectAfterAuth(
              currentUser,
              token,
              callbackPath,
              nextNavigation.router
          );
        } else {
          setSaveComplete(true);
        }
      } else {
        if (response.body?.data) {
          if (
            Array.isArray(response.body?.data?.email) &&
            typeof response.body?.data?.email?.[0] === "string"
          ) {
            setHasError(response.body?.data?.email?.[0]);
          } else if (
            Array.isArray(response.body?.data?.password) &&
            typeof response.body?.data?.password?.[0] === "string"
          ) {
            setHasError(response.body?.data?.password?.[0]);
          }
        } else {
          setHasError("Unable to complete sign up, please try again");
        }
      }

      setIsLoading(false);
    },
  });

  /**
   * set the email if provided in the url
   */
  useEffect(() => {
    const email = URLHelper.urlGetParameter("email");
    const fullName = URLHelper.urlGetParameter("fullName");

    formik.setValues(
      {
        name: fullName ?? "",
        email: email ?? "",
        magicLink: false,
        agreed: true,
        password: "",
      },
      true
    );
  }, []);

  /**
   * after the user starts changing the form remove the error
   */
  useEffect(() => {
    if (!hasError) {
      return;
    }

    function handleKeyDown() {
      setHasError(undefined);
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [hasError]);

  /**
   * Update password error based on if magic link or not
   */
  useEffect(() => {
    if (formik.values.magicLink) {
      formik.setFieldError("password", undefined);
      return;
    }

    if ((formik.values.password ?? "").length < 6) {
      formik.setFieldError("password", "Your password must be at least 6 characters");
    } else if (!PASSWORD_REGEX.test(`${formik.values.password}`)) {
      formik.setFieldError(
        "password",
        "Your password must contain a capital letter, lower case letter, and number"
      );
    } else {
      formik.setFieldError("password", undefined);
    }
  }, [formik.values.password]);

  return {
    isLoading,
    saveComplete,
    hasError,
    formik,
  };
}
