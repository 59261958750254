import { ReactElement } from "react";
import { Link } from "@mui/material";
import styles from "./FooterContactUsItem.module.scss";

interface FooterContactUsItemProps {
  Icon: ReactElement;
  title: string;
  href?: string;
}

function FooterContactUsItem({ Icon, title, href }: FooterContactUsItemProps) {
  return (
    <div className={styles.footerContactInfoItem}>
      <div className={styles.footerContactInfoItemIcon} data-test="icon">{Icon}</div>
      <Link underline="hover" variant="body1" color="white" href={href}>
        {title}
      </Link>
    </div>
  );
}

export default FooterContactUsItem;
