import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import cn from "classnames";
import { FooterBottomLinkExtra } from "@/src/component/partial/Footer/component/FooterBottomLinkExtra";
import { Footer } from "@/src/type/strapiCMS/components/partial";
import styles from "./FooterBottomExtra.module.scss";

interface FooterBottomExtraProps {
  isWidget?: boolean;
  footer: Footer;
  name: string;
}

function FooterBottomExtra({ isWidget, footer, name }: FooterBottomExtraProps) {

  return (
    <div className={styles.footerBottomExtra}>
      <>
        {!isWidget && (
          <div
            className={cn(
              styles.footerBottomExtraBackToTop,
              styles.footerLinksAnimation
            )}
          >
            <div className={styles.footerBottomExtraBackToTopIcon}>
              <ArrowUpwardIcon fontSize={"small"} />
            </div>
            <FooterBottomLinkExtra
              title="Back To Top"
              href="#"
              onClick={() => window.scrollTo()}
            />
          </div>
        )}
      </>
      <div className={styles.footerBottomExtraLegal}>
        {footer?.termsOfUse?.href && (
          <div className={styles.footerLinksAnimation}>
            <FooterBottomLinkExtra
              title={footer.termsOfUse.label ?? "Privacy Policy"}
              href={footer.termsOfUse.href}
            />
          </div>
        )}
        {footer?.privacyPolicy?.href && (
          <div className={styles.footerLinksAnimation}>
            <FooterBottomLinkExtra
              title={footer.privacyPolicy.label ?? "Terms Of Use"}
              href={footer.privacyPolicy.href}
            />
          </div>
        )}
      </div>
      <div className={styles.footerBottomExtraCopyright}>
        {name ?? "Blace"} &copy; {new Date().getFullYear()}
        <div className={styles.footerBottomEasterEggContainer}>
          <img
            className={styles.footerBottomEasterEgg}
            src="https://blace-prod.imgix.net/bigBird.png?auto=format&w=40"
            alt="Big Bird Easter Egg"
            height="20"
            width="12"
          />
        </div>
      </div>
    </div>
  );
}

export default FooterBottomExtra;
