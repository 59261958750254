//@ts-nocheck
function ConfigManager() {
  const config: any = {};

  this.init = (obj: any) => {
    if (obj && Object.keys(obj).length > 0) {
      for (const key of Object.keys(obj)) {
        this.setValue(key, obj[key]);
      }
    }
  };

  this.setValue = (key: string, props: any) => {
    if (!key) {
      return;
    }
    config[key] = props;
  };

  this.getValue = (key: string, defaultTo: any): any => {
    /* prettier-ignore */
    return key ? (config[key] ?? (defaultTo ?? undefined)) : (defaultTo ?? undefined);
  };

  this.getAll = (): Record<string, any> => {
    return config;
  };
}

export const SharedConfigManager = new ConfigManager();
