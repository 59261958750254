import { BaseLoader } from "@/src/component/base/BaseLoader";
import styles from "./PageLoading.module.scss";

function PageLoading() {
  return (
    <BaseLoader size={80} wrapperClassName={styles.pageLoadingLayout}/>
  );
}

export default PageLoading;
