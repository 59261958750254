//@ts-nocheck
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import whyDidYouRender from "@welldone-software/why-did-you-render";
import { PageLayout } from "@/src/component/layout";
import { ThemeCustomization } from "@/styles";
import { WithApp, WithCMS, WithFeatureHub } from "@src/component/provider";
import {
  APP_API,
  APP_NAME,
  APP_TENANT,
  ASSET_URL,
  BLACE_V2_API,
  DATADOG_TOKEN,
  ENV,
  FEATURE_HUB_APP_ID,
  FEATURE_HUB_KEY,
  FEATURE_HUB_URL,
  GOOGLE_MAPS_KEY,
  KEYS,
  STRAPI_API_TOKEN,
  STRAPI_API_URL,
  TEXT_CDN_FRONTEND,
  TEXT_CDN_URL
} from "@src/const";
import { useWidget } from "@src/hook";
import { SharedConfigManager } from "@src/util";
import type { AppProps } from "next/app";

/**
 * global scss variables are loaded via next.config.js
 */
import "@/styles/globals.css";

if (ENV === "dev") {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

function setConfig() {
  //init the config for the frontend code library
  SharedConfigManager.init({
    [KEYS.ASSET_URL]: ASSET_URL,
    [KEYS.TEXT_CDN_FRONTEND]: TEXT_CDN_FRONTEND,
    [KEYS.TEXT_CDN_URL]: TEXT_CDN_URL,
    [KEYS.DATADOG_TOKEN]: DATADOG_TOKEN,
    [KEYS.GOOGLE_MAPS_KEY]: GOOGLE_MAPS_KEY,
    [KEYS.ENV]: ENV,
    [KEYS.APP_NAME]: APP_NAME,
    [KEYS.BLACE_V2_API]: BLACE_V2_API,
    [KEYS.APP_API]: APP_API,
    [KEYS.APP_TENANT]: APP_TENANT,
    // Strapi
    [KEYS.STRAPI_API_URL]: STRAPI_API_URL,
    [KEYS.STRAPI_API_TOKEN]: STRAPI_API_TOKEN,
  });
  return true;
}

function CustomApp({ Component, pageProps, emotionCache }: AppProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
  const [_configReady, _setConfigReady] = useState<boolean>(setConfig());

  if (pageProps.isWidget || pageProps.otherLayout) {
    if (pageProps.isWidget) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useWidget();
    }

    return (
      <WithCMS
        globalWebConfig={pageProps.globalWebConfig}
        locale={pageProps.locale}
        dontLoadConfig={pageProps.dontLoadConfig}
      >
        <ThemeCustomization emotionCache={emotionCache}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <WithApp
              initSearch={{
                filters: pageProps.filters,
                searchId: pageProps.searchId,
              }}
              locale={pageProps.locale}
              disablePageTracking={pageProps.disablePageTracking}
              globalWebConfig={pageProps.globalWebConfig}
              initGlobalSearch={pageProps.initGlobalSearch}
            >
              <Component {...pageProps} />
            </WithApp>
          </LocalizationProvider>
        </ThemeCustomization>
      </WithCMS>
    );
  }

  return (
    <WithFeatureHub
      url={FEATURE_HUB_URL}
      apiKey={FEATURE_HUB_KEY}
      applicationId={FEATURE_HUB_APP_ID}
    >
      <WithCMS
        globalWebConfig={pageProps.globalWebConfig}
        locale={pageProps.locale}
      >
        <ThemeCustomization emotionCache={emotionCache}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <WithApp
              initSearch={{
                filters: pageProps.filters,
                searchId: pageProps.searchId,
              }}
              initGlobalSearch={pageProps.initGlobalSearch}
              locale={pageProps.locale}
              globalWebConfig={pageProps.globalWebConfig}
            >
              <PageLayout
                pageHasMobileFooter={pageProps.pageHasMobileFooter}
                transparentBeforeScroll={pageProps.transparentBeforeScroll}
              >
                <Component {...pageProps} />
              </PageLayout>
            </WithApp>
          </LocalizationProvider>
        </ThemeCustomization>
      </WithCMS>
    </WithFeatureHub>
  );
}

export default CustomApp;
