import { merge } from "lodash";
import { SearchSimilarSearchProps } from "@/src/component/partial/SimilarCarousel";
import { SearchLogic } from "@/src/model";
import { BlaceV2API, StrapiCMSAPI } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { Log } from "@/src/util";

const MAX_RESULTS = 6;

export interface SimilarDataConfig {
  searchedSlug: string;
  slug: string;
  search: SearchSimilarSearchProps;
  searchDataUpdate?: (items: BlaceV2Type.SearchType.Search[]) => void;
}

async function queryForOverrides(
  slug: string
): Promise<
  | BlaceV2API.HttpServiceV2.HTTPResponse<BlaceV2Type.SearchType.SearchResponse>
  | undefined
> {
  //get override values from the CMS
  const overrides: string[] = [];
  if (typeof slug === "string") {

    const overridesResponse =
      await StrapiCMSAPI.getSimilarCarouselOverridesUsingFilter(
        `filters[detailPageSlug][$eq]=${slug}`
      );

    for (const opt of overridesResponse.response?.data ?? []) {
      if (slug === opt.attributes.detailPageSlug) {
        for (const overrideSlugOpt of opt.attributes.similarDetailPageSlug ??
          []) {
          if (overrideSlugOpt.similarSlug) {
            overrides.push(overrideSlugOpt.similarSlug.trim());
          }
        }
      }
    }
  }

  //if the cms had override values get the override values from search index
  let overrideResponse:
    | BlaceV2API.HttpServiceV2.HTTPResponse<BlaceV2Type.SearchType.SearchResponse>
    | undefined = undefined;
  if (overrides.length > 0) {
    const query = SearchLogic.defaultQuery(20);
    query.search = "";
    query.queryType = "simple";
    query.filter = `search.in(slug,'${overrides.join("|")}','|')`;
    query.skip = 0;
    query.sessionId = "";
    query.orderby = "";

    overrideResponse = await BlaceV2API.SearchServiceV2.postSearchQuery(query);
  }

  return overrideResponse;
}

async function queryForSimilar(
  dataType: BlaceV2Type.SearchType.SearchDataType,
  region: string,
  category?: string[]
) {
  //
  const useCategory =
    (category ?? []).length > 0
      ? `and categories/any(c: search.in(c,'${category?.join("|")}','|'))`
      : "";

  const query = SearchLogic.defaultQuery(20);
  query.search = "";
  query.queryType = "simple";
  query.filter = `regions/any(r: r eq '${region}') and dataType eq '${dataType}' ${useCategory}`;
  query.skip = 0;
  query.sessionId = "";

  return await BlaceV2API.SearchServiceV2.postSearchQuery(query);
}

export async function getSimilarSearchItems(data: SimilarDataConfig): Promise<BlaceV2Type.SearchType.Search[]> {
  const { searchedSlug, slug, search, searchDataUpdate } = data;
  if (searchedSlug === slug || !slug) {
    return [];
  }

  const getItems = async (): Promise<BlaceV2Type.SearchType.Search[]> => {
    // get similar items configured at CMS
    const overrideResponse = await queryForOverrides(slug);
    let searchResponse = overrideResponse;

    // if not enough, add the similar by: type, region and category
    if (
      (search.category ?? []).length > 0 &&
      (searchResponse?.body?.payload?.value ?? []).length < MAX_RESULTS
    ) {
      searchResponse = merge(
        await queryForSimilar(search.dataType, search.region, search.category),
        searchResponse
      );
    }
    
    // if not enough, add the similar by: type and region
    if ((searchResponse?.body?.payload?.value ?? []).length < MAX_RESULTS) {
      searchResponse = merge(
        await queryForSimilar(search.dataType, search.region),
        searchResponse
      );
    }
    if (
      searchResponse?.status !== 200 ||
      typeof searchResponse?.body === "undefined" ||
      !Array.isArray(searchResponse.body.payload.value) ||
      (searchResponse.body.payload.value ?? []).length === 0
    ) {
      //TODO: add a toast for generic failures
      Log.logToConsoleDebug(
        "SimilarCarousel.tsx",
        "No results",
        [{ searchResponse }],
        Log.LOG_COLORS.ERROR.BG_COLOR,
        Log.LOG_COLORS.ERROR.COLOR
      );

      return [];
    }

    Log.logToConsoleDebug("SimilarCarousel.tsx", "Similar results", [
      { searchResponse },
    ]);

    const items: BlaceV2Type.SearchType.SearchItem[] = [];
    let i = 0;
    for (const value of searchResponse.body.payload.value) {
      if (value.slug !== slug) {
        i++;
        items.push(value);
      }

      if (i === MAX_RESULTS) {
        break;
      }
    }

    searchDataUpdate && searchDataUpdate(items);
   return items;
  };

  return getItems();
}
