import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { APP_PLATFORM, APP_TENANT, EMAIL_VALIDATION_REGEXP } from "@/src/const";
import { ActivityLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { sleep } from "@/src/util";
import type { FormikProps } from "formik";

export type ClientLeadCaptureTypeFormik = {
  email: string;
};

export type ClientLeadCaptureType = {
  isLoading: boolean;
  saveComplete: boolean;
  hasError?: string;
  formik: FormikProps<ClientLeadCaptureTypeFormik>;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_VALIDATION_REGEXP, "Must be a valid email")
    .required("Email Address is required"),
});

interface UseClientLeadCaptureProps {
  occurredAt?: string;
}

export function useClientLeadCapture({
  occurredAt,
}: UseClientLeadCaptureProps): ClientLeadCaptureType {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveComplete, setSaveComplete] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasError, setHasError] = useState<string | undefined>(undefined);

  /**
   * submit email to the backend to save as a lead
   */
  const formik = useFormik<ClientLeadCaptureTypeFormik>({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async values => {
      setIsLoading(true);
      const response = await BlaceV2API.ClientServiceV2.postClientLead({
        email: values.email,
        occurredAt:
          `${APP_PLATFORM}::${occurredAt}` ??
          `${APP_PLATFORM}::useClientLeadCapture`,
        allEnvId: APP_TENANT,
      });
      if (response.body?.metadata.statusCode === 200) {
        ActivityLogic.facebookTracking("track", "Contact");
        ActivityLogic.ga4Tracking("sign_up_newsletter", {}, true);
        await ActivityLogic.toActivityService({
          action: "sign_up_newsletter",
          locationInApp: "useClientLeadCapture.ts",
        });
        await sleep(150);

        setSaveComplete(true);
      }
      setIsLoading(false);
    },
  });

  return {
    isLoading,
    saveComplete,
    hasError,
    formik,
  };
}
