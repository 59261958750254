import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/IosShare";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Typography } from "@mui/material";
import { BaseButton, BaseModal } from "@/src/component/base";
import { useCMS } from "@/src/component/provider";
import { useBreakPointDown, useWindow } from "@/src/hook";
import { ActivityLogic } from "@/src/model";
import { CopyHelper } from "@/src/util";
import styles from "./ShareDetails.module.scss";

interface ShareDetailsProps {
  name: string;
  id: string;
}

function ShareDetails({ name, id }: ShareDetailsProps) {
  const isMobile = useBreakPointDown("md");
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const cms = useCMS();

  /**
   * handle opening the share drawer:
   * use the native share drawer and fallback to dialog/popup
   */
  async function handleShareOpen() {
    if (typeof navigator !== "undefined") {
      try {
        navigator.share({
          title: `${name} by ${cms.globalWebConfig?.data.attributes.name}`,
          text: `${name} by ${cms.globalWebConfig?.data.attributes.name}`,
          url: window.location.href,
        });
        return;
      } catch (error) {}
    }
    setShareOpen(true);

    ActivityLogic.ga4Tracking("share", {
      item_id: id,
      method: "copyLink",
      contentType: "url",
    });

    ActivityLogic.toActivityService({
      action: "share",
      actionId: id,
      actionIdType: "catalog",
      actionMessage: `share details for ${name}`,
      locationInApp: "ShareDetails.tsx",
    });
  }

  return (
    <>
      <div className={styles.shareDetailsImageOverlay}>
        <div className={styles.shareDetailsImageOverlayInner}>
          <BaseButton
            type="button"
            variant="outlined"
            size={isMobile ? "small" : "medium"}
            onClick={handleShareOpen}
            sx={{
              color: "var(--common-white)",
              borderColor: "var(--common-white)",
              "&:hover": {
                opacity: "80%",
                color: "var(--common-white)",
                borderColor: "var(--common-white)",
              },
            }}
          >
            <ShareIcon fontSize={"small"} />
            <Typography variant="button" sx={{ paddingLeft: "2px" }}>
              Share
            </Typography>
          </BaseButton>
        </div>
      </div>
      <BaseModal
        dialogProps={{
          open: shareOpen,
          onClose: () => setShareOpen(false),
          classes: {
            container: !isMobile
              ? styles.shareDetailsDialogContainer
              : undefined,
          },
        }}
        useSlide={false}
        isMobileDraggable={false}
        onExit={() => setShareOpen(false)}
      >
        <div className={styles.shareDetailsDialog}>
          <Typography variant="h3">Share this listing</Typography>
          <BaseButton
            type="button"
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() =>
              // eslint-disable-next-line react-hooks/rules-of-hooks
              CopyHelper.copyToClipboard(useWindow()?.location.href)
            }
          >
            <ContentCopyIcon />
            Copy
          </BaseButton>
          <a
            className={styles.shareDetailsDialogLink}
            href={`mailto:?body=${encodeURIComponent(
              `${useWindow()?.location.href}`
            )}`}
          >
            <BaseButton type="button" variant="outlined" fullWidth>
              <MailOutlineIcon />
              Email
            </BaseButton>
          </a>
        </div>
      </BaseModal>
    </>
  );
}

export default ShareDetails;
