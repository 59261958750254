import { Log } from "@/src/util/log";

export type HTTPResponse<T> = {
  success: boolean;
  status: number;
  error?: string;
  body?: T;
};

class HTTP<T> {
  _url: string;
  _method: string;
  _body: any;
  _useToken: boolean;
  _overrideNoToken: boolean;
  _allEnvId: string | undefined;
  _allowGuest: boolean;

  constructor(
    url: string,
    method: string,
    body: any = null,
    useToken: boolean = true
  ) {
    this._url = url;
    this._method = method;
    this._body = body;
    this._useToken = useToken;
    this._overrideNoToken = false;
    this._allowGuest = false;
  }

  setOverrideNotToken(override: boolean) {
    this._overrideNoToken = override;
  }

  setAllEnvId(allEnvId: string) {
    this._allEnvId = allEnvId;
  }

  setAllowGuest(allowGuest: boolean) {
    this._allowGuest = allowGuest;
  }

  execute = async (): Promise<HTTPResponse<T>> => {
    return await new Promise(async resolve => {
      interface Headers {
        "Content-Type": string;
        Accept: string;
        Authorization?: string;
        Guest?: string;
        AllEnvId?: string;
      }

      const headers: Headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        AllEnvId: this._allEnvId,
      };

      if (this._useToken) {
        const token: string | undefined = ""; //await getAccessToken();
        const isGuest: boolean = false; //await getIsGuest();

        //if no token is available return 401
        //unless
        //1. method is set as override no token
        //2. isGuest token is set && method is set to allow guest
        if (
          (!token || token === "undefined" || (token?.length ?? 0) < 30) &&
          !this._overrideNoToken &&
          !isGuest &&
          this._allowGuest
        ) {
          Log.logToConsoleDebug("HTTP.tsx", "API REQUEST", [
            this._url,
            "no token",
            token,
          ]);
          resolve({ success: false, status: 401, error: "NO_TOKEN" });
          return;
        }

        //if a token is present set the token (this is SSO auth provider token)
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }

        //if a guest token is present set the guest token
        const guestToken: string | undefined = undefined; //await getGuestToken();
        if (guestToken) {
          //headers.Guest = `Bearer ${guestToken}`;
        }
      }

      const request = {
        method: this._method,
        headers: headers,
        body:
          this._body && this._method !== "GET"
            ? JSON.stringify(this._body)
            : undefined,
      };

      const returnResponse = (toResolve: HTTPResponse<T>) => {
        Log.logToConsoleDebug("HTTP.ts", "API REQUEST", [
          this._url,
          request,
          toResolve,
        ]);
        if (!toResolve.success && toResolve.status !== 404) {
          Log.logToDataDog(Log.LogLevel.ERROR, "HTTP.ts", "API REQUEST ERROR", [
            this._url,
            request,
            toResolve,
          ]);
        }
        resolve(toResolve);
      };

      //@ts-ignore
      fetch(this._url, request)
        .then(response => {
          const { status } = response;
          switch (status) {
            case 200:
              response.json().then(response => {
                returnResponse({ success: true, status, body: response });
                return;
              });
              return;
            case 201:
            case 202:
            case 203:
            case 204:
              returnResponse({ success: true, status });
              return;
            case 404:
              returnResponse({ success: false, status });
              return;
            default:
              response.text().then(response => {
                returnResponse({ success: false, status, error: response });
                return;
              });
              return;
          }
        })
        .catch(error => {
          returnResponse({ success: false, status: 500, error });
        });
    });
  };

  static methods = {
    GET: "GET",
    PUT: "PUT",
    POST: "POST",
    PATCH: "PATCH",
    DELETE: "DELETE",
  };
}

export default HTTP;
