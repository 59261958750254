import { APP_TENANT, BLACE_V2_API } from "@/src/const";
import { BlaceV2Type } from "@/src/type";
import { HTTP, HTTPResponse } from "./HTTP";

export async function getGoogleReverseGeoCode(
  lat: number,
  lng: number
): Promise<HTTPResponse<BlaceV2Type.BlaceV2Response<any>>> {
  const action = new HTTP<BlaceV2Type.BlaceV2Response<any>>(
    `${BLACE_V2_API}/external/google/reverseGeoCode?lat=${lat}&lng=${lng}`,
    HTTP.methods.GET
  );
  action.setAllEnvId(APP_TENANT);
  return await action.execute();
}
