import { AuthLogic } from "@/src/model";
import { BlaceV1Type, NotificationType } from "@/src/type";
import { HTTP } from "./";

export async function getNotifications(): Promise<
  | BlaceV1Type.V1ServiceResponse<NotificationType.NotificationResponse>
  | undefined
> {
  if (!AuthLogic.hasToken()) {
    return;
  }

  return await HTTP.v1Client<NotificationType.NotificationResponse>(
    "/client/notifications?page=1",
    undefined,
    "GET",
    {
      Authorization: `Bearer ${AuthLogic.getIdentityToken()}`,
    }
  );
}
