interface Props {
  color?: string;
  size?: number;
}

export function CancelSVG({ color = "#EA0000", size = 16 }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${size}`} height={`${size}`} viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.52729 4.47157C3.26694 4.21122 3.26694 3.78911 3.52729 3.52876C3.78764 3.26841 4.20975 3.26841 4.4701 3.52876L7.9987 7.05735L11.5273 3.52876C11.7876 3.26841 12.2098 3.26841 12.4701 3.52876C12.7305 3.78911 12.7305 4.21122 12.4701 4.47157L8.94151 8.00016L12.4701 11.5288C12.7305 11.7891 12.7305 12.2112 12.4701 12.4716C12.2098 12.7319 11.7876 12.7319 11.5273 12.4716L7.9987 8.94297L4.4701 12.4716C4.20975 12.7319 3.78764 12.7319 3.52729 12.4716C3.26694 12.2112 3.26694 11.7891 3.52729 11.5288L7.05589 8.00016L3.52729 4.47157Z" fill={color} />
    </svg>
  );
}
