import { FC } from "react";
import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressPropsColorOverrides,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";

interface BaseButtonProps extends ButtonProps {
  /** an [OPTIONAL] boolean value that when try changes the text to a loading icon until false */
  loading?: boolean;
  loadingColor?: OverridableStringUnion<
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "inherit",
    CircularProgressPropsColorOverrides
  >;
}

/**
 * Common button that supports standard loading icon when clicked
 * @demo https://storybook.blace.dev?path=/story/base-BaseButton--contained
 *
 * @component BaseButton
 * @interface BaseButtonProps @extends ButtonProps
 * @property {boolean} loading - an [OPTIONAL] boolean value that when try changes the text to a loading icon until false
 * @property {"button" | "submit"} type - Use sumbit when triggering a form
 * @property {"contained" | "outlined" | "text"} variant - the style of the button
 * @property {boolean} disabled - make the but non-clickable and change style to grey
 * @property {Object} sx - MUI styling object: https://mui.com/system/getting-started/the-sx-prop/
 *
 * @note This component extends ButtonProps from MUI.  All properties are available in @demo link in Storybook
 */
const BaseButton: FC<BaseButtonProps> = ({
  loading,
  loadingColor,
  ...props
}) => {
  const finalProps: ButtonProps = { color: "secondary", ...props };
  return loading ? (
    <Button
      {...finalProps}
      startIcon={
        <CircularProgress
          color={loadingColor ?? "secondary"}
          size={14}
          data-testid="loading-icon"
        />
      }
      disabled
    />
  ) : (
    <Button {...finalProps} />
  );
};

export default BaseButton;
