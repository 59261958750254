import { ThemeType } from "@/src/type";

/**
 * these need to be the same as in variables.scss
 * TODO: fix this issue result from global import
 */
export const BREAKPOINTS: ThemeType.CustomBreakpoints = {
  xl: 1800,
  lg: 1400,
  md: 960,
  sm: 600,
  xs: 450,
};
